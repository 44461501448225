import { Component, Inject }   from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoaderManagmentService } from '@providers/loader-managment/loader-managment.service';

@Component({
  selector: 'success-modal',
  templateUrl: './success.modal.html',
  styleUrls: ['./success.modal.scss']
})

export class SuccessModalComponent {

  tit:    string  = 'alerts.modal.titS';
  msg:    any  = 'alerts.modal.msgS';
  class:  string  = 'green';
  succ:   boolean = true;
  bot:    boolean = false;
  bot2:   boolean = false;

  // Success
  titS: string = 'alerts.modal.titS';
  msgS: string = 'alerts.modal.msgS';

  // Error
  titE: string = 'alerts.modal.titE';
  msgE: string = 'alerts.modal.msgE';

  opt: number = 0;

  constructor(
    public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any,
    private loading: LoaderManagmentService
  ){
    this.show(data.succ, data.bot2, data.bot, data.tit, data.desc, data.opt);
  }

  show(succ: boolean, bot2?: boolean, bot?: boolean, tit?: string, desc?: string, opt?: number): void {
    this.class = (succ) ? 'green' : 'red';
    this.bot2 = bot2;
    if(tit){
      this.tit = tit;
      this.msg = (opt === 4 || opt === 7 || opt === 8 || opt === 11 || opt === 18)?desc.split('(-)'):desc;
      this.bot = bot;
      this.opt = (!!opt ? opt : 0);
    }
    else{
      this.tit = (succ) ? this.titS : this.titE;
      this.msg = (succ) ? this.msgS : this.msgE;
      this.bot = false;
      this.opt = 0;
    }
    this.loading.dismissLoading();
  }

  close(): void { this.dialogRef.close({close: false}); }

  closeUnd(): void { this.dialogRef.close(); }

  closeS(): void {
    this.dialogRef.close({close: true});
    // case 8:
    //   this.that.deleteUnit(this.item.pk_transportation, this.i, this);
    //   break;
  }

}
