export enum Sex {
    Male = 'Male',
    Female = 'Female',
    MaleMin = 'male',
    FemaleMin = 'female'
};

export enum TypeOtp {
    EMAIL = 'Email',
    PHONE = 'Sms'
};

export enum ChangesCurl {
    CREATED = 'CREATED',
    DELETED = 'DELETED',
    UPDATED = 'UPDATED'
};

export enum TypeSubscription {
    ONLINE = 'OPENING ONLINE',
    OPENING = 'OPENING',
    BASE = 'BASE'
};

export enum RolesType {
  developer = 22,
  groupAdmin = 21,
  groupOwner = 20,
  mechanic = 5,
  analyst = 4,
  enroller = 3,
  seller = 2,
  subscriptionAdmin = 1,
  financial = 23
}
