import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html'
})
export class ErrorMessageComponent {

  @Input() inputVal: FormControl;
  @Input() isPass: boolean;
  @Input() classDiv: string = 'text-left';

}
