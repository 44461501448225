export enum SocketEventTypes {
  SEND_SEAT = 'send-seat',
  BUS_CHANGE = 'availability-change',
  ERROR = 'message-error',
  TIME_EXPIRED = 'time-expired',
  SELECTED_SEATS = 'selected-seats',
  SELECTED_STANDDING = 'selected-standing',
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  REGISTER = 'register',
  GET_SELECTED_SEAT = 'getselected-seats',

  BUS_UPDATED = 'bus-updated'
};

export enum ESocketMessages {
  COMUNICATION = 'Error de comunicacion'
};
