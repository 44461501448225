import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ROLES_ADMIN } from '@constants/roles';
import { ROUTES_ADMIN } from '@constants/routes';
import { User } from '@models/entities/user';
import { UserManagerService } from '@providers/user-manager/user-manager.service';


@Injectable({
  providedIn: 'root'
})
export class RedirectService {

  constructor(
    private route: Router,
    private userService: UserManagerService
  ) { }

  redirect(to: any, data?): void { this.route.navigate([to], data); }

  redirectToInit(): void {
    const user: User = this.userService.currentUser;
    if(user && user.privileges.length > 0){
      const optionModule = user.privileges.find( module => (module.name === ROLES_ADMIN.MONITOR || module.name === ROLES_ADMIN.TICKETS || module.name === ROLES_ADMIN.DRIVERS || module.name === ROLES_ADMIN.ROLES || module.name === ROLES_ADMIN.ROUTES || module.name === ROLES_ADMIN.UNITS) );
      if(!!optionModule){
        switch(optionModule.name){
          case ROLES_ADMIN.MONITOR:
            this.redirect(ROUTES_ADMIN.MONITOR_ROUTE);
            break;
          case ROLES_ADMIN.TICKETS:
            this.redirect(ROUTES_ADMIN.TICKETS_ROUTE);
            break;
          case ROLES_ADMIN.DRIVERS:
            this.redirect(ROUTES_ADMIN.DRIVERS_ROUTE);
            break;
          case ROLES_ADMIN.ROLES:
            this.redirect(ROUTES_ADMIN.ROLES_ROUTE);
            break;
          case ROLES_ADMIN.ROUTES:
            this.redirect(ROUTES_ADMIN.ROUTES_ROUTE);
            break;
          case ROLES_ADMIN.UNITS:
            this.redirect(ROUTES_ADMIN.UNITS_ROUTE);
            break;
          default:
            this.route.navigate([ROUTES_ADMIN.NOT_FOUND_ROUTE]);
            break;
        }
      } else{
        const optionSecondModule = user.privileges.find( secondModules => (secondModules.name === ROLES_ADMIN.PROFILE || secondModules.name === ROLES_ADMIN.OFFICE || secondModules.name === ROLES_ADMIN.COPNY || secondModules.name === ROLES_ADMIN.PAYMENT_SYSTEM || secondModules.name === ROLES_ADMIN.VALID_POS) );
        if(!!optionSecondModule){
          switch(optionSecondModule.name){
            case ROLES_ADMIN.PROFILE:
              this.redirect(ROUTES_ADMIN.PROFILE_ROUTE);
              break;
            case ROLES_ADMIN.OFFICE:
              this.redirect(ROUTES_ADMIN.OFFICE_ROUTE);
              break;
            case ROLES_ADMIN.COPNY:
              this.redirect(ROUTES_ADMIN.COPNY_ROUTE);
              break;
            case ROLES_ADMIN.PAYMENT_SYSTEM:
              this.redirect(ROUTES_ADMIN.TRANSACTIONS_ROUTE);
              break;
            case ROLES_ADMIN.VALID_POS:
              this.redirect(ROUTES_ADMIN.VALID_POS);
              break;
            default:
              this.route.navigate([ROUTES_ADMIN.NOT_FOUND_ROUTE]);
              break;
          }
        } else this.route.navigate([ROUTES_ADMIN.NOT_FOUND_ROUTE]);
      }
    }
    else this.route.navigate([ROUTES_ADMIN.NOT_FOUND_ROUTE]);
  }

  redirectToDriverInfo(redirect: string): void { this.redirect(ROUTES_ADMIN.DRIVERS_ROUTE+redirect); }

  getRouteUrl(): string { return (this.route) ? this.route.url : ''; }
  getRouter(): Router { return this.route; }
}
