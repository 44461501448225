import { Injectable } from '@angular/core';
import { RolesType } from '@enums/general.enum';
import { UserManagerService } from '@providers/user-manager/user-manager.service';

@Injectable({
  providedIn: 'root'
})
export class RolesManagmentService {

  constructor(private userService: UserManagerService) { }

  public get onlyListRole(): boolean {
    if (this.userService?.role) {
      const { role } = this.userService;
      return role === RolesType.seller || role === RolesType.mechanic;
    }
    return false;
  }

  public get onlyBasicConfigRoutes(): boolean {
    if (this.userService?.role) {
      const { role } = this.userService;
      return role === RolesType.seller;
    }
    return false;
  }

  public get onlyMechanicList(): boolean {
    if (this.userService?.role) {
      const { role } = this.userService;
      return role === RolesType.enroller;
    }
    return false;
  }

  public get onlyMonitoring(): boolean {
    if (this.userService?.role) {
      const { role } = this.userService;
      return role === RolesType.seller;
    }
    return false;
  }

  public get onlySalesAndTickets(): boolean {
    if (this.userService?.role) {
      const { role } = this.userService;
      return role !== RolesType.subscriptionAdmin;
    }
    return false;
  }
}
