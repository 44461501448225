import { LANG, LANGACTUALL } from '@models/entities/general/lang';

export const LANGS: Array<LANG> = [
  {
    id: 0,
    key: 'en',
    title: 'language.english',
    icon: '/assets/img/flags/us.svg'
  },
  {
    id: 1,
    key: 'es',
    title: 'language.spanish',
    icon: '/assets/img/flags/cr.svg'
  }
];

export const defaultLanguage = 'es';

export const LANGACTUAL: LANGACTUALL = {
  icon: '/assets/img/flags/cr.svg',
  langName: 'language.spanish',
  langValue: 1
};
