import { Component, Inject }   from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'normal-modal',
  templateUrl: './normal-modal.html',
  styleUrls: ['./normal-modal.scss']
})

export class NormalModalComponent {

  title: string = '';
  classTitle: string  = '';
  message: string = '';
  btnCancel: string = '';
  btnSave: string = '';

  constructor(
    public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any
  ){
    this.title = data.title;
    this.classTitle = data.classTitle || '';
    this.message = data.message;
    this.btnCancel = data.btnCancel;
    this.btnSave = data.btnSave;
  }

  close(save: boolean = false): void { this.dialogRef.close(save); }
}
