import { Pipe, PipeTransform } from '@angular/core';
import { FormControl } from '@angular/forms';

@Pipe({
  name: 'FormatPipe',
})
export class FormatPipe implements PipeTransform {

  transform(str: any, noCurrency: boolean, exchange: string = '01'): any {
    let p = 0;
    str = `${(!!str) ? str : 0}`;
    if (str.includes('.')) {
      str = str.slice(0, str.indexOf('.') + 3);
      p = str.length - (str.indexOf('.'));
    }

    let negativeSign = '';
    if (str.includes('-')) {
      negativeSign = str.split('-');
      str = negativeSign[1];
      negativeSign = '-';
    }

    for (let i = (str.length - 4 - p); i >= 0; i -= 3) {
      str = str.substr(0, i) + str.charAt(i) + '.' + str.substr(i + 1);
    }

    str = negativeSign + str;
    return !!noCurrency ? str : this.assignCurrency(exchange, str);
  }

  assignCurrency(exchange, amount): string {
    if (parseInt(exchange) === 1) return `₡ ${amount}`;
    else
      if (parseInt(exchange) === 2) return `$ ${amount}`;
      else return `N ${amount}`;
  }

  deleteComa(price: string): number {
    return Number((String(price).includes('.')) ? price.replace(/\./g,'') : price);
  }

  deleteCurrencyComa(value: string): number {
    let valueChange: any = value.split(' ');
    if (valueChange?.length > 1) {
      valueChange = this.deleteComa(valueChange[1]);
      return valueChange;
    }
    return Number(value);
  }

  printDisc(noComma: string, ticket): any { return (this.deleteComa(noComma) * ticket.discount); }
  getTotal(noComma: string, ticket): any {
    const price: number = this.deleteComa(noComma);
    return this.transform(price-this.printDisc(noComma, ticket), false);
  }

  deleteCharacters(control: FormControl, first: boolean, noCurrency: boolean): string {
    let value: string = first || noCurrency ? control.value : `${control.value}`.slice(2);
    if(!`${value}`.includes('.')){
      for(let i = 0; i < value.length; i++){
        if(i === 0 && value[0] === '0'){
          value = value.slice(1);
          i--;
        } else {
          break;
        }
      }
    }

    return value;
  }

  changesAmountInputs(control: FormControl, first: boolean, noCurrency: boolean): void {
    const value = this.deleteCharacters(control, first, noCurrency);
    return this.transform(this.deleteComa(value), noCurrency);
  }
}

