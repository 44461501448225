export enum Sex {
    Male = 'Male',
    Female = 'Female',
    MaleMin = 'male',
    FemaleMin = 'female'
};

export enum TypeOtp {
    EMAIL = 'Email',
    PHONE = 'Sms'
};

export enum ChangesCurl {
    CREATED = 'CREATED',
    DELETED = 'DELETED',
    UPDATED = 'UPDATED'
};

export enum TypeSubscription {
    ONLINE = 'OPENING ONLINE',
    OPENING = 'OPENING',
    BASE = 'BASE'
};
