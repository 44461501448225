import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '@providers/authGuard/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  { path: 'login',
    loadChildren: () => import('@pages/login/login.module').then(m => m.LoginModule),
    canActivate: [AuthGuardService],
    data: { login: true  }
  },
  {
    path: '',
    loadChildren: () => import('@pages/home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuardService],
    data: { login: false  }
  },
  {
    path: 'suspended-access',
    loadChildren: () => import('@pages/suspended-access/suspended-access.module').then(m => m.SuspendedAccessModule)
  },
  {
    path: '404',
    loadChildren: () => import('@pages/not-found/not-found.module').then(mod => mod.NotFoundModule)
  },
  { path: '**', redirectTo: '404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
