const DRIVERS_SUB_ROUTE = {
  DRIVERS_LIST_ROUTE: '/employees/list',
  DRIVERS_NEW_ROUTE: '/employees/new'
};

const MONITORING_SUB_ROUTE = {
  MONITORING: '/operations/monitoring',
  RESTRICTIONS: '/operations/restrictions',
  NOTIFICATIONS: '/operations/notifications',
  ALERTS: '/operations/alerts'
};

const PROFILE_SUB_ROUTE = {
  ACCOUNT: '/profile/account',
  ACCESS: '/profile/access'
};

const ROLES_SUB_ROUTE = {
  ROLES_CREATE_ROLES: '/roles/create-role',
  ROLES_LIST_ROLES: '/roles/role-list'
};

const ROUTES_SUB_ROUTE = {
  ROUTES_CREATE_ROUTE: '/routes/create-route',
  ROUTES_LIST_ROUTES: '/routes/route-list'
};

const TICKETS_SUB_ROUTE = {
  TICKETS_SALES: '/tickets/sales',
  TICKETS_SOLD: '/tickets/sold',
  TICKETS_CONFIG: '/tickets/configuration'
};

const TRANSACTIONS_SUB_ROUTE = {
  IN_PROGRESS: '/transactions/in-progress',
  FINISHED: '/transactions/finished',
  CONCILIATIONS: '/transactions/conciliations'
};

const UNITS_SUB_ROUTE = {
  LIST: '/units/list',
  CREATE_CONSULT: '/units/create-consult'
};

const VALID_POS_SUB_ROUTE = {
  ASSIGNED: '/valid-pos/assigned',
  ASSIGN: '/valid-pos/assign'
};

export const ROUTES_ADMIN = {
  ROOT_ROUTE: '/',
  LOGIN_ROUTE: '/login',
  NOT_FOUND_ROUTE: '/404',
  MONITOR_ROUTE: '/operations',
  MONITORING_SUB_ROUTE,
  DRIVERS_ROUTE: '/employees',
  DRIVERS_SUB_ROUTE,
  ROLES_ROUTE: '/roles',
  ROLES_SUB_ROUTE,
  TICKETS_ROUTE: '/tickets',
  TICKETS_SUB_ROUTE,
  ROUTES_ROUTE: '/routes',
  ROUTES_SUB_ROUTE,
  UNITS_ROUTE: '/units',
  UNITS_SUB_ROUTE,
  PROFILE_ROUTE: '/profile',
  PROFILE_SUB_ROUTE,
  OFFICE_ROUTE: '/office',
  COPNY_ROUTE: '/copny',
  TRANSACTIONS_ROUTE: '/transactions',
  TRANSACTIONS_SUB_ROUTE,
  VALID_POS: '/valid-pos',
  VALID_POS_SUB_ROUTE,
  SUSPENDED_ACCESS: '/suspended-access'
};
