import { Component, Inject, Input } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-title-desc',
  templateUrl: './title-desc.html'
})

export class TitleDescComponent {

  @Input('classDiv') classDiv: string;
  @Input('title') title: string;
  @Input('classTitle') classTitle: string;
  @Input('classDesc') classDesc: string;
  @Input('desc') desc: string;
  @Input('br') br: boolean;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
    this.setData(data);
  }

  setData(dataSnack): void {
    if(dataSnack){
      this.classDiv = dataSnack.classDiv;
      this.title = dataSnack.title;
      this.classTitle = dataSnack.classTitle;
      this.classDesc = dataSnack.classDesc;
      this.desc = dataSnack.desc;
      this.br = dataSnack.br;
    }
  }
}
