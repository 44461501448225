import { Injectable } from '@angular/core';
import { IdPResponseError } from '@enums/idpresponseerrors.enum';
import { StorageKeys } from '@enums/storage.enum';
import { environment } from '@environments/environment';
import { CompanyDataSaved } from '@models/entities/copny/copny';
import { Subscription, User } from '@models/entities/user';
import { ErrorLogin, IdpAccessTokenResponse, IdpIdToken, IdpMeResponse, UserInfoDesResponse } from '@models/responses/login-response';
import { ResponseGeneralMini } from '@models/responses/response-general';
import { ConnectionManager } from '@providers/connection-manager/connection-manager';
import { CopnyManagmentService } from '@providers/copny-managment/copny-managment.service';
import { SessionDataProvider } from '@providers/session-data/session-data';
import { SnackbarService } from '@providers/snackbar-services/snackbar.services';
import { StorageService } from '@providers/storage/storage.service';
import { TranslationService } from '@providers/translation-new/translation.service';
import { UserManagerService } from '@providers/user-manager/user-manager.service';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  // TODO - UNTIL ADD INTERCEPTORS
  // url: string;
  // passerIdP: {
  //   options: PasserIdP.Options;
  // };

  // private readonly TOKEN = '/token';
  // private readonly USER_INFO = '/me';

  constructor(
    private connection: ConnectionManager,
    private copnyManagment: CopnyManagmentService,
    private session: SessionDataProvider,
    private snackbar: SnackbarService,
    private storage: StorageService,
    private translation: TranslationService,
    private userManagerService: UserManagerService
  ) {
    // this.url = environment.SERVER_URL;
    // this.passerIdP = { options: environment.passerIdP.options };
  }

  async initLogin(email: string, password: string): Promise<ResponseGeneralMini> {
    try {
      const response: IdpAccessTokenResponse = await this.connection.postIdpFetch(
        `${this.connection.BASE_URL_IDP}${this.connection.IDP_TOKEN}`,
        {
          grant_type: 'password',
          client_id: environment.passerIdP.options.clientId,
          client_secret: environment.passerIdP.options.clientsecret,
          username: email,
          password: password,
          scope: environment.passerIdP.options.scope,
        },
        3, true
      );
      if (response.token_type && response.access_token) {
        return { success: true, data: response };
      }
      else {
        return { success: false, data: this.revErrors(response) };
      }
    } catch (err) {
      const error: ErrorLogin = this.connection.fetchReviewStatus(err, 3);
      if (error) {
        this.revErrorIDP(error.error);
      }
      return { success: false, data: error?.error };
    }
  }

  async loginSelectSubscription(response: IdpAccessTokenResponse): Promise<ResponseGeneralMini> {
    const idToken: IdpIdToken = jwt_decode(response.id_token);

    this.session.token = response.access_token;
    this.session.refreshTokenIdp = response.refresh_token;
    try {
      const userSubsResponse: Subscription[] = await this.connection.getIdpFetch(`${this.connection.BASE_URL_IDP}${this.connection.GET_SUBSCRIPTIONS_ACCOUNT}`.replace('{{account}}', idToken.sub));
      if (userSubsResponse.length === 1) {
        this.session.subscriptionIdp = userSubsResponse[0];
        return { success: true, data: userSubsResponse };
      } else {
        return { success: true, data: userSubsResponse };
      }
    }
    catch (err) {
      const error: ErrorLogin = this.connection.fetchReviewStatus(err, 3);
      if (error) {
        if (error?.message && error?.description) {
          this.snackbar.showSnackBar(error.message, error.description);
        } else {
          this.revErrorIDP(error.error);
        }
      }
      return { success: false };
    }

  }

  async logingResfreshToken(userSubscriptionsToclient: Subscription[]): Promise<boolean> {
    try {
      const idpAccessTokenResponse: IdpAccessTokenResponse = await this.connection.postIdpFetch(
        `${this.connection.BASE_URL_IDP}${this.connection.IDP_TOKEN}`,
        {
          grant_type: "refresh_token",
          refresh_token: this.session.refreshTokenIdp,
          scope: environment.passerIdP.options.scope,
          suscription: this.session.subscriptionIdp._id,
          managementGroup: this.session.subscriptionIdp.managementGroup?._id
        },
        3, true
      );

      this.session.token = idpAccessTokenResponse.access_token;
      this.session.tokenType = idpAccessTokenResponse.token_type;
      this.session.refreshTokenIdp = idpAccessTokenResponse.refresh_token;
      this.session.subscriptionsIdp = userSubscriptionsToclient;

      return true;
    }
    catch (err) {
      const error: ErrorLogin = this.connection.fetchReviewStatus(err, 3);
      if (error) {
        if (error?.message && error?.description) {
          this.snackbar.showSnackBar(error.message, error.description);
        } else {
          this.revErrorIDP(error.error);
        }
      }
      return false;
    }
  }

  async loginSaveUser(): Promise<ResponseGeneralMini> {
    try {
      const userInfoIdpMeResponse: IdpMeResponse = await this.connection.getIdpFetch(`${this.connection.BASE_URL_IDP}${this.connection.ME}`);
      this.userManagerService.temporaryIdSub = userInfoIdpMeResponse?.sub;
      const userInfoDesResponse: UserInfoDesResponse = await this.connection.getIdpFetch(this.connection.BASE_URL + this.connection.GET_USER);
      const copnyUser = this.userManagerService.createUserToSave(userInfoIdpMeResponse, userInfoDesResponse);

      if (typeof (Storage) !== 'undefined') {
        return { success: true, data: copnyUser };
      }
      else {
        const { alerts } = this.translation.getLangMessagesByKeys('login');
        this.snackbar.showSnackBar('', alerts.local);
        return { success: false };
      }
    } catch (err) {
      this.userManagerService.temporaryIdSub = '';
      const error: ErrorLogin = this.connection.fetchReviewStatus(err, 3);
      if (error) {
        if (error?.message && error?.description) {
          this.snackbar.showSnackBar(error.message, error.description);
        } else {
          this.revErrorIDP(error.error);
        }
      }
      return { success: false };
    }
  }

  async loginGetInfoCopny(copnyUser: User): Promise<boolean> {
    const result: CompanyDataSaved = await this.copnyManagment.getCopnyss(copnyUser, true);
    if (result) {
      return true;
    } else {
      this.revErrorIDP('');
      return false;
    }
  }

  async selectedCopny(copnyUser: User): Promise<boolean> {
    const copnyFound: CompanyDataSaved = { ...this.copnyManagment.company };
    if (!!copnyFound) {
      if (copnyFound?.subscription?.fk_company_suscription_status === 2) {
        this.userManagerService.saveUser(copnyUser);
        this.session.isLogged = true;
        await this.setSessionDataStorage();
        return true;
      } else {
        return false;
      }
    }
  }

  async setSessionDataStorage(): Promise<void> {
    await this.storage.setData(StorageKeys.TOKEN, this.session.token);
    await this.storage.setData(StorageKeys.TOKEN_TYPE, this.session.tokenType);
    await this.storage.setData(StorageKeys.REFRESH_TOKEN_IDP, this.session.refreshTokenIdp);
    await this.storage.setData(StorageKeys.SUBSCRIPTION_IDP, this.session.subscriptionIdp);
    await this.storage.setData(StorageKeys.SUBSCRIPTIONS_IDP, this.session.subscriptionsIdp);
  }

  // TODO - THIS IS FOR LOGIN PAGE
  revErrors(data: any): string {
    const { alerts, login } = this.translation.getLangMessagesByKeys(['alerts', 'login']);
    if (data && data.code) {
      switch ('' + data.code) {
        case '0':
          return 'alerts.log0';
        case '2':
          return 'alerts.log2';
        case '3':
          return 'alerts.log3';
        case '4':
          return 'alerts.log4';
        case '5':
          this.snackbar.showSnackBar('', alerts.modal.titE);
          return '';
        case '4004':
          this.snackbar.showSnackBar('', alerts.modal.no_susc_add);
          return '';
        case '4005':
          this.snackbar.showSnackBar('', alerts.modal.no_susc_act);
          return '';
        default:
          this.snackbar.showSnackBar('', login.alerts.em_pass);
          return '';
      }
    } else {
      this.snackbar.showSnackBar('', login.alerts.em_pass);
      return '';
    }
  }

  revErrorIDP(error: string): void {
    const { alerts: { passerErrorMessage }, login: { alerts } } = this.translation.getLangMessagesByKeys(['alerts', 'login']);
    switch (error) {
      case IdPResponseError.InvalidGrant:
        this.snackbar.showSnackBar('', alerts.passerIdpErrorInvalidGrant);
        break;
      case IdPResponseError.InvalidManagementGroup:
        this.snackbar.showSnackBar('', alerts.passerIdpErrorInvalidManagementGroup);
        break;
      case IdPResponseError.InvalidSubscription:
        this.snackbar.showSnackBar('', alerts.passerIdpErrorInvalidSubscription);
        break;
      default:
        this.snackbar.showSnackBar('', passerErrorMessage);
        break;
    }
  }
}
