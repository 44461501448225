
<div *ngIf="!isPass && inputVal.invalid &&
  inputVal.errors &&
  (inputVal.dirty || inputVal.touched)" [className]="classDiv">
  <p class="color-psr-danger f11 p-y-4 m-0"
    *ngIf="inputVal.hasError('required')">
    {{ 'alerts.ERRORS.REQUIRED' | translate }}
  </p>
  <p class="color-psr-danger f11 p-y-4 m-0"
    *ngIf="inputVal.hasError('minlength')">
    {{ 'alerts.ERRORS.MINLENGTH' | translate }} {{inputVal.errors.minlength.requiredLength}} {{ 'alerts.ERRORS.CHARS' | translate }}.
  </p>
  <p class="color-psr-danger f11 p-y-4 m-0"
    *ngIf="inputVal.hasError('maxlength')">
    {{ 'alerts.ERRORS.MAXLENGTH' | translate }} {{inputVal.errors.maxlength.requiredLength}} {{ 'alerts.ERRORS.CHARS' | translate }}.
  </p>
  <p class="color-psr-danger f11 p-y-4 m-0"
    *ngIf="inputVal.hasError('min')">
    {{ 'alerts.ERRORS.MIN' | translate }} {{inputVal.errors.min.min}}.
  </p>
  <p class="color-psr-danger f11 p-y-4 m-0"
    *ngIf="inputVal.hasError('max')">
    {{ 'alerts.ERRORS.MAX' | translate }} {{inputVal.errors.max.max}}.
  </p>
  <p class="color-psr-danger f11 p-y-4 m-0"
    *ngIf="!inputVal.hasError('minlength') && !inputVal.hasError('maxlength') && inputVal.hasError('pattern')">
    {{ 'alerts.ERRORS.INVALID_FORMAT' | translate }}.
  </p>
</div>

<div *ngIf="isPass && inputVal.invalid && 
  inputVal.errors && 
  (inputVal.dirty || inputVal.touched)"
  [className]="classDiv"
>

  <p class="color-psr-danger f11 py-4 my-0"
    *ngIf="inputVal.hasError('required')">
    {{ 'alerts.ERRORS.REQUIRED' | translate }}
  </p>

  <p *ngIf="inputVal.hasError('hasCapitalCase') || inputVal.hasError('hasSmallCase') || inputVal.hasError('hasNumber')" 
    class="color-psr-danger f11 py-4 my-0">{{"alerts.ERRORS.PASSWORD_VALID" | translate }} </p>

  <p *ngIf="inputVal.hasError('minlength') && !(inputVal.hasError('hasCapitalCase') || inputVal.hasError('hasSmallCase') || 
    inputVal.hasError('hasNumber')) "class="color-psr-danger f11 py-4 my-0">{{"alerts.ERRORS.PASSWORD_MINLENGTH" | translate }} </p>

  <p *ngIf="inputVal.hasError('maxlength') && !(inputVal.hasError('hasCapitalCase') || inputVal.hasError('hasSmallCase') || 
    inputVal.hasError('hasNumber')) "class="color-psr-danger f11 py-4 my-0">{{ 'alerts.ERRORS.MAXLENGTH' | translate }} {{inputVal.errors.maxlength.requiredLength}} {{ 'ERRORS.CHARS' | translate }}. </p>


  <p class="color-psr-danger f11 py-4 my-0"
    *ngIf="inputVal.hasError('confirmedValidator')">
    {{ 'alerts.ERRORS.ERROR_CONFIRMPASSWORD' | translate }}
  </p>
</div>