import { Injectable } from '@angular/core';
import { StorageKeys } from '@enums/storage.enum';
import { ParamsUserData } from '@models/entities/profile/profile';
import { User } from '@models/entities/user';
import { IdpMeResponse, UserInfoDesResponse } from '@models/responses/login-response';
import { StorageService } from '@providers/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserManagerService {

  private _currentUser: User;
  private _temporaryIdSub: string = '';

  constructor(private storage: StorageService) { }

  // OTHER METHODS
  async saveUser(user: User): Promise<void> {
    this._currentUser = user;
    await this.storage.setData(StorageKeys.COOKIE_NAME, user);
  }

  createUserToSave( meResponse : IdpMeResponse , response: UserInfoDesResponse): User {
    this._temporaryIdSub = '';
    return {
      privileges:  response.data.privileges,
      rolename:    response.data.rolename,
      role:        response.data.role,
      vendor:      response.data.vendor,
      userData:    response.data.userdata,
      employee:    response.data.employee,
      sub: meResponse.sub,
      given_name: meResponse.given_name,
      family_name: meResponse.family_name,
      middle_name: meResponse.middle_name,
      email: meResponse.email,
      email_verified: meResponse.email_verified,
      birthdate: meResponse.birthdate,
      sex: meResponse.sex,
      hasMfa: meResponse.hasMfa,
      phone: meResponse.phone ?? response.data.phone,
      phone_verified: meResponse.phone_verified
    };
  }

  setUserUpdate(data: ParamsUserData): void {
    const {
      email_otp, email, given_name, family_name,
      birthdate, sex, hasMfa, phone, phone_otp
    } = data;

    if (email_otp) {
      this._currentUser.email = email;
    }
    if (phone_otp) {
      this._currentUser.phone = phone;
    }
    this._currentUser.given_name = given_name;
    this._currentUser.family_name = family_name;
    this._currentUser.birthdate = birthdate;
    this._currentUser.sex = sex;
    this._currentUser.hasMfa = hasMfa;

    this.saveUser(this._currentUser);
  }

  // GET's
    public get temporaryIdSub(): string {
      return this._temporaryIdSub;
    }

    public get currentUser(): User {
      return this._currentUser;
    }

    public get email(): string {
      return this._currentUser?.email || '';
    }
    
    public get role(): number {
      return this._currentUser?.role;
    }

    public get fullName(): string {
      return this._currentUser ? `${this._currentUser.given_name} ${this._currentUser.family_name || ''}` : '';
    }

    public get nationalNumber(): string {
      return this._currentUser?.phone?.nationalNumber || '';
    }
    
    public get isoNumericCode(): string {
      return this._currentUser?.phone?.isoNumericCode || '';
    }
    
    public get sub(): string {
      return this._currentUser?.sub || '';
    }

    public get isAdmin(): boolean {
      return this._currentUser?.role === 1;
    }

  // SET's
    public set temporaryIdSub(sub: string) {
      this._temporaryIdSub = sub;
    }

    public set currentUser(user: User) {
      this._currentUser = user;
    }
}
