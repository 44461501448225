import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppMaterialModule } from './app.material.module';

import { SessionDataProvider } from '@providers/session-data/session-data';
import { ConnectionManager } from '@providers/connection-manager/connection-manager';
import { environment } from '@environments/environment';
import { RedirectService } from '@providers/redirect/redirect.service';
import { SocketPrintProvider, SocketTicketProvider, SocketProvider } from '@providers/socket/socket';
import { SetDateStringProvider } from '@providers/setDateString/setDateString';
import { LoaderManagmentService } from '@providers/loader-managment/loader-managment.service';
import { SnackbarService } from '@providers/snackbar-services/snackbar.services';
import { AuthGuardService } from '@providers/authGuard/auth-guard.service';
import { AuthGuardRolesService } from '@providers/authGuard-roles/authGuard-roles.service';
import { RolesManagmentService } from '@providers/roles-managment/roles-managment.service';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationService } from 'ngx-pagination';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { ModalService } from '@providers/modal-services/modal.service';
import { FormatPipe } from '@pipes/format';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { CopnyManagmentService } from '@providers/copny-managment/copny-managment.service';
import { GOOGLE_MAPS_API_CONFIG, NgMapsGoogleModule } from '@ng-maps/google';
import { API_KEY_MAPS } from '@constants/generals';
import { NgMapsCoreModule } from '@ng-maps/core';
import { PickerModule } from '@ctrl/ngx-emoji-mart';

// console.log = () => {};

const config: SocketIoConfig = {
  url: environment.socket_ticket_url,// socket_url,
  options: {
    reconnection: true,
    timeout: 10000,
    transports: ['polling']
} };

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader { return new TranslateHttpLoader(http, './assets/i18n/', '.json'); }
@NgModule({
  declarations: [AppComponent],
  entryComponents: [AppComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    AppMaterialModule,
    HttpClientModule,
    BrowserAnimationsModule,
    PickerModule,
    SocketIoModule.forRoot(config),
    TranslateModule.forRoot({
      loader: {
      provide: TranslateLoader,
      useFactory: (HttpLoaderFactory),
      deps: [HttpClient]
    }}),
    NgMapsGoogleModule,
    NgMapsCoreModule
  ],
  providers: [
    SessionDataProvider, ConnectionManager, RolesManagmentService,
    SocketPrintProvider, SocketTicketProvider, SocketProvider,
    PaginationService,
    SetDateStringProvider, RedirectService, AuthGuardService, AuthGuardRolesService,
    ModalService, FormatPipe, LoaderManagmentService,
    SnackbarService, CopnyManagmentService,
    {
      provide: GOOGLE_MAPS_API_CONFIG,
      useValue: {
        apiKey: API_KEY_MAPS,
        libraries: ['places']
      }
    },
    { provide: MAT_DATE_LOCALE, useValue: 'es-CR' },
    { provide: MatSnackBarRef, useValue: {} },
    { provide: MAT_SNACK_BAR_DATA, useValue: {} } // Add any data you wish to test if it is passed/used correctly
  ],
  schemas: [NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
