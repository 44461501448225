// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  serverUrlAdmin: 'https://testadminapi.passer.live',
  serverUrlPasser: 'https://testapi.passer.live',
  serverUrlPasserPolicy: 'https://testing.passer.live/legal/business-partner',
  serverUrlPasserPublicTransp: 'https://testing.passer.live/enterprises',
  serverUrlCatalogs: 'https://testcatalogs.passer.live/api',
  serverUrlDriver: 'https://testdriverapi.passer.live/api',
  serverUrlReportsFinished: 'https://testreports.passer.live/api/settlements',
  serverURLReportsConsolidatedEcommerce: 'https://testreports.passer.live/api/consolidatedSettlements/ecommerce',
  serverURLReportsConsolidatedPhysicalOffices: 'https://testreports.passer.live/api/consolidatedSettlements/ticketOffice',
  serverURLReportCancellations: 'https://testreports.passer.live/api/consolidatedSettlements/refunds',
  serverURLReportsConsolidatedPhysicalDrivers: 'https://testreports.passer.live/api/consolidatedSettlements/drivers',
  serverURLReportsSellerBreakdown: 'https://testreports.passer.live/api/salesBreakdown/ticketOffice',
  serverURLReportsDriverBreakdown: 'https://testreports.passer.live/api/salesBreakdown/salesDriver',
  socket_url: 'https://testmapsocket.passer.live',
  socket_ticket_url: 'https://testsockets.passer.live',
  socket_print_url: 'https://testprintsocket,passer.live',
  passerIdP:{
    options: {
      serverUrl:  "https://testidp.passer.live",
      clientId: "6389772b9eed34d1311bb8a2",
      clientsecret: "&qC7gNTYx*hcmAx9KqnwqST",
      scope: "openid profile email phone offline_access", 
      resource: "https://testadminapi.passer.live",
      callbackRecoveryPasswordBaseUrl: "https://testing.passer.live/reset-password"
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
