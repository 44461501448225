import { LoginEndpoints } from "@enums/network.enum";

export const loginEndpoints = {
  [LoginEndpoints.loginToken]: 'idp.passer.live/token',
  [LoginEndpoints.userInfo]: 'idp.passer.live/me',
  [LoginEndpoints.idpRequest]: 'idp.passer.live/api/providers/Passer.',
  [LoginEndpoints.sendCode]: '/oauth/user/otp',
  [LoginEndpoints.verifyOtp]: '/oauth//user/verifyOtp',
  [LoginEndpoints.getUserData]: '/system/getUser',
  [LoginEndpoints.getCompanyData]: '/system/loadSubscription'
};
