import { Component, OnInit } from '@angular/core';
import { AVAILABLELANGUAGES_ES, defaultLanguage } from '@data/lang';
import { StorageKeys } from '@enums/storage.enum';
import { LoaderManagmentService } from '@providers/loader-managment/loader-managment.service';
import { StorageService } from '@providers/storage/storage.service';
import { TranslationService } from '@providers/translation-new/translation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private loading: LoaderManagmentService,
    private storage: StorageService,
    private translation: TranslationService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.setLanguage();
  }

  async setLanguage(): Promise<void> {
    this.loading.dismissLoading();
    let userLang;
    try {
      const response = await this.storage.getMyData(StorageKeys.CURRENT_LANGUAGE);
      userLang = response ?? defaultLanguage;
    } catch (e) {
      userLang = defaultLanguage;
    }

    const lang = AVAILABLELANGUAGES_ES[userLang === defaultLanguage ? 1 : 0];
    this.translation.setCurrentLang(lang);
  }
}
