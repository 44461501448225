import { Injectable } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { ROUTES_ADMIN } from '@constants/routes';
import { TicketRoleToPrint } from '@models/entities/roles/rolesData';
import { ClosingDriverData, TicketRefundPrint, TicketToPrint } from '@models/entities/tickets/ticketsData';
import { Subscription } from '@models/entities/user';
import { RedirectService } from '@providers/redirect/redirect.service';

@Injectable()
export class SessionDataProvider {

  header: boolean = false;
  mapFullScreen = false;

  private _isLogged: boolean = false;
  private _token: string;
  private _tokenType: string;
  private _refreshTokenIdp: string;
  private _subscriptionIdp: Subscription;
  private _subscriptionsIdp: Subscription[];
  private _dataTicket: TicketToPrint;
  private _dataTicketRefund: TicketRefundPrint;
  private _dataRole: TicketRoleToPrint;
  private _dataClosing: ClosingDriverData;
  private _dataShipment: any;
  private _ticketsFullScreen = false;
  private _matrixFullScreen = false;
  private _isPrintingFinished = false;

  constructor(private redirect: RedirectService) {
    this.redirect.getRouter().events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.header = !!(this.redirect.getRouteUrl() !== ROUTES_ADMIN.LOGIN_ROUTE && this.redirect.getRouteUrl() !== ROUTES_ADMIN.ROOT_ROUTE);
      }
    });
  }

  // Métodos de redireccion

    setSctLink(): void {
      this.addScript('/assets/js/bootstrap3.3.7.min.js', 'head');
    }

    // Include script file
    addScript(filename: string, nodeHTML: string): void {
      const head = document.getElementsByTagName(nodeHTML)[0],
      script = document.createElement('script');

      script.src = filename;
      script.type = 'text/javascript';
      head.appendChild(script);
    }

    // Include CSS file
    addCSS(filename: string): void {
      const head = document.getElementsByTagName('head')[0],
      style = document.createElement('link');

      style.href = filename;
      style.type = 'text/css';
      style.rel = 'stylesheet';
      head.appendChild(style);
    }

    // scroll
    scrollTo(element, top): void {
      try{ document.querySelector(element).scrollTo({top, behavior: 'smooth'}); }
      catch(err){ document.querySelector(element).scrollTo(0, top); }
    }
  // Fin

  clearAllData(): void {
    this._isLogged = false;
    this._token = undefined;
    this._tokenType = undefined;
    this._refreshTokenIdp = undefined;
    this._subscriptionIdp = undefined;
    this._subscriptionsIdp = undefined;
    this._dataTicket = undefined;
    this._dataTicketRefund = undefined;
    this._dataRole = undefined;
    this._dataClosing = undefined;
  }

  public get isLogged(): boolean {
		return this._isLogged;
	}

	public set isLogged(isLogged: boolean) {
		this._isLogged = isLogged;
	}

  public get token(): string {
		return this._token;
	}

	public set token(newToken: string) {
		this._token = newToken;
	}

  public get tokenType(): string {
		return this._tokenType;
	}

	public set tokenType(value: string) {
		this._tokenType = value;
	}

  public get refreshTokenIdp(): string {
		return this._refreshTokenIdp;
	}

	public set refreshTokenIdp(value: string) {
		this._refreshTokenIdp = value;
	}

  public get subscriptionIdp(): Subscription {
		return this._subscriptionIdp;
	}

	public set subscriptionIdp(value: Subscription) {
		this._subscriptionIdp = value;
	}

  public get subscriptionsIdp(): Subscription[] {
		return this._subscriptionsIdp;
	}

	public set subscriptionsIdp(value: Subscription[]) {
		this._subscriptionsIdp = value;
	}

  public get dataTicket(): TicketToPrint {
		return this._dataTicket;
	}

	public set dataTicket(value: TicketToPrint) {
		this._dataTicket = value;
	}

  public get dataTicketRefund(): TicketRefundPrint {
    return this._dataTicketRefund;
  }

  public set dataTicketRefund(value: TicketRefundPrint) {
    this._dataTicketRefund = value;
  }

  public get dataRole(): TicketRoleToPrint {
		return this._dataRole;
	}

	public set dataRole(value: TicketRoleToPrint) {
		this._dataRole = value;
	}

  public get dataClosing(): ClosingDriverData {
		return this._dataClosing;
	}

	public set dataClosing(value: ClosingDriverData) {
		this._dataClosing = value;
	}

  public get dataShipment(): any {
    return this._dataShipment;
  }

  public set dataShipment(value: any) {
    this._dataShipment = value;
  }

  public set ticketsFullScreen(value: boolean) {
    this._ticketsFullScreen = value;
  }

  public get ticketsFullScreen(): boolean {
    return this._ticketsFullScreen;
  }

  public set matrixFullScreen(value: boolean) {
    this._matrixFullScreen = value;
  }

  public get matrixFullScreen(): boolean {
    return this._matrixFullScreen;
  }

  public get isPrintingFinished(): boolean {
    return this._isPrintingFinished;
  }

  public set isPrintingFinished(value: boolean) {
    this._isPrintingFinished = value;
  }
}
