export const TIMES_TO_CHECK = {
  MONTHLY: 43800, // 1 MONTH
  HOUR: 60, // 1 HOUR
  HALF_HOUR: 30, // 30 MINUTES
  TEN_MIN: 10, // 10 MINUTES
  COUNTRY: 525600
};

export const ITEM_CACHE = {
  COUNTRY: 1,
  COUNTRY_TIME: 'lastTimeCountry',
  COUNTRY_LOCAL: 'countryItem',
  EMPLOYEE: 2,
  EMPLOYEE_TIME: 'lastTimeEmployee',
  EMPLOYEE_LOCAL: 'employeeItem',
  UNITS: 4,
  UNITS_TIME: 'lastTimeUnits',
  UNITS_LOCAL: 'unitsItem',
  ID_TYPE: 5,
  ID_TYPE_TIME: 'lastTimeIdType',
  ID_TYPE_LOCAL: 'idTypeItem',
  OFFICE: 6,
  OFFICE_TIME: 'lastTimeOffice',
  OFFICE_LOCAL: 'officeItem',
  PLACE_TYPE: 7,
  PLACE_TYPE_TIME: 'lastTimePlaceType',
  PLACE_TYPE_LOCAL: 'placeTypeItem',
  VENDOR: 8,
  VENDOR_TIME: 'lastTimeVendor',
  VENDOR_LOCAL: 'vendorItem',
  JOURNEY: 9,
  JOURNEY_TIME: 'lastTimeJourney',
  JOURNEY_LOCAL: 'journeyItem',
  BRAND: 10,
  BRAND_TIME: 'lastTimeBrand',
  BRAND_LOCAL: 'brandItem',
  BUS_MODEL: 11,
  BUS_MODEL_TIME: 'lastTimeBusModel',
  BUS_MODEL_LOCAL: 'busModelItem',
  ROUTE: 12,
  ROUTE_TIME: 'lastTimeRouteList',
  ROUTE_LOCAL: 'routeListItem',
  BUSS_TYPE: 13,
  BUSS_TYPE_TIME: 'lastTimeBussTypeList',
  BUSS_TYPE_LOCAL: 'bussTypeListItem',
  RESTRICTION: 14,
  RESTRICTION_TIME: 'lastTimeRestrictionList',
  RESTRICTION_LOCAL: 'restrictionListItem',
  EMPLOYEE_MIN: 15,
  EMPLOYEE_MIN_TIME: 'lastTimeEmployeeMin',
  EMPLOYEE_MIN_LOCAL: 'employeeMinItem',
};

export const isLessThanXMins = (currentDate,lastDate,mins) => {
  const diff = currentDate - lastDate;
  const minutes = (diff / 60000);
  return minutes <= mins;
};
