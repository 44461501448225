<div class="modal fade-modal in-modal">
  <div class="modal-dialog modal-sm max-w-360">
    <div class="modal-content p-16">
      <div class="modal-body text-center">
        <app-title-desc
          classDesc="f13 m-0"
          classTitle="f12 m-0 m-b-8"
          [desc]="'alerts.modal.credentialsDescription' | translate"
          [title]="'alerts.modal.credentialsTitle' | translate">
        </app-title-desc>

        <app-title-desc
          classDesc="f13 m-0 text-ellipsis"
          classDiv="radius-t-4 background-bg-material p-t-10 p-b-8 p-x-16 m-t-8 text-left"
          classTitle="f11 text-ellipsis fw-normal color-psr-radio-button-bg transf-none m-0 m-b-2"
          [desc]="email"
          [title]="'labels.email' | translate"
        >
        </app-title-desc>

        <div class="relative m-t-8">
          <mat-form-field appearance="fill" class="w-100" [hideRequiredMarker]="true">
            <mat-label>{{ 'labels.pass' | translate }}</mat-label>
            <input
              matInput
              name="password"
              [formControl]="passwordControl"
              [type]="eyePass ? 'password' : 'text'">
          </mat-form-field>
          <base-icon
            iconClass="z-index-10 top-22 absolute right-22 pointer"
            iconSize="xsmall"
            [srcIcon]="eyePass ? 'eye' : 'eye-off'"
            (click)="eyePass = !eyePass">
          </base-icon>
        </div>
        <app-error-message [inputVal]="passwordControl"></app-error-message>
      </div>

      <div class="btns-footer flex align-items-center justify-content-between m-t-16">
        <button class="btn btn-cancel fw-bold upper min-w-150px w-auto h-px-45" (click)="closeModal()">
          {{ 'actions.cancel' | translate }}
        </button>
        <button class="btn btn-cargar fw-bold upper min-w-150px w-auto h-px-45" (click)="closeModal(true)">
          {{ 'actions.continue' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>