import { Injectable } from '@angular/core';
import { TypeSubscription } from '@enums/general.enum';
import { StorageKeys } from '@enums/storage.enum';
import { Country } from '@models/entities/combosData';
import { BusinessEntity, CompanyDataSaved } from '@models/entities/copny/copny';
import { SaveDataCopny } from '@models/entities/copny/copny';
import { User } from '@models/entities/user';
import { ResponseGetCopnys } from '@models/responses/copny-response';
import { ErrorResponseGeneral } from '@models/responses/response-general';
import { ConnectionManager } from '@providers/connection-manager/connection-manager';
import { SessionDataProvider } from '@providers/session-data/session-data';
import { SetDateStringProvider } from '@providers/setDateString/setDateString';
import { SnackbarService } from '@providers/snackbar-services/snackbar.services';
import { StorageService } from '@providers/storage/storage.service';
import { TranslationService } from '@providers/translation-new/translation.service';

@Injectable({
  providedIn: 'root'
})
export class CopnyManagmentService {

  private _company: CompanyDataSaved;
  private _iconWarning = false;

  constructor(
    private sessionData: SessionDataProvider,
    private connection: ConnectionManager,
    private formatDate: SetDateStringProvider,
    private snackbar: SnackbarService,
    private storage: StorageService,
    private translation: TranslationService
  ) { }

  async getCopnyss(user: User, isLogin: boolean = false): Promise<CompanyDataSaved> {
    this._company = undefined;
    const { alerts } = this.translation.getLangMessagesByKeys('copny');
    try{
      const response: ResponseGetCopnys = await this.connection.getFetch(this.connection.BASE_URL + this.connection.GET_COPNYS, { pk_employee: user.employee, subscription: this.sessionData.subscriptionIdp?._id }, 3, isLogin);
      const { success, data } = response;
      if(success){
        if (data?.subscription?.expiration) {
          data.subscription.expiration = this.formatDate.setStringDate(data.subscription.expiration, 5, '+');
        }
        if (data.entity_type) {
          data.entity_type = { name: data.entity_type.name, code: data.entity_type.code };
        }

        this._company = {
          businessname: data.businessname,
          country: data.country,
          entity_type: data.entity_type,
          hasexternalintegration: data.hasexternalintegration,
          haspayments: data.haspayments,
          indfiscalid: data.indfiscalid,
          namcompany: data.namcompany,
          pk_company: data.pk_company,
          phone: data.phone,
          subscription: data.subscription,
          urlavatar: data.urlavatar,
          urlcover: data.urlcover
        };
        await this.storage.setData(StorageKeys.COMPANY_DATA, this._company);
        return this._company;
      } else {
        this.snackbar.showSnackBar('', alerts.asoCop_no);
        return undefined;
      }
    } catch(err){
      const error: ErrorResponseGeneral = this.connection.fetchReviewStatus(err, 3);
      if (error) {
        if (error?.message && error?.description) {
          this.snackbar.showSnackBar(error.message, error.description);
        } else {
          this.snackbar.showSnackBar('', alerts.asoCop_err);
        }
      }

      return undefined;
    }
  }

  async saveDataCompany(saveData: SaveDataCopny, country: Country, entityType: BusinessEntity): Promise<void> {
    const { legalName, legalId, businessName, phone } = saveData.company;
    this._company.namcompany = legalName;
    this._company.indfiscalid = legalId;
    this._company.businessname = businessName;
    this._company.phone = phone;
    this._company.country = country;
    this._company.entity_type = entityType;
    await this.storage.setData(StorageKeys.COMPANY_DATA, this._company);
  }

  async saveAvatarCover(image: string, avatar: boolean = false): Promise<void> {
    if (avatar) {
      this._company.urlavatar = image;
    } else {
      this._company.urlcover = image;
    }
    await this.storage.setData(StorageKeys.COMPANY_DATA, this._company);
  }

  public get iconWarning(): boolean {
    return this._iconWarning;
  }

  public set iconWarning(value: boolean) {
    this._iconWarning = value;
  }

  public get companyID(): any {
    return this._company?.pk_company || '';
  }

  public get avatarCompany(): string {
    return this._company?.urlavatar || '/assets/img/user.png';
  }

  public get companyName(): string {
    return this._company?.businessname || '';
  }

  public get typeCopny(): number {
    return this._company?.subscription?.fk_operationtype || -1;
  }

  public get hasPaymentsConnected(): boolean {
    return this._company?.haspayments || this.subscriptionType === TypeSubscription.BASE;
  }

  public get subscriptionType(): string {
    switch (this._company?.subscription?.fk_subscription_type) {
      case 1: return TypeSubscription.ONLINE;
      case 2: return TypeSubscription.OPENING;
      case 3: return TypeSubscription.BASE;
      default: return '-';
    }
  }

  public get isOpeningOnline(): boolean {
    return this._company?.subscription?.fk_subscription_type === 1;
  }

  public get isBase(): boolean {
    return this._company?.subscription?.fk_subscription_type === 3;
  }

  public get company(): CompanyDataSaved {
    return this._company;
  }

  public set company(company: CompanyDataSaved) {
    this._company = company;
  }
}
