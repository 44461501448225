<div id="modalGeneral" class="modal fade-modal in-modal">
  <div class="modal-dialog modal-sm succes-modal">
    <div class="modal-content">
      <div class="modal-body text-center p-16">
        <div class="succes-modal__title-modal text-center p-b-8">
          <h5 class="f12 upper m-0" [ngClass]="class"><strong>{{ tit | translate }}</strong></h5>
        </div>

        <p *ngIf="opt !== 4 && opt !== 7 && opt !== 8 && opt !== 11 && opt !== 18" class="text-center f13 m-b-30">{{ msg | translate }}</p>
        <p *ngIf="opt === 4 || opt === 7 || opt === 8 || opt === 11 || opt === 18" class="text-center f13 m-b-30"><span *ngFor="let items of msg">{{ items | translate }}</span></p>

        <div class="flex align-items-center justify-content-around btns-footer">
          <button *ngIf="!bot2" type="button" class="btn btn-newD btn-cancel" aria-label="Close" (click)="close()">{{ 'actions.close' | translate }}</button>
          <button *ngIf="bot" type="button" class="btn btn-newD btn-cargar" aria-label="Close" (click)="closeS()">{{ 'actions.continue' | translate }}</button>
          <button *ngIf="bot2" type="button" class="btn btn-newD btn-cargar" aria-label="Close" (click)="closeUnd()">{{ 'actions.understood' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
