import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderManagmentService {

  private skeleton: boolean = false;
  private loader: boolean = false;

  constructor() { }

  presentLoading(): void { this.loader = true; }
  dismissLoading(): void { this.loader = false; }

  presentSkeleton(): void { this.skeleton = true; }
  dismissSkeleton(): void { this.skeleton = false; }

  isLoading(): boolean { return this.loader; }
  isSkeleton(): boolean { return this.skeleton; }
}
