import { Injectable } from '@angular/core';
import { LANGACTUAL, LANGS, defaultLanguage } from '@data/lang';
import { StorageKeys } from '@enums/storage.enum';
import { LANG } from '@models/entities/general/lang';
import { StorageService } from '@providers/storage/storage.service';

declare var require: any;

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  private _langs = LANGS;
  private _langActual = LANGACTUAL;
  private _languageMessage;
  private _currentlanguage = '';

  constructor(private storage: StorageService) {
    this._languageMessage = this.getJsonLanguages(this._currentlanguage);
  }

  getJsonLanguages(lang): any {
    switch (lang) {
      case 'es' : return require('@assets/i18n/es.json');
      case 'en' : return require('@assets/i18n/en.json');
      default : return require('@assets/i18n/es.json');
    }
  }

  async getTranslateUse(): Promise<string> {
    try {
      this._currentlanguage = await this.storage.getMyData(StorageKeys.CURRENT_LANGUAGE) || defaultLanguage;
      this._languageMessage = this.getJsonLanguages(this._currentlanguage);
      return this._currentlanguage;
    } catch(err) {
      this._currentlanguage = defaultLanguage;
      this._languageMessage = this.getJsonLanguages(this._currentlanguage);
      throw this._currentlanguage;
    }
  }

  async setUserTranslate(language: string): Promise<void> {
    await this.storage.setData(StorageKeys.CURRENT_LANGUAGE, language);
    this._languageMessage = this.getJsonLanguages(this._currentlanguage);
    const lang = this._langs.find( lang => lang.key === this._currentlanguage);
    if (lang) {
      this._langActual.icon = lang.icon;
      this._langActual.langName = lang.title;
      this._langActual.langValue = lang.id;
    }
    const html = document.querySelector('html');
    html.setAttribute('lang' , this._currentlanguage || defaultLanguage);
  }

  // GET's
  public get currentlanguage(): string {
    return this._currentlanguage;
  }

  public get languageMessage(): any {
    return this._languageMessage;
  }

  public get langs(): LANG[] {
    return this._langs;
  }

  public get langActualIcon(): string {
    return this._langActual.icon;
  }

  public get langActualTitle(): string {
    return this._langActual.langName;
  }

  public get langActualValue(): number {
    return this._langActual.langValue;
  }

  // SET's
  public set currentlanguage(language: string) {
    this._currentlanguage = language;
  }

  public set langActualIcon(icon: string) {
    this._langActual.icon = icon;
  }

  public set langActualTitle(title: string) {
    this._langActual.langName = title;
  }

  public set langActualValue(value: number) {
    this._langActual.langValue = value;
  }
}