export const esLocale = {
  language: 'es',
  data: {
    actions: {
      off: 'Apagar',
      on: 'Encender',
      off_on: 'Apagar/Encender',
      open: 'Abrir',
      action: 'Acción',
      assign: 'Asignar',
      reassign: 'Reasignar',
      noAction: 'Acción no permitida',
      update_count: 'Actualizar conteo',
      updateSave: 'Actualizar y guardar',
      active_deactive: 'Activar/Inactivar',
      change: 'Cambiar',
      change_active: 'Cambiar y activar',
      active: 'Activar',
      modify: 'Modificar',
      mod_data: 'Modificar datos',
      inv_Form: 'Formato inválido',
      search: 'Buscar',
      continue: 'Continuar',
      send: 'Enviar',
      sendCode: 'Enviar código',
      select_all: 'Seleccionar todos',
      select_allnt: 'Deseleccionar todos',
      add: 'Agregar',
      addClose: 'Agregar / Cerrar',
      apply: 'Aplicar',
      accept: 'Aceptar',
      load: 'Cargar',
      generate: 'Generar',
      not_apply: 'No aplicar',
      save: 'Guardar',
      save_ch: 'Guardar cambios',
      next: 'Siguiente',
      previous: 'Anterior',
      colect: 'Colectivo',
      direct: 'Directo',
      newPlace: 'Nueva Plaza',
      close: 'Cerrar',
      cancel: 'Cancelar',
      understood: 'Entendido',
      solicit: 'Solicitar',
      edit: 'Editar',
      verify: 'Verificar',
      view: 'Ver',
      clean: 'Limpiar',
      delete: 'Eliminar',
      assigning: 'Por asignar',
      way_sel: 'Seleccionar vía',
      seat_sel: 'Seleccionar asientos',
      donwload: 'Proceder con la descarga',
      all: 'Todos',
      send_Ag: 'Reenviar código',
      valid: 'Validar',
      verify_asoc: 'Verificar y asociar',
      send_sol: 'Enviar solicitud',
      send_report: 'Enviar reporte',
      return: 'Regresar',
      save_config: 'Guardar configuración',
      sync: 'Sincronizar',
      move: 'Trasladar',
      mod_role: 'Modificar role',
      sendCodeVerify: 'Enviar código verificación',
      recover: 'Recuperar',
      create: 'Crear',
      select: 'Seleccionar',
      authorize: 'Autorizar',
      receive: 'Recibir',
      cancelTicket: 'Anular',
      exitNoSaving: 'Salir sin guardar',
      exitSaving: 'Guardar y salir',
      modifyTime: 'Modificar tiempo',
      modifyMarker: 'Modificar marcador',
      modifyExit: 'Modificar/Salir',
      preSave: 'Preguardar',
      getPaid: 'Cobrar',
      addSelection: 'Pre-asignar selección',
      saveAssign: 'Guardar y asignar',
      update: 'Actualizar',
      addRow: 'Agregar fila',
      decline: 'Rechazar'
    },
    alerts:{
      passerErrorMessage: 'Los sentimos, algo ha sucedido y no es posible procesar la solicitud. Por favor intenta de nuevo',
      errDTST: 'Problemas de conexión',
      errDTSD: 'Lo sentimos, parece que hubo un microcorte de Internet. Cierra esta ventana e intenta de nuevo, si el error persiste contáctanos.',
      error_timeoutT: 'Sin respuesta',
      error_timeoutD: 'Parece que algo anda mal y no hay respuesta del servidor. Nuestras disculpas. Por favor espera y trata en unos minutos.',
      error_maintenanceT: 'En mantenimiento',
      error_maintenanceD: 'Nuestras disculpas, el servicio está en mantenimiento. Será reestablecido a la mayor brevedad. Trata en unos minutos.',
      title_err: 'Ha ocurrido un error',
      requ_data: 'Datos requeridos',
      log0: 'La autenticación falló. Usuario no encontrado.',
      log2: 'La autenticación falló. Usuario con activacion de cuenta pendiente.',
      log3: 'La autenticación falló. Usuario con reseteo de contraseña pendiente.',
      log4: 'Código incorrecto.',
      log5: 'Debes ingresar el email de tu cuenta passer para enviarte un link en el cual puedas modificar tu contraseña.',
      log6: 'Revisa tu correo. Te hemos enviado un link en el cual podrás recuperar tu contraseña.',
      req_code: 'Código requerido',
      tickets_det: 'Dedigita: Rango de fecha, Nombre del cliente o el Código del tiquete.',
      tickets_fields: 'Debe seleccionar todos los campos (Origen, Destino, Vía, Horario, Fecha), antes de poder asignar asientos.',
      tick_phone: 'No existe usuario con dicho teléfono.',
      tick: 'Debes seleccionar al menos un asiento o indicar la cantidad de tiquetes de pie a vender.',
      pric: 'Hubo un error al cargar el precio del trayecto. Intenta ingresando los datos de nuevo.',
      journey_sched: 'El trayecto seleccionado no posee horarios de trabajo. Pruebe con otro trayecto o vía.',
      prefT: 'Espacio preferente',
      prefD1: 'El asiento número ',
      prefD2: ' es preferente por lo que recuerda que si una persona con necesidades especiales lo requiere tendrás que elegir otro.',
      prefStanding: 'Este asiento es preferente por lo que recuerda que si una persona con necesidades especiales lo requiere tendrás que elegir otro.',
      seat_busy: 'El espacio seleccionado está ocupado.',
      noMoreTime: 'Tiempo agotado',
      noMoreTimeText: 'Lo sentimos, pasaron 5 minutos de compra y tus asientos se han liberado',
      seats_discT: 'Hubo un problema',
      seats_discD: 'La conexión con el servidor de sockets falló. Revisa tu conexión a internet o vuelve a intentar más tarde.',
      ficha: 'Información actualizada el ',
      perm: 'Debe seleccionar una opción',
      perm2: 'Debe seleccionar al menos una opción.',
      create_t: 'No disponible',
      create_d: 'Esta parte del sistema se encuentra aún en desarrollo.',
      role_tik: 'No puedes realizar esta acción porque el elemento que intentas eliminar está asociado a un role operativo o ya tiene tiquetes vendidos.',
      permDelT: 'Acción denegada',
      permDelD: 'El usuario que tratas de eliminar es el único Superadministrador y debes tener al menos un Superadministrador en tu compañía.',
      permChD: 'El usuario al que tratas de cambiar su tipo de acceso es el único Superadministrador y debes tener al menos un Superadministrador en tu compañía.',
      email_dupl: 'Este usuario ya existe en el sistema de Passer.',
      noAccT: 'Sin acceso',
      noAccD: 'No estas asignado a ninguna oficina, por favor contacta a tu administrador.',
      noAccTS: 'Oficina cerrada',
      noAccDS: 'Estas fuera de horario de servicio. Si quieres extender el horario contacta a tu administrador.',
      suspendedAccount: 'Esta cuenta se encuentra suspendida.  Por favor contacta a tu administrador de cuentas.',
      send_t: 'Código enviado',
      hasNoPaymentsTitle: 'ADVERTENCIA - CREDENCIALES ECOMMERCE',
      hasNoPaymentsDescription: 'Esta suscripción es del tipo <b>{{type}}</b> y aún no han sido asignados credenciales de ecommerce para procesamiento de ventas en línea. Por favor corrige o no podrás vender tus servicios de manera online.',
      logOutTitle: 'Cierre de sesión',
      logOutDescription: 'Haz realizado 3 intentos fallidos, por seguridad la sesión se cerró y deberás ingresar otra vez.',
      subscriptionBase: 'Esta acción no está permitida para tu tipo de suscripción. Aumenta tu plan para activar esta función.',
      dateRange: 'Debes seleccionar una fecha de inicio y final.',
      imageSize: 'El tamaño máximo de subida es de 10 MB',
      multipleFiles: 'No se pueden utilizar varios archivos',
      anotherSessionTitle: 'Multiples sesiones',
      anotherSessionDescription: 'Detectamos que tienes varias sesiones abiertas, cerraremos esta sesión y seguirás con la última con la que ingresaste.',
      updateTitle: 'ACTUALIZACIÓN IMPORTANTE',
      updateDescription: 'Notamos que tienes una versión algo antigua. Te invitamos a que por favor actualices para poder seguir usando el sistema. Las nuevas versiones traen muchas mejoras de rendimiento, estabilidad y estética.',
      ERRORS: {
        MINLENGTH: 'La longitud mínima de este campo es',
        MAXLENGTH: 'La longitud máxima de este campo es',
        MIN: 'La cantidad mínima de este campo es',
        MAX: 'La cantidad máxima de este campo es',
        REQUIRED: 'Este campo es obligatorio.',
        CHARS: 'caracteres',
        INVALID_FORMAT: 'Formato inválido',
        PASSWORD_VALID: 'La contraseña debe tener 1 mayúscula, 1 minúscula y un numero',
        PASSWORD_MINLENGTH: 'La contraseña debe tener un mínimo de 8 caracteres',
        ERROR_CONFIRMPASSWORD: 'Contraseñas no coinciden.'
      },
      code: {
        fileT: 'Archivos muy grandes',
        fileD: 'Parece que estás guardando archivos con tamaños muy grandes. Por favor intenta subirlos de nuevo, sube archivos menos pesados o comunicate con el servicio Enterprises de Passer.',
        copnyData2: 'Debes digitar todos los datos solicitados anteriormente de la empresa. Revisa que la cédula jurídica tenga al menos 10 caracteres.',
        copnyError: 'Debes seleccionar al menos una empresa',
        canT: 'Debes digitar todos los datos correspondientes de las rutas y tramos agregados.',
        canVD: 'No puedes ingresar placas iguales.',
        canVD2: 'No puedes ingresar números de unidades iguales.',
        dup_term: 'Solo puedes ingresar tus terminos una ves por día.',
        empty_oficial: 'Debes seleccionar un origen y destino antes de agregar un trayecto de venta regulado.',
        rep_oficial: 'Ya has agregar dicho trayecto al listado de venta regulado.',
        selected_oficial: 'Debes seleccionar una de las opciones del listado de trayectos de venta regulado.',
        origin_first: 'El inicio debe ser un tramo anterior al fin.',
        err_buss: 'Error al cargar catálogo de tipos de entidadess.'
      },
      modal: {
        titS: 'Acción exitosa',
        titE: 'Error desconocido',
        msgS: 'Los cambios o ajustes se han generado y/o guardado con éxito.',
        msgE: 'Algo anda mal, lo sentimos. Por favor intenta otra vez, si el problema persiste utiliza el chat de asistencia o llama al 4001-8280.',
        msgErr: 'Algo anda mal, lo sentimos. Por favor intenta otra vez, si el problema persiste, comunicate con el serivicio Enterprises Passer.',
        no_susc_add: 'Tu cuenta passer no tiene una suscripción agregada. Por favor, comunicate con el servicio Enterprises.',
        no_susc_act: 'Tu cuenta passer no tiene una suscripción activa. Por favor, comunicate con el servicio Enterprises.',
        credentialsTitle: 'VERIFICAR IDENTIDAD',
        credentialsDescription: 'Por seguridad, vuelve a ingresar tu contraseña de cuenta Passer para mantener la sesión activa',
        tiq_perm_term: {
          tit_tmOut: 'Timeout',
          desc_tmOut: 'Lo sentimos, pasaron más de 5 minutos y los asientos que habías seleccionado fueron liberados para que otros usuarios los pudiesen comprar. Por favor intenta otra vez.',
          desc_tmOutM: 'No puedes tener menos de 0 tiquetes de pie, ni más que el máximo permitido.'
        }
      },
      office: {
        newOff: 'Debes digitar y seleccionar los datos respectivos (Nombre - País - Provincia/Estado - Cantón/Ciudad - Dirección - Teléfono) de la nueva oficina.',
        data_sche: 'Debes ingresar una hora de apertura, una de cierre y seleccionar al menos un día de la semana.',
        days_err: 'Parece que hay un conflicto de horarios en dos o más días. Por favor revisa y corrige.',
        range_err: 'Uno de los horarios no cumple con el formato permitido. La hora de cierre debe ser al menos una hora mayor a la de entrada.'
      },
      employee: {
        4: 'Debe digitar todos los datos solicitados (Ingreso - Tipo licencia - Expira - Registro de Puntos).',
        ficha1: 'Debes digitar todos los datos requeridos (Nombre, Apellidos, Tipo ID, Identificación, País, Dirección, Código país, Teléfono, Email, Ingreso).',
        ficha2: 'Debes digitar todos los datos requeridos de las licencias (Tipo, Expiración).',
        modal:{
          lic: 'Debes seleccionar un tipo de licencia y su fecha de expiración.'
        },
        empl_inac: {
          title: 'Inactivar conductor',
          desc_inacPre: 'Si pones a este conductor en estado inactivo no podrá ser tomado en cuenta para los roles de servicio ¿Deseas continuar?'
        },
        empl_del: {
          title: 'Eliminar conductor',
          desc_delPre: '¿Realmente deseas eliminar este conductor? Si lo haces ya no podrá ser asignado a roles ni habrá registros de su actividad. Si lo prefieres puedes inactivarlo.'
        }
      },
      unit: {
        seat1: 'No puedes posicionar un asiento sobre otro.',
        seat2: 'No puedes agregar un asiento en el pasillo.',
        seat3: 'Este asiento ya está asignado en el bus, intenta mover el que está dentro del bus.',
        seat4: 'El asiento que tratas de agregar ya se encuentra dentro del bus.',
        plate: 'La placa digitada ya existe. Por favor digite otra.',
        spacesError: 'Cantidad de espacios menor a la permitida, por favor revisa.',
        alert33: 'La cantidad máxima de columnas es de 5, sumando las del lado izquierdo y derecho. Ten encuenta que solo una de las dos columnas puede llegar a 3.',
        data4: 'Debe digitar como mínimo 4 y máximo 10 números diferentes para los asientos preferenciales.',
        not_delLast: 'Debes mantener al menos un vehículo en el sistema. Si lo que deseas es cancelar tu cuenta comunícate con el centro de soporte Enterprises.',
        del: 'Al eliminar este modelo se desvincularán todas las placas asignadas por lo que tendrás que asignarlas nuevamente ¿Deseas continuar?',
        mod: 'Al modificar este modelo, toma en cuenta que  los cambios se realizarán a todos los vehículos asignados. ¿Deseas continuar?',
        exist: 'El modelo con el nombre ',
        exist2: ' ya existe. Por favor intenta de nuevo, pero ingresando otro nombre para el modelo que deseas crear.',
        delM: 'El modelo que estás intentando eliminar tiene vehículos asignados. Ve a consultar y elimina o reasigna los vehículos de dicho modelo hasta que no quede ninguno y así puedas eliminar este modelo.',
        unit_func: {
          tit_rem: 'Retirar de circulación',
          desc_rem1: 'Estás por retirar de circulación el vehículo ',
          desc_rem2: '. Si lo haces ya no podrás asignarlo a los roles de operación.',
          tit_del: 'Eliminar vehículo',
          desc_del1: 'Estás por eliminar el vehículo ',
          desc_del2: '. Recuerda que eliminar un vehículo no implica el retiro de su respectivo lector.'
        }
      },
      no_route: {
        addStrTitle: 'Agregar tramos',
        addStrDescription: 'Debes agregar todos los tramos a lo largo del trayecto.',
        capacityT: 'Agregar aforo',
        capacityD: 'Debes agregar el aforo para cada tramo de la ruta.',
        no_jourT: 'Error de ingreso de datos',
        no_jourD: 'Debes ingresar un punto de inicio y final para el trayecto que deseas crear/modificar.',
        tit_click1: 'Arrastrar marcador',
        desc_click1: 'Ya agregaste ambos puntos (Origen - Destino), para modificar el recorrido solo debes arrastrar el punto deseado.',
        ch_routeT: 'Vehículo necesario',
        ch_routeD: 'No puedes cambiar el estado del trayecto, debido a que no tienes al menos un vehículo creado.',
        desc_back: 'Si regresas perderás los cambios realizados. ¿Seguro que deseas continuar?',
        route_list: {
          tit_in: 'Inactivar Trayecto',
          desc_inPre: 'Estás por inactivar un trayecto. Si continuas éste no podrá ser tomado en cuenta para los roles de operación ¿Deseas continuar?'
        },
        route_del: {
          desc_delPre: '¿Realmente deseas eliminar este trayecto? Se perderá también toda información de otros módulos que se encuentre asociada.'
        }
      },
      profile: {
        modalCh: {
          email: 'Formato de email no válido',
          logOut: 'Los cambios o ajustes se han generado y/o guardado con éxito. Ahora debes iniciar sesión de nuevo para poder usar todas las funcionalidades del sitio con normalidad.'
        }
      },
      stop: {
        1: 'Debe ingresar una punto en el mapa para continuar.',
        4: 'Aún no se han guardado los cambios de la parada que se está modificando.',
        5: 'Debes seleccionar una de las paradas antes de poder elimiarla/modificarla.',
        6: 'No puedes eliminar/modificar las dos primeras paradas, debido a que representan el punto de origen y destino respecctivamente.'
      },
      stretch: {
        1: 'No hay tramo por asignar. Ya creaste todos los tramos para dicho trayecto. Si no es así, por favor revisa que se hayan creado tramos en la sección de canones o que no se haya generado un error al momento de obtener los tramos.',
        2: 'Debe digitar la hora y minutos del recorrido. Las horas y minutos deben ser mayor o igual a 0, los minutos menor a 60. Si ingresas 0 horas, debes digitar al menos un minuto.',
        3: 'Aún no se han guardado los cambios del tramo que se está modificando.',
        4: 'Debe seleccionar un \"Origen-Destino\".',
        5: 'No puedes mover el marcador antes o después de un marcador anterior o siguiente a el respectivamente.',
        7: 'Debes seleccionar uno de los tramos antes de poder elimiarlo/modificarlo.',
        8: 'Has llegado al último tramo de la ruta, no debes colocar el marcador en el mapa, únicamente ingresa la información de tiempo de recorrido y presiona \"Agregar\".',
        10: 'No hay más tramos por agregar.',
        11: 'Debes ingresar un tiempo de recorrido menor o igual al tramo siguiente.',
        12: 'Debes ingresar un tiempo de recorrido mayor o igual al tramo anterior.',
        noAddMarker: 'Ya agregaste el marcador, para modificar el lugar solo debes arrastrar el marcador agregado.',
        lastMarker: 'No puedes mover el último marcador, si deseas realizar esta acción debes ir al tab \"TRAYECTO PADRE\" y mover el marcador final del trayecto padre.'
      },
      roles: {
        1: 'Este conductor ya posee un role previo asignado que impide asignar este nuevo role.  Procupara que existan al menos 1 hora de diferencia entre la hora de salida del nuevo role y  la hora de cierre del role previo.',
        2: 'Click aquí para ver los criterios para la creación de roles.',
        3: 'Por favor selecciona una fecha y horario para continuar el proceso.',
        4: 'El conductor no posee disponibilidad en este horario.',
        5: 'Debes seleccionar un conductor y unidad.',
        6: 'El trayecto y vía seleccionado no posee horarios de trabajo.',
        7: 'Antes de ver ficha, debes seleccionar un conductor.',
        8: 'El trayecto y vía seleccionado no posee horarios de trabajo para lo que resta del día de hoy.',
        9: 'A ocurrido un error. Revisa que la fecha de salida sea menor a la de cierre. En caso de ser las mismas fechas, revisa que la hora de salida sea menor que la de cierre.',
        10: 'Si deseas hacer este role recurrente, debes agregar una fecha de finalización.',
        dup_roleT: 'Role duplicado',
        dup_roleD: 'Ya existe un role con estos mismos parámetros, si deseas agregar una extra, crea un nuevo horario y luego intenta otra vez.',
        tit_newR: 'Role exitoso',
        desc_newR1: 'Se ha creado con éxito el role para ',
        desc_newR2: ' en la unidad ',
        desc_newR3: ', para el trayecto ',
        remove2: 'Debes seleccionar un nuevo conductor para realizar el retiro de conductor.',
        platCant: 'Asegurate de seleccionar una nueva placa y digitar la cantidad de trasbordos para proseguir.',
        no_move: 'No puedes retirar el vehículo porque el role ya está en curso. Lo que puedes hacer es realizar un trasbordo.',
        no_delete: 'No puedes retirar el vehículo porque el role ya está en curso.',
        no_transf: 'Para poder realizar un trasbordo, el estado del role debe estar \"En curso\".',
        role_no_active: 'El role seleccionado no está activo.',
        roleTemp: 'Esta acción no esta permitida para los roles temporales. Para poder llevar a cabo estas acciones, primero debes crear el role en la sección <b>NUEVO ROLE</b>',
        restartTrip: 'Solo puedes reiniciar un viaje cuyo role esté en curso',
        move: {
          4: 'Debes seleccionar una nueva unidad para realizar el traslado de conductor.'
        }
      }
    },
    copny:{
      sel_photo: 'Debes cargar una imagen antes de poder cambiarla. Si deseas salir del modal sin realizar cambios, presiona el botón \"Cerrar\"',
      alerts: {
        asoCop_no: 'No se encontraron compañías asociadas.',
        asoCop_err: 'Error al cargar compañías asociadas.',
        empty_terms: 'Debes llenar todos los campos de los términos y condiciones de tu servcicio.',
        shipmentLimitError: 'No se pudo obtener la configuración de carga.',
        packageTypeError: 'No se pudo obtener la lista de paquetes con sus respectivas tarifas.',
        weightError: 'No se pudo obtener la configuración de pesos.',
        contactError: 'No se pudo obtener los contactos de soporte.'
      },
      companyData: {
        imageWall: 'CARGA LA PORTADA DE TU EMPRESA',
        infoCompanyTitle: 'INFORMACIÓN DE LA EMPRESA',
        infoCompanyDescription: 'Agrega la información de la empresa que estará relacionada a esta suscripción.',
        legalRepTitle: 'REPRESENTANTE LEGAL',
        legalRepDescription: 'Agrega la información de la persona física que representa la empresa.',
        loadCrop: 'Cargar y recortar el logo de tu empresa',
        loadCropWall: 'Cargar y recortar la portada de tu empresa',
        twoRepresentatives: 'Solo puedes agregar dos representantes',
        representativeEmpty: 'Debes ingresar al menos un representante.'
      },
      headers: {
        price: "Tarifa",
        packageType: "Tipo paquete",
        position: "Puesto"
      },
      termsData: {
        hasNoPolicies: 'No tengo políticas propias, utilizar las políticas generales de Passer Enterprises',
        termTitle: 'TÉRMINOS Y CONDICIONES DE TU SERVICIO',
        termDescription: 'Esta información se verá reflejada en el sitio web de Passer para que los usuarios la puedan consultar y conocer tus políticas de uso.',
        changeReemb: 'Políticas sobre cambios y reembolsos',
        scheduleService: 'Políticas sobre horarios y prestación de servicios',
        userAttention: 'Políticas sobre atención de usuarios',
        otherService: 'Otras consideraciones sobre los servicios'
      },
      tabs: {
        profile: "Perfil de Empresa",
        config: "Configuraciones"
      },
      configuration: {
        title: 'Condiciones',
        subTitle: 'Condiciones de ventas',
        desc: 'Utiliza los parámetros para definir tus condiciones de venta online, operación y otros términos importantes que los usuarios deban conocer.  Lo que nos indiques acá se considerará como parte de tus políticas comerciales.',
        acceptConditions: 'Entiendo y acepto que estas condiciones de venta formarán parte de mis políticas de servicio, las cuales se mostrarán al usuario final antes de cada compra y que Passer las aplicará según lo aquí dispuesto.',
        params: "Los parámetros de cobro determinan la forma en que se realizarán los cálculos de acuerdo al modelo de medidas que se seleccione para cada artículo, así como la figura legal con la cual realizarás la facturación",
        tabList: [
          { action: 'tickets', name: 'BOLETERÍA' },
          { action: 'logistic', name: 'LOGÍSTICA' },
          { action: 'busApp', name: 'BUS APP' },
          { action: 'support', name: 'SOPORTE' }
        ],
        tabLogistic: [
          { action: 'weight', name: 'PESOS' },
          { action: 'costs', name: 'TARIFARIO' },
          { action: 'charge', name: 'CARGA' },
        ],
        tickets: {
          title: 'POLÍTICAS DE OPERACIÓN',
          desc: 'Los atributos de operación te permiten definir normas y características en función de lo que sea más viable para tu negocio. Esta información será presentada al usuario final para su conocimiento.',
          boarding_way: '¿PERMITIR ABORDAJES EN CARRETERA?',
          sale_after: '¿PERMITIR COMPRAS DESPUÉS DE LA HORA DE SALIDA?',
          max_time: 'Tiempo máximo para compra desde la app',
          maxTimeDES: 'Tiempo máximo para venta desde boletería física',
          changes: '¿ADMITIR CAMBIOS?',
          max_departure_time: 'Tiempo máximo antes de la salida',
          max_arrival_time: 'Tiempo máximo después de la salida',
          max_changes: 'Máximo cambios permitidos',
          max_value: 'Valor máximo permitido',
          maxTimeValid: 'Expiración',
          availabilityBuyTitle: 'DISPONIBILIDAD PARA COMPRA',
          availabilityBuyDescription: 'Máximo de tiempo con el que permites comprar con antelación',
          authorizationTitle: 'AUTORIZACIÓN PARA DESCUENTOS',
          authorizationDescription: 'Indica si deseas que los vendedores tengan que contar o no con la autorización de un administrador de suscripción para poder realizar descuentos.',
          noAuth: 'No requiere autorización',
          yesAuth: 'Requiere autorización',
          timeBeforeCancellations: 'Tiempo máximo antes de la salida',
          timeAfterCancellations: 'Tiempo máximo después de la salida',
          onlyOriginal: 'Solo en plaza original',
          anyPlace: 'Cualquier plaza',
          cancellationAllow: '¿ADMITIR ANULACIONES?',
          allowOnRoad: '¿PERMITIR ABORDAJES EN CARRETERA?',
          printCopy: '¿IMPRIMIR COPIA DE LOS BOLETOS?',
          refundTitle: 'REEMBOLSOS DIGITALES',
          refundAlert: 'Los reembolsos digitales son aquellos provenientes de boletos comprados por medio de la aplicación Passer para usuarios finales.'
        },
        busApp : {
          title: 'Configuración de funciones para la app del conductor',
          description: 'Utiliza estas configuraciones para habilitar o inhabilitar funciones de tus conductores.',
          reversionTitle: 'REVERSIONES DE COBRO',
          reversionDescription: 'La función de reversión permite que los conductores anulen un cobro realizado durante su recorrido (no boletos). Las anulaciones se descuentan de su recaudo',
          ticketOnRoadTitle: 'BOLETOS EN CARRETERA',
          ticketOnRoadDescription : 'Esta función faculta al conductor para que pueda realizar venta de boletos desde su app.',
          enable: 'Habilitar',
          disable: 'Inhabilitar',
          notificationTitle: 'NOTIFICACIONES VIAJE FINALIZADO',
          notificationDescription: '¿Deseas que los administradores de suscripción o encargados de role reciban un email cada vez que el conductor finaliza un viaje?',
          allUsers: 'Todos los administradores y enroladores/operativos',
          onlySomeUsers: 'Solo algunos administradores y enroladores'
        },
        instructions: {
          title: 'INSTRUCCIONES GENERALES',
          desc: 'Políticas, criterios o temas importantes que deseas que apliquemos y tengamos en consideración con relación a tu operación',
          specials: 'Describe las instrucciones'
        },
        support: {
          title: "Instrucciones para el servicio de soporte Passer",
          description: "Permítenos conocer información relevante que deseas que nuestro personal de atención y soporte a usuarios tengan en cuenta y aplique al momento de abordar incidencias."
        },
        contacts: {
          title: "CONTACTOS",
          description: "Personas, teléfonos y correos a los cuales el personal de soporte Passer se pueda remitir en caso de emergencias o dudas."
        },
        billing: {
          title: "FACTURACIÓN",
          legalTitle: "FIGURA LEGAL",
          legalDescription: "Utilizar la identificación legal de esta suscripción Enterprises",
          consultTributation: "Consultar en tributación",
          comercialName: "Nombre comercial",
          legalName: "Nombre legal",
          typeOfTaxPayer: "Tipo contribuyente:",
          economicActivity: "Actividad económica:",
          electronicBillingTitle: "COMPROBANTES ELECTRÓNICOS",
          electronicBillingTDescription: "¿Emitir comprobantes electrónicos?",
          remainderBilling: "Se utilizará la información de tributación consultada",
          agency: "Agencia",
          indexFact: "Consecutivo facturación",
          atvUser: "Usuario ATV",
          atvPass: "Contraseña ATV",
          pin: "pin",
          emitCredit: "¿Emitir notas de crédito?",
          reminderCredit: "Se considerará como nota de crédito cuando realices la anulación total o parcial del cobro realizado para un servicio de envío.",
          cancelSend: "ANULACIÓN DE ENVÍOS",
          cancelSendTime: "Únicamente previo a hora de envío",
          canceSendNotime: "Después hora de envío",
          maxTimeCancel: "Tiempo máximo para cancelación",
        },
        weight: {
          title: "VOLUMETRÍA",
          description: "El cobro por peso volumétrico es ideal cuando el artículo tiene un relativo bajo peso pero una ocupación de espacio desproporcional a dicho peso. El factor de conversión estándar suele oscilar entre 4000 y 6000.",
          volumeFactor: "Factor volumétrico",
          pricekg: "Tarifa por kilo",
          priceLib: "Tarifa por libra",
          fixWeight: "PESO FIJO",
          fixWeightDesciption: "Define la tarifa de cobro por cada kilo o libra de peso."
        },
        costs: {
          title: "PAQUETERÍA",
          description: "El cobro fijo para paquetes es ideal si ya tienes una estándar de tarifas para los diferentes tipos de paquetes indistintamente de su tamaño y peso.",
          packageName: "Nombre del paquete",
          price: "Tarifa"
        },
        charge: {
          description: "El valor de carga define la cantidad máximo de envíos (indistintamente de peso y medidas) que se podrán cargar en cada vehículo.",
          unlimitedShipments: "Envíos ilimitados",
          maxShipments: "Máximo de envíos"
        }
      }
    },
    dashboard: {
      title: 'MONITOREO Y SOPORTE',
      unit_search_input: 'Ingrese el número de unidad',
      act_speed: 'Velocidad actual',
      pass: 'Pasajeros',
      now_board: 'Abordo en este momento',
      day_board: 'Transportados durante el día',
      ret_role: 'Retirar del role',
      remove: 'Retirar',
      alerts: {
        no_unit: 'No se encuantra la unidad. Intenta seleccionando otra de las opciones dadas.',
        number_unit: 'Debes digitar el numero de unidad antes de realizar la búsqueda.',
        no_infoBus: 'No se pudo obtener la información del bus seleccionado.',
        err_route: 'Error al cargar ruta.',
        err_info: 'Error al cargar información.',
        errorRestriction: 'Error al cargar las restricciones.',
        dateEmptyTitle: 'Hora sin asignar',
        dateEmptyDescription: 'Aún no has creado la primera condición de hora.',
        warningDeleteTitle: 'Eliminar restricción',
        warningUpdateTitle: 'Cambiar estado restricción',
        warningDeleteDescription: 'Estás por eliminar una restricción ¿Deséas continuar?',
        warningUpdateDescription: 'Estás por Cambiar el estado de la restricción ¿Deséas continuar?',
        secondRestrictionTitle: 'Hora requerida',
        secondRestrictionDescription: 'La segunda condición de hora es requerida. Si no deseas agregar condiciones de hora, limpia la primer condición.'
      },
      monitoring: {
        title: 'Monitoreo',
        trace: 'Localizar',
        taps: 'Taps pago electrónico',
        tickets: 'Tiquetes abordo',
        arrival: 'Llegada aproximada',
        back: 'Vuelta',
        total: 'Recaudo total',
        outService: 'Sacar de servicio',
        comunication: 'COMUNICACIÓN',
        sendNotification: 'Enviar una notificación a este vehículo',
        updateValidator: 'El estado del validador ha sido actualizado con éxito',
        warningTitle: 'SACAR DE SERVICIO VALIDADOR',
        warningDescription: 'Estás por sacar de servicio el validador para cobro electrónico y verificación de boletos del vehículo {número}, placa {placa} ¿Deseas continuar?',
        modalTitle: 'ENVIAR MENSAJE A VEHÍCULO',
        modalDescription: 'Se enviará una notificación push y un mensaje al inbox de la app del conductor del vehículo número {{numero}}'
      },
      restrictions: {
        title: 'Restricciones',
        subTitle: 'Restricciones venta online',
        description: 'Las restricciones son acciones temporales que puedes definir para controlar la venta online de una ruta, tramo u horarios determinados, pero sin tener que deshabilitar por completo los servicios. Un ejemplo práctico de esto es cuando por circunstancias climáticas u otros factores un servicio no puede atravesar ciertos tramos en un rango de horas, quizá por derrumbes, inundaciones o bloqueos.',
        tabs: [
          {
            name: 'RESTRICCIONES',
            action: "restrictionList"
          },
          {
            name: 'NUEVA RESTRICCIÓN',
            action: "newRestriction"
          }
        ],
        restrictionLabel: 'Etiqueta restricción',
        menuRestriction: [
          { icon: 'power-on', name: 'Activar/Inactivar' },
          { icon: 'delete', name: 'Eliminar' },
          { icon: 'notifications', name: 'Notificar usuarios' }
        ],
        emptyListTitle: 'No hay restricciones creadas',
        emptyListDescription: 'Aún no has creado restricciones. Toca el botón \"NUEVA RESTRICCIÓN\" para comenzar a crear restricciones.'
      },
      newRestriction: {
        newRestriction: 'NUEVA RESTRICCIÓN',
        statusRestriction: 'Estado de la restricción',
        inactive: 'Inactiva',
        active: 'Activa',
        if: 'Sí',
        is: 'Es',
        to: 'A',
        criterion: 'Criterio',
        optionalConditions: 'CONDICIONES OPCIONALES',
        isEqual: 'Igual a',
        value: 'Valor',
        and: 'y',
        condition: 'Condición',
        conditionPlusList: [
          { name: 'Mayor que', value: '>' },
          { name: 'Mayor/Igual que', value: '>=' }
        ],
        conditionLessList: [
          { name: 'Menor que', value: '<' },
          { name: 'Menor/Igual que', value: '<=' }
        ]
      },
      notifications: {
        title: 'Notificaciones',
        description: 'Las notificaciones son mensajes cortos que puedes enviar a los usuarios de tus servicios para informarles sobre algún tema de relevancia en particular. Las personas las podrán ver el mensaje en su pantalla principal, barra de notificaciones y el inbox de la app Passer. Esta función ayuda en gran manera a mejorar la calidad y experiencia de usuario de tus servicios, ya que ayudas a las personas de manera oportuna y evitas inconvenientes. Costo por notificación.',
        tabs: [
          {
            name: 'NUEVA',
            action: 1
          },
          {
            name: 'HISTORIAL',
            action: 2
          }
        ],
        newNotification: {
          descriptionNoti: 'DESCRIPCIÓN DE LA NOTIFICACIÓN',
          activeServices: 'SERVICIOS ACTIVOS',
          activeServicesDescription: 'Puedes enviar esta notificación a todos los servicios que tengan tiquetes vendidos.',
          message: 'Mensaje (máximo 350 caracteres)',
          redirectTitle: 'REDIRECCIÓN',
          redirectDescription: '¿Dónde irán los usuarios al abrir la notificación?',
          inbox: 'Inbox de la aplicación Passer',
          external: 'Sitio externo',
          specificDate: 'Enviar esta notificación en una fecha y hora específicas',
          individualTitle: 'USUARIO INDIVIDUAL',
          individualDescription: 'Ingresa el número teléfono, incluido código de país',
          massive: 'MASIVA',
          massiveDescription: 'Selecciona un vehículo o fecha de role',
          alerts: {
            noRoleTitle: 'Role sin seleccionar',
            noRoleMessaege: 'Debes seleccionar al menos un role para poder continuar con el envío de la notificación.',
            newOperatorNotification: 'Nueva notificación de operador',
            userError: 'Error al cargar usuario.',
            OperatorNotificationeError: 'Error al cargar Notificaciones',
            OperatorNotificationsErrorToSave: 'Error al guardar la Notificaión'
          }
        }
      },
      recordNotification: {
        all: 'Todas',
        onlyProg: 'Solo programadas',
        menu: [
          { icon: 'delete', name: 'Eliminar' },
          { icon: 'eye', name: 'Ver' }
        ],
        emptyListTitle: 'No hay notificaciones creadas',
        emptyListDescription: 'Aún no has creado Notificaciones. Toca el botón \"NUEVA\" para comenzar a crear notificaciones.'
      },
      alertsNotifications: {
        title: 'Alertas',
        description: 'Permite que los usuarios conozcan de incidentes o situaciones en la ruta, por ejemplo, derrumbes o accidentes, que pudiera afectar los servicios.',
        journeyTag: 'Trayecto padre o etiqueta',
        footerRoute: 'Haz click sobre el trayecto del mapa para agregar un marcado indicativo. Las personas podrá ver esto en su aplicación Passer.',
        situation: 'Incidente o situación',
        range: 'Rango de la alerta',
        message: 'Mensaje (opcional)'
      },
      viewNotification: {
        scheduledTitle: 'NOTIFICACIÓN PROGRAMADA',
        scheduledDescription: 'Se enviará el siguiente mensaje en la fecha {{fecha}} a las {{hora}}',
        notification: 'NOTIFICACIÓN',
        notifyTitle: 'NOTIFICAR USUARIOS',
        notifyDescription: 'Se enviará una notificación alertando a todos los usuarios que utilicen la app Passer'
      }
    },
    datetime: {
      date_role: 'Fecha role',
      scheduleInitEnd: 'Horario inicio y cierre',
      saleDate: 'Fecha venta',
      settingDate: 'Fecha ajuste',
      date_range: 'Rango de Fecha',
      filter_date: 'Filtrar fecha',
      date: 'Fecha',
      dates: 'Fechas',
      schedule: 'Horario',
      schedules: 'Horarios',
      manage_date: 'Bloquear fechas',
      selectDates: 'Seleccionar fechas',
      initDate: 'Fecha inicio',
      openingTime: 'Hora apertura',
      closingTime: 'Hora cierre',
      initHour: 'Hora inicio',
      endDate: 'Fecha fin',
      endHour: 'Hora fin',
      dateInitEnd: 'Fecha inicio y cierre',
      birthday: 'Fecha nacimiento',
      date_ini: 'Fecha salida',
      date_buy: 'Fecha compra',
      dateHour_buy: 'Fecha/hora compra',
      date_close: 'Fecha cierre',
      date_ing: 'Fecha ingreso',
      dateFin: 'Fecha finalización',
      date_fin_rec: 'Fecha finalización recurrencia',
      hour_ini: 'Hora salida',
      dateHourDeparture: 'Fecha/hora salida',
      departureParentHour: 'Hora salida padre',
      hour_arrival: 'Hora llegada',
      dateHourArrival: 'Fecha/hora llegada',
      hour_close: 'Hora aproximada cierre',
      boarding_time: 'Hora Abordaje (Aprox)',
      datetimeRole: 'Fecha/Hora Role',
      'date/hour_boarding': 'Fecha/Hora abordaje',
      hour_boarding: 'Fecha/Hora abordaje',
      sendDate: 'Fecha envío',
      sendDateHour: 'Fecha/hora envío',
      deliveryDateHour: 'Fecha/hora entrega',
      deliveryDate: 'Fecha entrega',
      deliveryHour: 'Hora entrega',
      arrivalDate: 'Fecha llegada',
      datetime: 'Fecha y hora',
      range_role: 'Rango fecha',
      out: 'Salida',
      finish: 'Llegada',
      day: 'Día',
      hour: 'Hora',
      hourUpper: 'HORA',
      hours: 'Horas',
      minutes: 'Minutos',
      year: 'Año',
      years: 'año(s)',
      months: 'mes(es)',
      'month/year': 'Mes / Año',
      days: 'día(s)',
      daysT: 'Días',
      hoursMin: 'hr(s)',
      minutesMin: 'min(s)',
      searchDate: 'Buscar fecha',
      requestDate: 'Fecha solicitud',
      requestDateHour: 'Fecha/Hora solicitud',
      serviceDateHour: 'Fecha/Hora servicio',
      sig_Mon: {
        1: 'Lu',
        2: 'Ma',
        3: 'Mi',
        4: 'Ju',
        5: 'Vi',
        6: 'Sa',
        7: 'Do',
        L: 'Lun',
        M: 'Mar',
        I: 'Mie',
        J: 'Jue',
        V: 'Vie',
        S: 'Sab',
        D: 'Dom'
      },
      full_month:{
        1: 'Enero',
        2: 'Febrero',
        3: 'Marzo',
        4: 'Abril',
        5: 'Mayo',
        6: 'Junio',
        7: 'Julio',
        8: 'Agosto',
        9: 'Setiembre',
        10: 'Octubre',
        11: 'Noviembre',
        12: 'Diciembre'
      }
    },
    descriptions: {
      no_results: 'No hay registros acorde a los parámetros de búsqueda.',
      no_parameters: 'No has ingresado ningún parámetro de búsqueda.',
      photo: {
        titleS: 'Cargar y recortar el logo de tu empresa',
        titleC: 'Cargar y recortar logo de factura',
        logo_load: 'Cargar logo',
        img_load: 'Cargar imagen'
      }
    },
    employees: {
      drivers: 'Conductores',
      driver_list: {
        title: 'Lista conductores',
        active_role: 'Role activo',
        no_employees: 'No hay conductores registrados',
        no_employeesD: 'Aún no has creado conductores. Ingresa a la pestaña \"Nuevo conductor\" para comenzar a resgistrar nuevos conductores.',
        reset: 'Resetear contraseña',
        send_noti: 'Enviar notificación',
        disableAccessTitle: 'Acceso para bus driver',
        disableAccessDescription: 'Estás por eliminar el acceso a Passer Bus Driver App para este el conductor <b>{{name}}</b>. El conductor podrá ¿Deseas continuar?',
        allowReverse: 'Permitir reversiones de cobro',
        allowSales: 'Permitir venta de tiquetes en ruta',
        menuList: [
          {icon: 'contact', name: 'Perfil y valoraciones'},
          {icon: 'person-remove', name: 'Eliminar conductor'},
          {icon: 'android', name: 'OTP activación Bus Driver'},
          {icon: 'power-on', name: 'Revocar/Conceder acceso Bus Driver'},
          // {icon: 'send', name: 'employees.driver_list.send_noti'},
          {icon: 'power-on', name: 'Activar/Inactivar conductor'}
        ]
      },
      new_driver: {
        title: 'Nuevo conductor',
        info_lab: 'Información laboral',
        address: 'Dirección y contacto',
        actual_points: 'Puntos actuales (opcional)',
        statusT: 'Estado y app conductores',
        statusD: 'Indica si deseas agregar este conductor listo para roles (activo) o bien, crearlo sin ser tomado en cuenta para roles (inactivo). Podrás cambiar su estado cuando lo desees.',
        access_bus: 'Conceder acceso a Bus Driver App',
        add_driver: 'Agregar conductor',
        points: 'puntos',
        data_licR: 'Datos de licencia requeridos (Tipo licencia - Expira)',
        lengthLimit: 'Solo puedes agregar una licencia por conductor.',
        duplicateTitle: 'Licencia duplicada',
        duplicateDescription: 'No puedes agregar dos veces el mismo tipo de licencia'
      },
      alerts: {
        empl: 'Error al cargar conductores.',
        tran_errLic: 'Transacción procesada con errores. Error al guardar licencias ',
        lic_err: 'Error al guardar licencias ',
        yet_dev: 'Esta parte del sistema está aún en desarrollo.',
        err_phoneT: 'Acción denegada',
        err_phoneD: 'Conductor sin teléfono agregado. Debes ir a la ficha del conductor y agregar un número de teléfono al cual deseas enviar el SMS.',
        warn_smsT: 'ACCESO PARA BUS DRIVER',
        warn_smsD: 'Enviarás un código de acceso a ',
        warn_smsD2: '. El conductor podrá utilizar este código para acceder a Passer Bus Driver App <br>¿Deseas enviarlo?',
        role_activeT: 'Role activo',
        role_activeD: 'No puedes eliminar conductor porque tiene un role activo.',
        soonTitle: 'Próximamente',
        soonDescription: 'Sección de comentarios y valoraciones del conductor.'
      },
      table: {
        position: 'Puesto',
        lic_point: 'Puntos licencia',
        status: 'Estado',
        status_active: 'Activo',
        status_inactive: 'Inactivo',
        qualification: 'Calificación',
        consultEdit: 'Consultar y editar',
        actions: 'Acciones'
      },
      forms: {
        lastname1: 'Primer Apellido',
        lastname2: 'Segundo Apellido',
        birthday: 'Nacimiento',
        state: 'Estado / Provincia'
      },
      actions: {
        lic: 'Agregar nuevo tipo de licencia',
        annot: 'Agregar anotación',
        ini: 'Inicia',
        send_noti: 'Escribe un mensaje, no mayor a 100 caracteres, para enviar una notificación a Bus Driver App para este conductor.'
      },
      personal_info: {
        title: 'Datos personales',
        left_data: {
          title: 'Información general del conductor',
          address_contact: 'Dirección y contacto',
          save: 'Guardar información'
        },
        right_data: {
          title: 'Comentarios y valoraciones',
          desc: 'Así es como han comentado y valorado los usuarios a este conductor',
          val_global: 'Valoración global',
          val_gen: 'Valoraciones generales por categoría',
          val_comm: 'Comentarios y valoraciones individuales'
        }
      },
      labor_info: {
        title: 'Datos laborales',
        seniority: 'Antigüedad',
        licenses: 'Licencias',
        lic: 'Licencia',
        gran: 'Otorgado',
        pointss: 'Puntos',
        lost_poi: 'Puntos perdidos',
        creat: 'Creado por',
        emis: 'Emisión',
        expire: 'Expira',
        exp: 'Exp',
        points: 'Registro de puntos',
        add_license: 'Agregar licencia',
        add_pen: 'Agregar multa',
        succ: {
            title: 'Operación Satisfactoria',
            desc: 'El nuevo conductor ha sido agregado con éxito al sistema.',
            desc2: 'Ahora puedes asignarlo a un role de trabajo.',
            un_desc: 'El conductor',
            un_desc2: 'ha sido eliminado exitósamente.',
            un_desc3: 'Recuerda que ya no estará disponible para ser asignado a un role de trabajo.'
        }
      },
      comments: {
        title: 'Comentarios',
        comparison: 'Elige 2 conductores para comparar su rendimiento entre si',
        drive: 'Conducción',
        opinions: 'opinión(es)',
        pru: 'Prudente',
        tem: 'Temerario',
        norm: 'Normal',
        user_serv: 'Servicio al usuario',
        appal: 'Pésimo',
        bad: 'Malo',
        reg: 'Regular',
        good: 'Bueno',
        excel: 'Excelente'
      },
      unsubscribe: {
        title: 'Dar de baja',
        mot_baj: 'Motivo de la baja',
        dismi: 'Despido',
        resig: 'Renuncia',
        ch_post: 'Cambio de puesto',
        other: 'Otro',
        alert_tit: 'Darás de baja a este empleado',
        alert_desc: 'Al darlo de baja se eliminará totalmente del sistema, y no se podrá tomar nuevamente en cuenta para los roles. Si lo que deseas es suspenderlo temporalmente, puedes usar la opción permisos.'
      },
      permissions: {
        title: 'Permisos',
        out_rol: 'Sacar de role',
        new: 'Nuevo permiso'
      },
      annotations: {
        title: 'Anotaciones',
        anot: 'Anotación',
        new: 'Nueva anotación',
        dig_ann: 'Digitar aquí tu anotación...'
      },
      penalties: {
        title: 'Multas'
      }
    },
    footer: {
      login: {
        0: 'Soporte',
        1: 'Condiciones de uso',
        2: 'Desarrolladores'
      }
    },
    header: {
      permissions: 'Accesos',
      passerAccount: 'Cuenta Passer',
      ofice: 'Plaza de operación',
      subscriptionProfile: 'CONFIGURACIONES DE SUSCRIPCIÓN',
      collectLiquidation: 'Recaudo y liquidaciones',
      video: 'Video tutoriales',
      valid_pos: 'Validadores y Terminales',
      logWith: 'Logueado como:',
      logOut: 'Cerrar Sesión'
    },
    labels: {
      position: "Puesto",
      jobPlace: "Lugar o plaza de trabajo",
      licenseActive: 'Activa',
      licenseExpired: 'Vencida',
      adjustment: 'Ajuste',
      agent: 'Agente',
      address: 'Dirección',
      bus: 'Autobus',
      warning: 'Advertencia',
      capacity: 'Aforo',
      warn: 'Advertencia',
      apt: 'Apertura',
      benefactor: 'Beneficiario',
      benefactorMovil: 'Número beneficiario',
      connection: 'Conexión',
      disconn: 'Desconectado',
      features: 'Características',
      cirr: 'Cierre',
      route: 'Ruta',
      parentRoute: 'Ruta padre',
      way: 'Vía',
      wayTVí: 'Vía: ',
      unit: 'Unidad',
      copny: 'Empresa',
      idMin: 'ID',
      ced_fis: 'Cédula Física',
      ced_jud: 'Cédula Jurídica',
      passport: 'Pasaporte',
      origin: 'Origen',
      destination: 'Destino',
      stretchOrigin: 'Tramo origen',
      stretchDestination: 'Tramo destino',
      parentOrigin: 'Origen padre',
      parentDestination: 'Destino padre',
      journey: 'Trayecto',
      ticketRoute: 'Recorrido tiquete',
      schedule: 'Horario',
      gender: 'Género',
      female: 'Femenino',
      male: 'Masculino',
      opt_select: 'Opciones seleccionadas',
      days_filter: 'Filtrar días',
      copny_filter: 'Filtrar Empresa',
      perm_filter: 'Filtrar Permisos',
      all_selec: 'Todos seleccionados',
      name: 'Nombre',
      lastname: 'Apellidos',
      ms: 'Mensaje',
      photo: 'Foto',
      place: 'Lugar',
      code: 'Código',
      driver: 'Conductor',
      yes: 'Si',
      no: 'No',
      phone: 'Teléfono',
      movil_phone: 'teléfono móvil',
      print: 'Imprimir',
      reprint: 'Reimprimir',
      number: 'Número',
      email: 'Email',
      pass: 'Contraseña',
      user: 'Usuario',
      type_id: 'Tipo ID',
      id: 'Identificación',
      tel_assig: 'Teléfono asignado',
      active: 'Act',
      inactive: 'Ina',
      tick_online: 'Con venta online',
      urb_offline: 'Sin venta online',
      tik: 'Interurbano',
      urb: 'Urbano',
      train: 'Tren',
      window_dig: 'Ventanilla digital',
      boarding: 'Abordaje',
      stair: 'Escaleras',
      entry: 'Entrada',
      row: 'Fila',
      rows: 'Filas',
      moves: 'Movimientos',
      tick_off: 'Electrónico',
      tick_on: 'Online',
      reject: 'Rechazos',
      pending: 'pendiente',
      total_rec: 'Total recaudo',
      retention: 'Retención',
      total_pend: 'Total pendiente',
      total_acre: 'Total acreditación',
      vehicle: 'Vehículo',
      amount: 'Monto',
      taps: 'Taps',
      country: 'País',
      recurrent: 'Recurrente',
      init: 'Inicio',
      end: 'Fin',
      country_operation: 'País de operación',
      country_birth: 'País de nacimiento',
      entity_type: 'Tipo de entidad',
      legal_name: 'Nombre legal',
      bank_cred: 'Credenciales acreditaciones',
      type_device: 'Tipo dispositivo',
      phone_optional: 'Número teléfono (opcional)',
      address_ex: 'Dirección exacta',
      ing_lab: 'Fecha ingreso a laborar',
      label: 'Etiqueta',
      rates: 'Tarifas',
      info: 'Información',
      description: 'Descripción',
      vehicles: 'Vehículos',
      space: 'Espacio',
      spaces: 'Espacios',
      more: 'más',
      placeType: 'Tipo plaza',
      seat: 'Asiento',
      seats: 'Asientos',
      seatsArray: 'Asientos (Ej: 1,3,4)',
      id_ticket: 'ID Tiquete',
      stop: 'Parada',
      iva: 'IVA',
      percentage: 'Porcentual',
      fixed: 'Fija',
      wh: 'WhatsApp',
      msg: 'Meta Messenger (Facebook)',
      id_booking: 'ID Booking',
      cant: 'Cantidad',
      percent: 'Porcentaje',
      ways: 'Vías',
      terminal: 'Terminal',
      highway: 'Carretera',
      rate_base: 'Tarifa base',
      tradename: 'Nombre Comercial',
      service_type: 'Tipo de servicio',
      ingress: 'Ingreso',
      marital_status: 'Estado civil',
      type: 'Tipo',
      state: 'Estado',
      rate: 'Tarifa',
      bank_ret: 'Retención Banco',
      incomeRetention: 'Retención IVA',
      sinpeRetention: 'Retención SINPE-TP',
      gatewayRetention: 'Retención pasarela',
      passerComission: 'Comisión Passer',
      gatewayComission: 'Comisión Pasarela',
      expectedLiquidation: 'Liquidación esperada',
      totalSell: 'Venta total',
      gen_price: 'Tarifa general: ',
      stretch: 'Tramo',
      model: 'Modelo',
      brand: 'Marca',
      plate_v: 'Placa',
      plate: 'Placa',
      mechanic: 'Mecánico',
      contact: 'Contacto',
      licenseType: 'Tipo licencia',
      tickets: 'Tiquetes',
      colones: 'Colones',
      dollars: 'Dólares',
      sex: 'Sexo',
      phoneAccount: 'Teléfono de cuenta',
      emailAccount: 'Email de cuenta',
      lastConnection: 'Última conexión',
      details: 'Detalles',
      availability: 'Disponibilidad',
      role: 'ID Role',
      electronicCollection: 'Cobro electrónico',
      ecommerce: 'Ecommerce',
      ticketIn: 'Boletería física',
      totalSale: 'Venta bruta',
      collect: 'Recaudo',
      debt: 'Deuda',
      numberUnid: 'Número unidad',
      numberVehicle: 'Número vehículo',
      idValidator: 'ID Validador',
      validator: 'Validador',
      activeToggle: 'Activo',
      inactiveToggle: 'Inactivo',
      title: 'Título',
      service: 'Servicio',
      show: 'Mostrar',
      delivered: 'Entregados',
      undelivered: 'Sin entregar',
      opened: 'Abiertos',
      record: 'Historial',
      dataNotFound: 'Datos no encontrados',
      original: 'Original',
      copy: 'Copia',
      extraReprint: '- Reimpresión',
      emojiMartLabels: {
        search: 'Buscar',
        emojilist: 'Lista de emoji',
        notfound: 'Emoji no encontrado',
        clear: 'Limpiar',
        categories: {
          search: 'Resultados de la búsqueda',
          recent: 'Usado con frecuencia',
          people: 'Emoticonos y Personas',
          nature: 'Animales y Naturaleza',
          foods: 'Comida y Bebida',
          activity: 'Actividad',
          places: 'Viajes y Lugares',
          objects: 'Objetos',
          symbols: 'Simbolos',
          flags: 'Banderas',
          custom: 'Personalizado'
        },
        skintones: {
          1: 'Tono de piel predeterminado',
          2: 'Tono de piel claro',
          3: 'Tono de piel claro medio',
          4: 'Tono de piel medio',
          5: 'Tono de piel oscuro medio',
          6: 'Tono de piel oscuro'
        }
      },
      verified: 'Verificados',
      ticketCount: 'Total boletos',
      shipmentCount: 'Total envíos',
      traceMoney: 'DESGLOSE DE VENTA POR TRAMO',
      changes: 'Cambios',
      location: 'Ubicación',
      locationPlace: 'Plaza',
      fuel: 'Combustible',
      burden: 'Carga',
      seller: 'Vendedor',
      other: 'Otros',
      cashRegister: 'Caja',
      distance: 'Distancia',
      less: 'Menos',
      breakdown: 'Desglose',
      parentJourney: 'Trayecto padre',
      adults: 'Adultos',
      cancellations: 'Anulaciones',
      settings: 'Configuraciones',
      configuration: 'Configuración',
      article: 'Artículo',
      content: 'Contenido',
      long: 'Largo',
      width: 'Ancho',
      height: 'Alto',
      sender: 'Remitente',
      receiver: 'Destinatario',
      sent: 'Enviados',
      toReceive: 'Para recibir',
      takeOut: 'Sacar',
      dimensions: 'Dimensiones',
      volumetricWeight: 'Peso volumétrico',
      fixedWeight: 'Peso fijo',
      price: 'Precio',
      version: 'Versión',
      reimburse: 'Reembolsar',
      electrinicInvoice: 'Factura electrónica',
      fullName: 'Nombre completo',
      emailAccountPasser: 'Email cuenta Passer',
      reason: 'Motivo',
      refund: 'Reembolso',
      madeBy: 'Realizado por',
      cash: 'Efectivo',
      transference: 'Transferencia',
      method: 'Método',
      userDES: 'Usuario DES',
      emailPasser: 'Email usuario Passer',
      totalTickets: 'Cantidad tiquetes'
    },
    language: {
      changeLang: 'Cambiar idioma',
      spanish: 'Español',
      english: 'Ingles'
    },
    login: {
      titleT: 'Verificación de Pasos',
      desc: 'Hemos enviado el código al ',
      sign: 'Iniciar sesión',
      forg_pass: '¿Olvidaste la contraseña?',
      no_account: 'Si aún no tienes una cuenta Passer Enterprises regístrate gratis para que tu empresa evolucione a lo electrónico y digital.',
      register_copny: 'Registrar mi empresa',
      auth_two: {
        title: 'Autenticación de dos factores',
        desc: 'La autenticación de dos factores requiere que ingreses un código de verificación que fue enviado a tu email o teléfono de cuenta Passer.',
        where_send: '¿Dónde deseas recibir el código de verificación?',
        receive: 'Recibirás el código al ',
        receive2: ' terminado en:'
      },
      copny: {
        title: 'Selecciona una empresa',
        desc: 'Selecciona la empresa con la que deseas comenzar esta sesión.',
        suscription_status: {
          0: 'Cancelada',
          1: 'Activa',
          2: 'Pendiente',
          3: 'Suspendida'
        }
      },
      alerts: {
        local: 'Lo sentimos, su navegador no es compatible con el almacenamiento web',
        em_pass: 'Email y contraseña no concuerdan.',
        data_err: 'Error al cargar datos.',
        reset_err: 'Error al tratar de resetear contraseña. Verifica que el email ingresado esté activo y sea el de tu cuenta passer.',
        send_err: 'Error al enviar código.',
        closeT: 'Sistema cerrado',
        closeD: 'Por seguridad el sistema se cerró y deberás ingresar nuevamente.',
        passerIdpErrorInvalidGrant: 'Error de inicio de sesion; Correo electrónico o contraseña no válidos.',
        passerIdpErrorInvalidManagementGroup: 'Lo sentimos, el usuario que inició sesión no tiene acceso a este recurso, intente con otro o comuníquese con su administrador de cuenta de Enterprises.',
        passerIdpErrorInvalidSubscription: 'Error de inicio de sesion; Usuario sin suscripciones asociadas.'
      },
      recoverPassord: {
        title: 'RECUPERAR CONTRASEÑA',
        description: 'Ingresa tu email de cuenta Passer',
        email: 'Correo electrónico',
        backLogin: 'Volver al login'
      },
      selectSubcription: {
        title: 'SELECCIONAR EMPRESA',
        description: 'Selecciona la empresa con la que deseas comenzar esta sesión',
        displayName: 'Nombre empresa:',
        managementGroup: 'Grupo administrativo:',
        roleAssignments: 'Role:'
      }
    },
    logistic: {
      title: 'LOGÍSTICA',
      issuingPlace: 'Plaza emisora',
      receptorPlace: 'Plaza receptora',
      weight: 'Peso',
      volumetry: 'Volumetría',
      fixedRate: 'Tarifa fija',
      measurement: 'Medida',
      kilogram: 'Kilogramo',
      pound: 'Libra',
      packageType: 'Tipo paquete',
      detailsTitle: 'OTROS DETALLE DEL ENVÍO',
      receiveShipment: 'Recibir en plaza',
      deliverRecipient: 'Entregar destinatario',
      receiveFooter: 'Aplican las condiciones y políticas definidas por el operador de transporte. Para mayor información ingresa a www.passer.live/policies',
      alerts: {
        takeOutTitle: 'SACAR DEL TRANSPORTE',
        takeOutDescription: '¿Estás seguro que deseas sacar el envío del transporte asignado? Por favor verifica que el horario asignado no haya pasado aún.',
        takeOutBadState: 'No puedes sacar este envío debido a su estado actual.',
        noCancelShipment: 'No puedes anular este envío debido a su estado actual.',
        noPrint: 'No puedes reimprimir este envío debido a su estado actual.',
        noDataAssign: 'Debes seleccionar un role al cual deseas asignar el envío',
        assignBadStatus: 'No puedes asignar este envío debido a su estado actual.',
        chargeAlertTitle: 'Atención:',
        chargeAlertDescription: 'La administración ha definido una carga máxima de {{count}} envíos por vehículo.'
      },
      newSending: {
        title: 'NUEVO ENVÍO',
        detailsTitle: 'DETALLES DEL ENVÍO',
        senderReceiver: 'REMITENTE Y DESTINATARIO',
        senderName: 'Nombre del remitente',
        receiverName: 'Nombre del destinatario',
        taxClient: 'Cliente tributario',
        idClient: 'Identificación del cliente',
        clientNotRegister: 'La identificación ingresada no está registrada en el sistema o no corresponde a un contribuyente, por favor verifica.'
      },
      manageSending: {
        title: 'GESTIONAR',
        infoTitle: 'INFORMACIÓN DE LA VENTA',
        total: 'TOTAL NETO A PAGAR',
        warningTitle: 'Asignar transporte',
        warningDescription: 'Recuerda asignar buscar un role y vehículo para realizar el envío correspondiente.',
        noPackageTitle: 'No hay paquetes registrados',
        noPackageDescription: 'Aún no hay paquetes registrados. Ingresa a la pestaña "Nuevo envío" para comenzar a registrar paquetes.',
        cancelShipmet: 'Anular envío',
        closingTitle: 'MIS VENTAS',
        closingDescription: 'Detalle de la facturación según ubicación de ventas.',
        totalShipments: 'Total envíos',
        printClosing:  'Imprimir cierre',
        tabOptions: [
          { action: 'manage', name: 'ENVÍO/RECEPCIÓN' },
          { action: 'closing', name: 'CIERRES' }
        ],
        stateList: [
          { value: 1, displayName: 'En plaza' },
          { value: 2, displayName: 'Enviado/Asignado' },
          { value: 3, displayName: 'Recibido' },
          { value: 4, displayName: 'Entregado' },
          { value: 5, displayName: 'Cancelado' }
        ],
        menuList: [
          {icon: 'shipping', name: 'Asignar'},
          {icon: 'eye', name: 'Detalles'},
          {icon: 'print', name: 'Reimprimir'},
          {icon: 'outbox', name: 'Sacar'},
          {icon: 'block', name: 'Anular envío'}
        ],
        detailsMenu: [
          {icon: 'outbox', name: 'Recibir en plaza'},
          {icon: 'receivebox', name: 'Entregar destinatario'}
        ]
      },
      assignTransport: {
        title: 'ASIGNAR TRANSPORTE',
        description: 'Se muestran los servicios para la fecha seleccionada. Selecciona uno y presiona "Asignar"',
        services: 'SERVICIOS DE TRANSPORTE',
        reassignTitle: 'REASIGNAR SERVICIO',
        reassignDescription: 'Estás por reasignar el envío del vehículo placa {{plate1}} al vehículo placa {{plate2}}. ¿Deséas continuar con la reasignación?',
        assignTitle: 'ASIGNAR SERVICIO',
        assignDescription: 'Estás por asignar el envío al vehículo placa {{plate}}. ¿Deséas realizar la asignación?',
        receivedAccordance: 'Recibido conforme'
      },
      cancellationShipment: {
        title: 'ANULAR ENVÍO',
        description: 'Estar por anular el envío <b>{{track}}</b> por un monto de <b>{{amount}}</b>. Si deseas continuar confirma tu identidad y presiona "Cancelar"',
        warning: 'Si anulas este envío deberás hacer un reembolso en efectivo o a la cuenta bancaria del usuario.'
      },
      closingModal: {
        title: 'VENTANILLA (PLAZA)',
        totalShipments: 'TOTAL ENVÍOS',
        grossSales: '+ VENTA BRUTA ENVÍOS',
        cancellations: '- ANULACIONES',
        netDelivery: 'ENTREGA NETA',
        withoutCash: 'Sin caja',
        withCash: 'Con caja',
        sellerDelivery: 'ENTREGA VENDEDOR',
        missingOther: 'SOBRANTE/FALTANTE',
        signSupervisor: 'Firma supervisor',
        signSeller: 'Firma vendedor',
        menuList: [
          {icon: 'print', name: 'Imprimir cierre'}
        ]
      },
      taxClient: {
        title: 'CLIENTE TRIBUTARIO',
        description: 'Agrega o modifica la información del cliente para el cual se deben emitir comprobantes electrónicos',
        modifyClient: 'Modificar o agregar cliente'
      }
    },
    navbar: {
      operations: 'Monitoreo vehicular',
      guides: 'VER GUIAS DE SOPORTE',
      ticketOffice: 'Boletería',
      logistic: 'Logística',
      control: {
        drivers: 'Conductores',
        roles: 'Roles'
      },
      maintance: {
        routes: 'Mantenimiento Rutas',
        units: 'Registro vehículos'
      }
    },
    notFound: {
      title: 'ALGO EXTRAÑO SUCEDE',
      desc: 'La página que buscas desapareció',
      come_back: 'Regresar al inicio'
    },
    office: {
      title: 'Plazas de operación',
      deleteT: 'Eliminar plaza',
      deleteD: '¿Realmente deseas eliminar la plaza ',
      searchSeller: 'Buscar personal asociado',
      menuList: [
        {icon: 'edit', name: 'Consultar'},
        {icon: 'delete', name: 'Eliminar'}
      ],
      officeTypeMin: [
        { id: 0, name: 'Carretera' },
        { id: 1, name: 'Terminal/Oficina' },
        { id: 2, name: 'Terminal' }
      ],
      alerts: {
        placeTypeError: 'Error al cargar los tipos de oficina.',
        off_err: 'Error al cargar oficinas.',
        country_err: 'Error al cargar países.',
        noVen_err: 'No hay vendedores creados.',
        creOff_err: 'Error al crear oficina.',
        tran_err: 'Transacción exitosa, pero con varios errores.',
        save_err: 'Error al guardar cambios de vendedores.',
        mod_err: 'Error al modificar oficina.',
        empty_officeT: 'No hay plazas creadas',
        empty_officeD: 'Aún no has creado plazas. Toca el botón \"Nueva\" para comenzar a crear tus primeras plazas.',
        infoOfficeInvalid: 'Datos faltantes o formato invalido en el tab \"INFORMACIÓN DE LA PLAZA\"',
        scheduleInvalid: 'Datos faltantes o formato invalido en el tab \"HORARIOS\"'
      },
      modals: {
        new_off: {
          title: 'Plaza de operación',
          tabs: [
            {
              name: 'INFORMACIÓN DE LA PLAZA',
              action: "placeInformation"
            },
            {
              name: 'HORARIOS',
              action: "schedules"
            },
            {
              name: 'VENDEDORES',
              action: "sellers"
            }
          ],
          showEmail: 'Mostrar email en la app',
          info_gen: 'Información general',
          notSunday: 'Esta plaza no abre domingos',
          notSaturday: 'Esta plaza no abre sábados',
          scheduleWeek: 'Horario de lunes a viernes',
          scheduleSaturday: 'Horario de sábados',
          scheduleSunday: 'Horario de domingos',
          sellerDesc: 'Agregar personal de ventas a esta plaza. Esto servirá para que puedas conocer a qué sitio pertenece una venta',
          writeName: 'Escribe un nombre...'
        },
        sched: {
          title: 'Horarios y días de atención al cliente',
          add_sche: 'agregar horario'
        },
        contact: {
          title: 'COMUNICACIÓN',
          description: 'Permite que los usuarios te contacten de manera ágil y efectiva, así podrás dar un trato más personalizado y oportuno. Los usuarios te podrán contactar desde la app Passer.',
          copyLink: 'Copia y pega el link',
          whatsapp: 'WhatsApp API link',
          messeger: 'Messenger link'
        }
      }
    },
    paymentSystem: {
      county: 'Ciudad',
      state_sel: 'Estado',
      send_code: 'Enviar código',
      details: {
        title: 'Detalles del role',
        desc: 'Se muestra la información completa del role seleccionado. Si deseas realizar una conciliación entre sistemas de conteo de pasajeros y pago electrónico ingresas los datos y genera el reporte.',
        desc_pending: 'Se muestra la información completa del role seleccionado. Podrías hacer conciliaciones una vez se hayan realizado las respectivas acreditaciones.',
        ini_sale: 'Inicia venta online',
        rec_elect: 'Recaudo electrónico',
        total_rec: ' pendiente',
        com_tp: 'Comisión SINPE-TP',
        com_passer: 'Comisón Passer',
        total_acre: ' pendiente',
        cont_pass: 'Conteo de pasajeros',
        cont_desc: 'Ingresa los datos de tu sistema de conteo para conciliar el recaudo electrónico y online con el dinero en efectivo. Si has hecho una conexión con Passer Connect los datos se actualizará automáticamente.',
        count_brand: 'Cantidad de marcas',
        cash: 'Efectivo',
        ref_acredT: 'Referencias de acreditación',
        ref_acredD: 'La referencia SINPE-TP se refiere a la liquidación de fondos que el Banco Central de Costa Rica ha realizado por concepto de recaudo de pago electrónico. La referencia Passer Online se refiere a la acreditación que Passer ha hecho por concepto de recaudo de ventas online.',
        date_cor: 'Fecha corte venta online',
        send_reportT: 'Enviar reporte',
        send_reportD: 'Envía esta información a tu contador o área financiera',
        count_online: 'Cantidad transacciones online',
        count_elect: 'Cantidad transacciones electrónicas'
      },
      alerts: {
        loadCode_err: 'Error al cargar códigos de roles.',
        rec_err: 'Error al cargar recaudos.',
        infoBan_err: 'Error al cargar información del banco.',
        typeId_err: 'Error al cargar catálogo de tipos de identificación.',
        repErrT: 'Reporte no disponible',
        repErrD: 'No hay reporte para la fecha y role seleccionados.',
        repErrOneD: 'Ha ocurrido un error al obtener los reportes con los roles: '
      },
      concil: {
        title: 'Conciliación',
        ref: 'Referencia',
        status: {
          1: 'Asignado',
          2: 'Temporal',
          3: 'En curso',
          4: 'Terminado',
          5: 'Deshabilitado',
          6: 'Eliminado'
        }
      },
      moves: {
        view_det: 'Ver detalles',
        cant: 'Cantidad',
        collect: 'Recaudo',
        pen: 'Finalizada Pendientes',
        acred: 'Acreditadas'
      }
    },
    roles: {
      new_role: 'Nuevo role',
      list_tab: 'Pizarra de roles',
      role_description: 'Descripción del rol',
      idCopied: 'Id de role copiado con éxito!',
      standingAvailable: 'Disponibilidad de pie:',
      searchDriver: 'Buscar conductor',
      alerts: {
        unit_err: 'Error al cargar unidades.',
        jour_err: 'Error al cargar trayectos.',
        role_err: 'Error al cargar roles.',
        err_data: 'A ocurrido un error al cargar datos.',
        err_routes: 'Error al cargar rutas.',
        drAb_err: 'Error al cargar conductores habilitados.',
        unAb_err: 'Error al cargar unidades habilitadas.',
        unOp_err: 'Error al cargar unidades operativas.',
        driverChangeTitle: 'Cambio de conductor',
        driverChangeDescription: 'Ya tienes uno o varios roles preasignados con el conductor <b>{{driver}}</b>, si seleccionas otro diferente se borrarán los datos que tenías ya preasignados.',
        hourMissingTitle: 'Hora faltante',
        hourMissingDescription: 'Debes seleccionar al menos un horario para continuar.',
        driverMissingTitle: 'Conductor faltante',
        driverMissingDescription: 'Debes seleccionar un conductor para continuar.',
        unitMissingTitle: 'Vehículo faltante',
        unitMissingDescription: 'Debes seleccionar un vehículo para continuar.',
        addPreassigned: 'Datos preasignado con éxito',
        deletePreassignedTitle: 'Eliminar dato',
        deletePreassignedDescription: '¿Estás seguro que deseas eliminar este dato de la lista de preasignaciones?',
        deleteRowCollection: 'Fila de recaudo eliminado con éxito.',
        preassignTitle: 'Pre-asignar roles',
        preassignDescription: 'Aún no has pre-asignado roles. Para poder guardar y asignar primero presiona Pre-asignar selección”.',
        noSelectedData: 'No has seleccionado ninguna fila de boletos o recaudo. Si realizas la modificación no se registrarán marcas de trasbordo al conductor que recibe.',
        deleteTransferTitle: 'ELIMINAR FILA',
        deleteTransferDescription: 'Estas por eliminar una fila. Si lo haces al nuevo conductor no se le registrará este trasbordo ¿Deseas continuar?'
      },
      new_role_data: {
        title: 'CREAR Y ASIGNAR NUEVOS ROLES',
        description: 'Ingresa todos los parámetros que componen este role: Vía, horarios, conductores y vehículos.',
        infoJourney: 'Estas por crear un role para el siguiente servicio: <b>TRAYECTO PADRE:</b> {{journey}} / <b>VÍA:</b> {{way}}',
        onlineSales: 'Esta ruta tiene activa la venta online desde la app Passer',
        planner: 'PLANIFICADOR',
        search_label: 'Buscar origen, vía o etiqueta',
        schedules: 'Fecha y horarios',
        sched_desc: "Agrega la fecha y horas de servicio para este role.",
        review: 'Revisa que todo esté bien antes de crear y guardar el role.',
        role_rec: 'Hacer este role recurrente',
        title_r: 'Conductor y vehículo',
        desc_r: 'Selecciona el vehículo y el conductor que operarán este servicio. Se mostrarán únicamente los que estén disponibles o no choquen con otros roles.',
        disp_driver: 'Conductores disponibles',
        view_prof: 'Ver perfil',
        disp_veh: 'Vehículos disponibles',
        create_role: 'Crear nuevo role',
        preassigns: 'PREASIGNACIONES',
        slectedDriver: 'Conductor seleccionado:',
        menu: [
          { icon: 'delete', name: 'Eliminar' }
        ]
      },
      role_list: {
        more_fil: 'Más filtros',
        send_reg: 'Enviar registro',
        send_regD: 'Envía esta información a otros interesados',
        rec_roles: 'Roles recurrentes',
        all_roles: 'Todos los roles',
        apply_fil: 'Aplicar filtros',
        empty_roleT: 'No hay roles creados',
        empty_roleD: 'Aún no has creado roles. Ingresa a la pestaña \"Nuevo role\" para comenzar a crear tus primeros servicios.',
        pending: 'Pendiente',
        startTripAlert: 'Estás por reiniciar el viaje del role {{role}} del {{date}}. ¿Deséas continuar?',
        startTripSuccess: 'El viaje se ha reiniciado con éxito',
        menuList: [
          {icon: 'bus', name: 'Modificar vehículo'},
          {icon: 'person-remove', name: 'Modificar conductor'},
          {icon: 'delete', name: 'Eliminar role'},
          {icon: 'swap-horiz', name: 'Trasbordo'},
          {icon: 'payments', name: 'Ver tiquetes'},
          {icon: 'scatter-plot', name: 'Consultar aforo'},
          {icon: 'reboot', name: 'Reiniciar viaje'}
        ]
      },
      modify_driver_unit: {
        titleUnit: 'Modificar vehículo',
        titleDriver: 'Modificar conductor',
        descriptionVehicle: 'Utiliza el campo \"Modificar\" para cambiar el vehículo asignado al role seleccionado. Se notificará a los usuarios de la app.',
        descriptionDriver: 'Selecciona un nuevo conductor para continuar con el role de servicio actual.',
        desc_link: 'Repasa aquí las reglas y funcionamiento.',
        warningMessage: '<b>Considera que:</b> Se mantendrá el mismo role de servicio, y se cambiará únicamente el conductor',
        actual_driver: 'Conductor actual',
        actual_veh: 'Vehículo actual',
        search_driver: 'Buscar conductor (nombre o apellido)',
        search_unit: 'Buscar vehículo (placa o número)',
        remove: 'Retirar y cambiar',
        reasignTitle: 'Reasignación de aforo',
        reasignDescription: 'Utiliza los campos de cantidad y tarifa según corresponda (boletos o recaudo) para autorizar el trasbordo de pasajeros del vehículo del conductor actual al nuevo conductor.',
        advice: 'Importante:',
        advice2: 'El trasbordo se refiere a un registro de movilización para el conteo de marcas las cuales el administrador descontará al conductor o vehículo que recibe según corresponda y bajo su criterio. No implica movimientos financieros o de cobros.',
        searchRate: 'Buscar tarifa',
        tabs: [
          {
            name: 'BOLETOS',
            action: "tickets"
          },
          {
            name: 'RECAUDO',
            action: "collection"
          }
        ]
      },
      addCollection: {
        title: 'NUEVA FILA DE RECAUDO',
        description: 'Agregarás una nueva fila al recaudo del conductor saliente'
      },
      transfer: {
        title: 'TRASBORDO',
        desc: 'Utiliza esta función si necesitas mover pasajeros de un vehículo a otro. El role de ambos vehículos se mantendrá y la cantidad que muevas no será registrada como recaudo para el segundo vehículo.',
        veh_send: 'Vehículo que envía',
        veh_rec: 'Buscar vehículo que recibe',
        alertRemoveRoleTitle: 'RETIRAR VEHÍCULO ACTUAL',
        alertRemoveRoleDescription: 'Marca la siguiente casilla si deseas sacar de role el actual vehículo y mantener el nuevo vehículo que estás asignando.',
        ret_role: 'Retirar el actual vehículo de su role.',
        cant_tras: 'Cantidad trasbordos',
        transfer: 'Trasbordar',
        unit_err: 'Unidad receptora requerida',
        rolesReceptor: 'ROLES DEL VEHÍCULO QUE RECIBE',
        errorSelectedRole: 'Debes seleccionar un role de la tabla para continuar el trrasbordo.',
        transferPassenger: 'Pasajeros trasbordados',
        reasignTitle: 'Reasignación de aforo',
        reasignDescription: 'Utiliza los campos de cantidad y tarifa según corresponda (boletos o recaudo) para autorizar el trasbordo de pasajeros del vehículo actual al vehículo que recibe.'
      },
      delete: {
        title: 'Eliminar role',
        description: 'Estás por eliminar el role <b>{{trayecto}}</b> para la fecha <b>{{fecha}}</b> con horario de salida <b>{{hora}}</b>',
        title_s: 'Role con tiquetes vendidos',
        desc_s: 'Este role posee ',
        desc_s3: ' tiquetes vendidos. Se notificará a los usuarios de tiquetes electrónicos, pero tú deberás notificar por los medios adecuados a los usuarios de tiquetes físícos.',
        disableRole: 'Inhabilitar esta misma hora para lo que resta del día'
      },
      profile_driver: {
        send_wh: 'Enviar un WhatsApp',
        view_complete: 'Ver ficha completa',
        comments: 'Qué comenta los usuarios',
        service: 'Servicio',
        driving: 'Conducción',
        vocab: 'Vocabulario',
        no_comments: 'Sin comentarios'
      },
      salesReport: {
        title: 'Reporte de ventas',
        phisicalTickets: 'TIQUETES FÍSICOS:',
        digitalTickets: 'TIQUETES DIGITALES:'
      },
      datetime: {
        title: 'FECHA Y HORARIOS DEL ROLE',
        description: 'Selecciona los horarios de ruta que deseas asignar a este role de servicio. Si no encuentras en horario desea entonces créalo en el módulo \"rutas\"'
      }
    },
    routes: {
      title: 'Mantenimiento de rutas',
      no_filters: 'No has aplicado ningún filtro aún',
      alerts: {
        str_err: 'Error al cargar tramos.',
        tar_err: 'Error al cargar tarifas.',
        err_maps: 'Hubo un problema con la obtención de datos de google maps, ¿Deseas intentar nuevamente?. Si el error persiste, por favor comunicate con el servicio Enterprises de Passer.',
        err_compServN: 'El trayecto seleccionado no se ha creado o esta desactivado. En caso de estar desactivado dirigete al módulo de rutas para activar dicho trayecto.',
        err_compServ: 'Error al cargar trayecto (Company Services).',
        noPlaces: 'No hay ',
        noPlaces2: ' creadas aún.',
        timeSelected: 'Debes agregar un horario para continuar.',
        daySelected: 'Debes seleccionar al menos un día para continuar.',
        dateSelected: 'Debes seleccionar al menos una fecha para continuar.',
        noCharacterValid: 'El caracter \"-\" no es válido en el nombre del tramo.',
        removeSchedule: 'Horario removido',
        dataLoadedTitle: 'Datos incorrectos',
        sameDataLoadedTitle: 'Datos no concuerdan',
        verifyDataTitle: 'VERIFICA LOS DATOS',
        verifyDataDescription: 'Algunos de los datos de la matriz no cumplen los criterios. Por favor verifica que estás utilizando el formato correcto, y que no hayan caracteres inconsistentes, como "puntos" y "comas"',
        dataLoadedLengthDescription: 'Por favor verifica que todos los datos sean correctos. Verifica que la cantidad de datos cargados sean los indicados, recuerda que los datos a cargar deben ser a partir del segundo punto de la matriz trarifaria. Si el error persiste, por favor intenta descargar nuevamente el documento guía y vuelve a cargar la información, también puedes comunicarte con el servicio Enterprises de Passer.',
        generateTitle: 'Archivo sin cargar',
        generateDescription: 'Primero debes cargar los datos antes de poder generar los cortes del trayecto',
        noDataLoaded: 'Antes de poder guardar la información del trayecto debes presionar \"Generar\" para cargar la información completa de los cortes cargados.',
        moreDataMatrix: 'Estás tratando de ingresar más datos de los requeridos. Primero agrega la cantidad de filas necesarias para copiar la información a la tabla.',
        moreDataOlderMatrix: 'Estás tratando de ingresar más datos de los requeridos. Asegurate de tener la misma cantidad de filas necesarias para copiar la información a la tabla.',
        emptyOlderMatrix: 'Parece que tienes celdas en blanco, con decimales o que no son números. Por favor verifica antes de continuar.',
        noDataOlderMatrixTitle: 'Matriz de adulto mayor',
        noDataOlderMatrixDesc: 'Debes ingresar las tarifas de la matriz de adulto mayor.',
        preSave: 'Las nuevas tarifas se han preguardado con éxito. Cierra esta ventana y presiona \"Guardar cambios\"',
        deleteRouteTitle: 'Eliminar ruta',
        deleteRouteDescription: 'Estás por eliminar la ruta <b>{{route}}</b> con la etiqueta <b>{{tag}}</b>. ¿Deséas eliminar la ruta?'
      },
      route_list: {
        title: 'Rutas creadas',
        active: 'Encendida',
        deactive: 'Apagada',
        servicetype: 'Tipo servicio',
        service_type: 'Modalidad',
        empty_routeT: 'No hay rutas creadas',
        empty_routeD: 'Aún no has creado rutas. Ingresa a la pestaña \"Nueva ruta\" para comenzar a crear tus primeros servicios.',
        base_config: 'Configuración básica',
        matrix: 'Matriz tarifaria',
        matrix_journey: 'Matriz de trayectos y tarifas',
        map: 'Mapa',
        menuList: [
          { icon: 'edit', name: 'Configuración básica'},
          { icon: 'edit', name: 'Editar matriz tarifaria'},
          { icon: 'edit', name: 'Editar tarifas y cortes'},
          { icon: 'edit', name: 'Editar cortes de rutas'},
          { icon: 'delete', name: 'Eliminar rutas'},
          { icon: 'power-on', name: 'Apagar/Encender'},
          { icon: 'clock', name: 'Horarios'},
          { icon: 'credit-card', name: 'Tarifas'}
        ]
      },
      new_route: {
        title: 'Nueva ruta',
        basic_config: {
          title: 'Configuración básica de la ruta',
          create_label: 'Etiqueta de ruta',
          empty_data: 'Debes digitar la etiqueta que identificará la ruta a crear.',
          checkDesc: 'Al crear horarios y fechas confirmas y das fe que son exactas y veraces, que las has configurado de manera precisa y que los servicios serán brindados según las fechas y horas aquí establecidas. Al mismo tiempo, liberas a Passer de cualquier responsabilidad por incumplimiento.',
          journeyTypeDirect: 'Realiza únicamente paradas reducidas en puntos de control, terminales, sitios de descanso o afines. No permite el abordaje en carretera de manera general.',
          journeyTypeIndirect: 'Realiza paradas constantes durante el recorrido. Los usuarios pueden abordar en cualquier parada autorizada.',
          specials: 'Especiales',
          extras: 'Extras',
          serv_type: {
            title: '¿Deséas vender tiquetes online para esta ruta?',
            yes_tool: 'Si activa esta opción los servicios de esta ruta podrán ser consultados y reservados de forma online por los usuarios desde la app Passer.'
          },
          sched: {
            title: 'GESTIÓN DE HORARIOS Y DÍAS DE SERVICIO'
          },
          tabSchedule: [
            { action: 'regular', name: 'HORARIOS REGULARES' },
            { action: 'special', name: 'HORARIOS ESPECIALES Y EXTRAS' }
          ],
          menu: [
            { icon: 'pending-actions', name: 'Consultar horarios' }
          ]
        },
        matrix: {
          title: 'Matriz de operación',
          desc: 'Define el esquema de tramos, trayectos y vías que operan dentro de esta ruta. Agrega la tarifa que corresponda a cada uno.',
          way_codeT: 'Nombre de la vía',
          way_codeD: 'Ingresa el nombre de la vía o fraccionamiento.',
          way_name: 'Nombre de la vía',
          code_route: 'Código de ruta',
          scheme: 'Esquema de tramos y tarifas',
          rowDescription: 'Por defecto tienes 2 filas (tramo padre). Puedes agregar más filas según tu tipo de recorrido, o bien, eliminar aquellas que no necesites.',
          addRows: 'Agregar filas',
          deleteRow: 'Eliminar fila',
          tariffTitle: 'Ajuste tarifario',
          tariffDescription: 'Utiliza esta opción para hacer un cambio masivo en la tarifa de cada tramo.',
          reg_stretchesT: 'TRAYECTO DE VENTA REGULADO (opcional)',
          reg_stretchesD: 'Define un punto de inicio y un punto final cuyas tramos medios deban cobrarse con el mismo precio de la tarifa del trayecto regulado. De esta manera estarás indicando al sistema dónde comienza y termina un tramo cuyos puntos de abordaje comparten la misma tarifa según autorización del ente regulador.',
          add_stretch: 'Ingresar tramos',
          count_stretches: 'Cantidad de tramos',
          rate_pay: 'Tarifa que se cobrará',
          empty_inputs: 'Debes digitar el nombre de la vía y el código de ruta.',
          adjustmentList: [
            { name: 'Aumentar', value: 1 },
            { name: 'Disminuir', value: 2 }
          ],
          menuList: [
            { icon: 'delete', name: 'Eliminar' }
          ]
        },
        new_route: {
          title: 'Mapas operativos',
          desc: 'Los mapas operativos te permiten gestionar ubicaciones, tiempos e información geográfica importante para tú propio control y enriquecer la experiencia de los usuarios finales. Utiliza las pestañas de \"Trayecto padre\", \"Tramos intermedios\" y \"Paradas autorizadas\" para añadir la información.',
          journey_fath: 'Trayecto padre',
          stretches: 'Cortes de trayecto',
          stops: 'Paradas autorizadas',
          journey_fatT: 'Trayecto padre de la ruta',
          journey_fatD: 'Haz click sobre el mapa para agregar marcadores y señalar la posición de inicio y fin del trayecto padre, es decir, el primer lugar de partida y el último de llegada.',
          search_google: 'Buscar en Google Maps',
          add_marker: 'Debes agregar los marcadores del trayecto padre.',
          checkDisclaimer: 'He leído, acepto y entiendo los descargos de responsabilidad de la configuración de matrices tarifarias y mapas.',
          tabList: [
            {action: 'journeyFath', name: 'Trayecto padre'},
            {action: 'stretches', name: 'Cortes de trayecto'}
          ]
        },
        stops: {
          title: 'Paradas y terminales',
          desc: 'Haz click sobre el mapa para indicar la ubicación de paradas y terminales. Esto lo verán reflejados los usuarios en la aplicación Passer y ayudará a mejorar su experiencia de viaje. También podrás tener un control más preciso sobre el tiempo y ubicación de tus vehículos. Si tienes dudas sobre cómo funciona puedes ',
          name_stop: 'Nombre de la parada',
          time: 'Tiempo recorrido',
          road_stop: 'Parada carretera',
          terminal: 'Terminal / Oficina',
          subTitle: 'Descripción y frecuencia',
          subDescription: 'Ingresa un nombre por el cual los usuarios pueda reconocer esta parada. Además, puedes indicar la frecuencia con la que el servicio estará pasando por este lugar.',
          type: 'Tipo (opcional)',
          nameStop: 'Nombre referencia',
          frequency: 'Frecuencia'
        },
        stretches: {
          title: 'GEOLOCALIZAR CORTES',
          desc: 'Carga la matriz de coordenadas y tiempos de recorrido de cada tramo o corte para que el sistema añada la marca al mapa. El tiempo que indiques permitirá calcular la hora de salida de los servicios desde cada corte de la ruta. ',
          fileFormat: 'Descargar formato Excel para matriz.',
          adminRates: 'Administrar tarifas',
          alertLoadInfo: 'La información se cargó correctamente. Presiona \"Generar\" para agregar al mapa los marcadores geográficos de cada corte.',
          subTitle: 'Tiempo de recorrido',
          subDescription: 'Ingresa el tiempo de recorrido del servicio desde el origen padre, en este caso ',
          subDescription2: ' hasta casa uno de los tramos del trayecto hasta llegar al destino padre',
          depName: 'Origen padre',
          nextStretch: 'Siguiente tramo',
          time_tr: 'Tiempo de recorrido',
          admin_stretchesT: 'Administrar tramos',
          admin_stretchesD: 'Utiliza los controles y funciones para apagar o encender tramos en situaciones especiales. También puedes definir el aforo de venta online si activaste esta opción.',
          searchStretch: 'Buscar tramo',
          stateStretch: 'Estado corte',
          distanceStretch: 'Distancia corte anterior',
          distanceOrigin: 'Distancia padre',
          arrivalName: 'Corte de destino',
          coordinates: 'Coordenadas',
          modifyTimeTitle: 'MODIFICAR TIEMPO',
          modifyTimeDescription: 'Agrega un nuevo número de horas y minutos para cambiar el tiempo estimado del recorrido de este corte ({{tramo}})',
          elderAlert: 'Puedes utilizar las opciones de administración tarifaria para modificar los precios o la forma en que se aplican a determinadas circunstancias. En el caso de tarifas de adulto mayor es necesario que presiones el botón "Adultos" para generar la matriz diferenciada, caso contrario no podrás guardar la ruta.',
          menuList: [
            {icon: 'edit', name: 'Modificar tiempo'},
            {icon: 'edit', name: 'Modificar marcador'},
            {icon: 'power-on', name: 'Activar/Inactivar'}
          ]
        }
      },
      modals: {
        calendar: {
          title: 'BLOQUEAR FECHAS',
          description: 'Utiliza el botón "Agregar fecha" para inhabilitar días específicos.',
          alert: 'Esta función es especialmente útil cuando existen casos de fuerza mayor que obligan a suspender los horarios de servicios temporalmente.',
          addDate: 'Agregar fecha',
          disabledDates: 'Fechas bloqueadas'
        },
        aforo: {
          title: 'Aforo',
          desc: 'El aforo te permite definir la capacidad de venta online por tramo, así, puedes controlar la rentabilidad de cada uno. Define el porcentaje de venta que deseas se asigne a cada uno, de esta forma los usuarios solo podrá comprar esa cantidad de asientos para el tramo en cuestión. Por regla general el porcentaje no puede ser inferior al 50%, además, el destino padre siempre será 100%.',
          errData: 'Asegurate haber ingresado todos los datos de la tabla de aforo. Además, cada porcentaje debe ser como mínimo 50 y no más de 100.'
        },
        ratesSchedules: {
          title: 'TARIFAS PARA EL TRAYECTO:',
          titleSchedule: 'HORARIOS DE SERVICIO PARA EL TRAYECTO:',
          warning: 'Si deseas realizar cambios ingresa a <b>\"mantenimiento de rutas\"</b> y selecciona <b>\"Configuración básica\"</b>'
        },
        datetimeRoute: {
          title: 'DÍAS Y HORARIOS DE SERVICIO',
          description: 'Ingresa una hora (am/pm) y luego selecciona los días de la semana, o fechas, a los que quieres asignar el horario.',
          applyAll: 'Aplicar a todos los días de la semana',
          regularSchedule: 'Es un horario regular',
          regularDescription: 'Son los horarios raíz y corresponden a la hora de salida del primer tramo del trayecto. Los horarios de salida de los tramos siguientes se calculan automáticamente según el tiempo que hayas asignado en la sección “Cortes de trayecto”.',
          specialSchedule: 'Es un horario especial',
          specialDescription: 'Utiliza esta opción para crear horarios particulares para una fecha específica y que luego desaparecerán. Cuando creas un horario especial no se mostrarán los horarios regulares de esa misma fecha.',
          extraSchedule: 'Es un horario extra',
          extraDescription: 'Son horarios adicionales y se mostrarán junto con los horarios regulares o los especiales.'
        },
        disclaimer: {
          title: 'DESCARGOS DE RESPONSABILIDAD',
          description: 'Al crear las matrices de tarifas y marcado geográfico en mapas, comprendes y aceptas las siguientes disposiciones y descargos, liberando a Passer de cualquier responsabilidad derivada de incumplimientos de tu parte o por la carga de información errónea.',
          disclaimerList: [
            '1. El tiempo de recorrido que tú definas permite determinar la hora de cierre aproximada de cada role.',
            '2. La distancia entre cortes tarifarios (tramos) es la longitud entre un corte y otro. Esta distancia permite calcular la tarifa aplicable para adultos mayores cuando activas la opción de cálculo automático.',
            '3. Confirmas y aceptas que los datos contenidos en la tabla de distancias y tarifas para adulto mayor está correcta, y a tu entera satisfacción.',
            '4. Al establecer tiempos de recorrido y distancias entre cortes tarifarios confirmas y das garantía que los has revisado, hecho con precisión y de forma verás.',
            '5. Liberas a Passer de cualquier responsabilidad por el incumplimiento de horarios.',
            '6. Liberas a Passer de cualquier responsabilidad por cálculos tarifarios erróneos derivados de información equivoca que tú has cargado o añadido.'
          ],
          consult: 'Si tienes consultas no dudes en utilizar los canales de atención para poderte ayudar y asesorar. Siempre será un gusto servirte.'
        },
        olderMatrix: {
          title: 'TARIFAS PARA ADULTO MAYOR',
          description: 'Agrega la tarifa de adulto mayor que se aplicaría según corresponda entre cada tramo que has agregado a la matriz.'
        },
        specialSchedules: {
          title: 'HORARIOS',
          description: 'Se muestran los horarios para el <b>{{date}}</b>'
        }
      },
      savedRoute: {
        title: 'Ruta previa',
        description: 'Parece que tienes una ruta en proceso, ¿Deseas continuar?'
      },
      preventRoute: {
        title: 'Ruta sin guardar',
        description: '¿Deseas guardar la ruta antes de irte?'
      }
    },
    profile: {
      verify_password: 'Verificar tu contraseña actual para modificar y guardar tus credenciales de acceso.',
      verify_phoneT: 'Verificar teléfono',
      verify_phoneD: 'Si cambias tu número de teléfono deberás verificarlo. Presiona \"Enviar código\" para recibir un código vía SMS en tu dispositivo móvil.',
      administrative_password: 'Contraseña administrativa',
      active_payment: 'Activa la verificación de dos pasos para agregar una capa extra de seguridad a tu cuenta. Recibirás un código en tu teléfono móvil o email cada vez que desees acceder a tu cuenta.',
      location: 'Ubicacion',
      enter_code: 'Ingresar codigo',
      verify_code: 'Código verificado con éxito',
      alerts: {
        photo: 'Las dimensiones de la imagen deben ser mayores a ',
        pixels: ' pixeles.',
        error_verify: 'Error verificando código',
        all_code: 'Debes ingresar los 4 dígitos del código antes de verificar.'
      },
      account: {
        title: 'Cuenta Passer',
        description: 'Tu cuenta Passer es una llave universal, exclusiva y personal, para que puedas acceder de manera fácil, segura y sin complicaciones a todas las plataformas y servicios que Passer te ofrece con un mismo Email, Teléfono, Contraseña o PIN.<br/><br/> Cada plataforma tiene particularidades únicas, por lo cual deberás hacer algunas configuraciones y carga de información adicional para cada caso.<br/><br/> Cuando modificas los datos de cuenta se modificarán de manera general, por lo que asegúrate de recordarlos y realizar las verificaciones que correspondan.',
        savingAccountTitle: 'Cuenta de ahorros',
        savingAccountDescription: 'Solicita de manera gratuita y sin tramitologías complejas tu cuenta de ahorros y tarjeta débito del BANCO NACIONAL para que puedas recibir el pago y realizar compras con grandes beneficios.',
        donwloadApp: 'Descarga la app gratis',
        openAccount: 'ABRIR CUENTA',
        credentialsTitle: 'CREDENCIALES DE ACCESO',
        credentialsDescription: 'Si modificas tus credenciales presiona \"Enviar código\" para recibir un código vía SMS en tu dispositivo móvil o cuenta de email.',
        currentPassword: 'Contraseña actual',
        newPassword: 'Nueva contraseña',
        confirmPassword: 'Confirmar nueva contraseña',
        twoSteps: 'VERIFICACIÓN DE 2 PASOS',
        twoStepsDescription: 'Activa o desactiva la verificación de 2 pasos de tu cuenta Passer. Te recomendamos tener esta opción activa para mayor seguridad.',
        pinAccountTitle: 'PIN DE CUENTA',
        pinAccountDescription: 'El PIN de cuenta lo usarás en todas tus transacciones y compras de los servicios Passer.'
      },
      access: {
        title: 'Mis accesos',
        description: 'Los accesos Enterprises son las empresas o comercios a la cual un propietario administrador de grupo te ha dado acceso por medio de tu cuenta Passer.',
        companySubscription: 'Empresa/Suscripción',
        accessType: 'Tipo acceso',
        owner: 'Propietario del grupo',
        yourAccess: 'Tu acceso',
        yourConnection: 'Tu Conexión',
        menuList: [
          { icon: 'switch-account', name: 'Trabajar aqui' }
        ]
      }
    },
    suspendedAccess: {
      title: 'ACCESO SUSPENDIDO',
      description: 'Un dueño o administrador de grupos ha revocado tu acceso a esta suscripción o cuenta. Por favor intenta ingresar con otro usuario o comunícate con la administración de tu empresa.',
      return: 'Regresar al login'
    },
    tickets: {
      title: 'Boletería',
      asign_seats: 'Asignar asientos',
      timeLeft: 'Tiempo restante:',
      timeoutTitle: 'SIN RESPUESTA',
      timeoutD: 'Lo sentimos, no pudimos comunicarnos con el servidor. Por seguridad bloquearemos el asiento por 5 minutos, por favor intenta con otro.',
      timeoutStandingD: 'Lo sentimos, no pudimos comunicarnos con el servidor, por favor intenta nuevamente.',
      withoutNumbering: 'Sin numeración',
      standing: 'De pie',
      sitting: 'Sentados',
      boardings: 'Abordajes',
      alerts: {
        vend_err: 'Error al cargar vendedores.',
        tick_err: 'Error al cargar Tiquetes.',
        sch_err: 'Error al cargar horarios.',
        pay_more: 'Debes pagar con más o igual al precio mostrado en el total de venta.',
        schServ_err: 'Error al cargar horarios de servicio.',
        seat_err: 'Error al cargar asientos.',
        client_err: 'Error al cargar clientes.',
        code_err: 'Error al cargar códigos de tiquetes.',
        oneT_err: 'Error al cargar tiquete.',
        no_ticketsT: 'No hay tiquetes registrados',
        no_ticketsD: 'Aún no hay tiquetes vendidos. Ingresa a la pestaña \"Ventanilla\" para comenzar a vender tiquetes.',
        noOfficeTitle: 'Sin plazas disponibles',
        noOfficeDescription: 'En este momento ninguna de tus plazas de venta se encuentra en horario de servicio activo, o aún no se te han asignado plazas. Por favor revisa o consulta con tu administrador de suscripción.',
        errorCondition: 'Error al cargar las condiciones de venta.',
        pinInvalid: 'PIN inválido, por favor verifica y trata de nuevo',
        digitalTitle: 'Tiquete digital',
        digitalDescription: 'Este tiquete fue enviado a la app Passer, por lo cual no es posible imprimirlo',
        successBought: 'Compra exitosa',
        successBoughtDescription: 'Se ha generado un tiquete para ',
        alertMinValue: 'El valor mínimo del {{value}} es',
        sameDiscount: 'Este boleto de adulto mayor tiene descuento del <b>0%</b>, por lo tanto se cobrará y registrará en los cierres como una venta <b>"regular"</b>',
        pendingTicketTitle: 'Transacción pendiente',
        pendingTicketDescription: 'Al parecer la venta que intentaste hacer se pudo haber realizado. Por favor verifica antes de reintentar.',
        pendingErrorTitle: 'Fallo en transacción',
        pendingErrorDescription: 'La transacción de venta anterior no se realizó. Por favor vuelve a ejecutar la venta.',
        missingTicketsTitle: 'Boletos faltantes',
        missingTicketsDescription: 'Al parecer de los {{count1}} seleccionados, solo se pudieron procesar {{count2}} para la venta. Por favor vuelve a seleccionar y vender los tiquetes faltantes.',
        busInfo1: 'Al vehículo <b>{{plate}}</b> le quedan aproximadamente <b>{{amount}}</b> espacio(s) disponible(s).',
        busInfo2: 'Utiliza la opción <b>\"Consultar aforo\"</b> para obtener más detalles o consultar otros servicios.',
        lastUpdate: 'Última actualización:'
      },
      tabSolds: [
        { action: 'tickets', name: 'BOLETOS' },
        { action: 'closings', name: 'CIERRES' }
      ],
      tickets_list:{
        title: 'Vendidos',
        generate_change: 'Generar cambio',
        total_income: 'Ventas totales',
        total_tickets: 'Cantidad de tiquetes',
        unidentified: 'No identificado',
        cancelTicket: 'Anular tiquete',
        officeEmptyTitle: 'Sin cajas abiertas',
        officeEmptyDescription: 'Para anular un tiquete debes tener al menos una caja abierta. Ingresa a la pestaña <b>\"Ventanilla\"</b> para abrir una.',
        noCancelTicket: 'No puedes anular un tiquete cancelado/reembolsado.',
        noPrint: 'No puedes reimprimir un tiquete cancelado/reembolsado.',
        warningCancel: 'Estás por anular el tiquete {{num}}. Esta acción no es reversable, así que antes de continuar asegurate de revisar bien los datos y que sea el tiquete correcto. Presiona continuar para anular el tiquete.',
        menuList: [
          {icon: 'print', name: 'Reimprimir'},
          {icon: 'delete', name: 'Anular tiquete'}
        ]
      },
      ticketsClosing: {
        menuSeller: [
          { icon: 'print', name: 'Imprimir cierre' },
          { icon: 'wallet', name: 'Desglose' }
        ],
        menuDriver: [
          { icon: 'cashier', name: 'Recibir' },
          { icon: 'wallet', name: 'Desglose' }
        ],
        sellers: {
          title: 'MIS VENTAS',
          description: 'Detalle de la facturación según ubicación de ventas',
          totalTransactions: 'Total transacciones',
          totalTickets: 'Total boletos',
          grossSales: 'Venta bruta',
          discounts: '- Descuentos autorizados',
          discountsMin: '- Desc. autorizados',
          discountChanges: '- Descuentos cambios',
          discountChangesMin: '- Desc. cambios',
          discountElder: '- Descuentos adultos',
          discountElderMin: '- Desc. adultos',
          cancellation: '- Anulaciones',
          total: 'Entrega neta',
          print: 'Imprimir cierre',
          other: '- OTROS',
          signSeller: 'Firma vendedor',
          sellerDelivery: 'ENTREGA VENDEDOR',
          missingOther: 'SOBRANTE/FALTANTE',
          withCash: 'Con caja',
          withoutCash: 'Sin caja'
        },
        admins: {
          title: 'VENTAS DE OTRAS PLAZAS',
          description: 'Información de las ventas realizadas por otros vendedores. Se muestran cierres pendientes.'
        },
        drivers: {
          title: 'CIERRE CONDUCTORES',
          description: 'Se muestran los roles pendientes de cierre',
          warningTitle: 'Cierre conductor',
          warningDescription: 'Estás por realizar el cierre de un conductor, esta acción no se podrá cambiar, así que revisa bien los datos ingresados antes de continuar. Si crees que todo está en orden, presiona <b>\"Continuar\"</b> para realizar el cierre e impresión.',
          warningSaleTitle: 'Cierre ventanilla',
          warningSaleDescription: 'Estás por realizar el cierre de ventanilla, esta acción no se podrá cambiar, así que revisa bien los datos ingresados antes de continuar. Si crees que todo está en orden, presiona <b>\"Continuar\"</b> para realizar el cierre e impresión.',
          closeTitle: 'VENTANILLA (UBICACIÓN)',
          driverDelivery: 'ENTREGA CONDUCTOR',
          missingOther: 'SOBRANTE/FALTANTE'
        }
      },
      tickets_sales: {
        title: 'Ventanilla',
        carrier: 'AL PORTADOR',
        tab_left: {
          title: 'DESCRIPCIÓN DEL SERVICIO',
          salesPlace: 'Lugar de venta',
          selectPlace: 'Seleccionar plaza',
          selected: 'Seleccionado',
          load_seats: 'Seleccionar espacios',
          send_tick: 'Enviar tiquete a la app',
          send_tickD: 'Utiliza esta opción si deseas enviar los tiquetes directamente a la app del usuario.',
          elder: 'Adulto mayor',
          elderD: 'El descuento para adulto mayor se calcula automáticamente en función de las distancias del trayecto. O ingresa un descuento manual.',
          seatsSelected: 'Cantidad espacios: ',
          standingSelected: 'Espacios de pie: ',
          consultCapacity: 'Consultar aforo'
        },
        tab_right: {
          title: 'INFORMACIÓN DE LA VENTA',
          total_sale: 'Total venta',
          codes: 'Cambios y descuentos',
          id_tick: 'ID de tiquete',
          otherDiscount: 'Otro descuento',
          withoutDiscount: 'Sin descuento',
          payWith: 'Pagar con',
          change: 'Vuelto',
          total: 'Total neto a pagar',
          pros_buy: 'Procesar compra'
        },
        seats_modal: {
          title: 'Selecciona asientos',
          withoutSeat: 'Este servicio permite la venta de pie',
          totalStanding: 'Espacios disponibles de pie',
          busy: 'Ocup',
          free: 'Libre'
        }
      },
      views: {
        seats_modal: {
          title: 'Para la fecha y hora seleccionada existen ',
          title2: ' disponibles.',
          floor: 'Seleccione un piso',
          first_floor: 'Primer piso',
          second_floor: 'Segundo piso',
          available: 'Disponible',
          taken: 'Ocupado',
          selected: 'Seleccionado',
          corridor: 'Pasillo',
          back: 'Atrás',
          seats: 'Asientos',
          pref: 'Preferencial'
        },
        sales_modal: {
          success_sell: 'Tiquete vendido con éxito',
          alert: 'Alertar'
        },
        details_modal: {
          code_details: 'Consulta de código',
          boarding_data: 'Fecha y hora para abordaje',
          boarding_place: 'Lugar de abordaje'
        }
      },
      modals: {
        title: 'Generar código de cambio',
        desc: 'Vas a generar un cambio para el tiquete con ID ',
        desc2: ' ¿Deseas continuar?',
        desc3: 'Recuerda retener el tiquete original',
        title_generate: 'Cambio pendiente',
        trip_data: 'Datos del viaje',
        amount: 'Precio',
        noChangesTitle: 'APLICAR CAMBIO',
        noChangesDescription: 'Aplicarás un cambio al tiquete {{id}}. El monto se descontará del total neto a pagar.',
        verifyInfo: 'Verifica la siguiente información',
        applicablePercentage: 'Porcentaje aplicable',
        changeAmount: 'Monto del cambio',
        adminTitle: 'AUTORIZACIÓN DE DESCUENTO',
        adminDescription: 'Estas por autorizar un descuento, por favor selecciona tu nombre e ingresa tu PIN de cuenta Passer',
        refundTitle: 'AUTORIZACIÓN DE REEMBOLSO',
        refundDescription: 'Estas por autorizar un reembolso, por favor selecciona tu nombre e ingresa tu PIN de cuenta Passer',
        adminSubscription: 'Administrador de suscripción',
        adminPIN: 'PIN de cuenta Passer',
        openCashTitle: 'Abrir caja',
        openCashDescription: 'Ingresa el monto con el que iniciarás este día de trabajo',
        adminEmpty: 'Debes seleccionar un administrador para poder continuar.',
        cancelTicketTitle: 'ANULAR TIQUETE',
        cancelTicketDescription: 'Estar por anular el tiquete con el id <b>{{num}}</b>. Si deseas continuar completa la siguiente información y presiona \"Anular\".',
        selectPlace: 'Selecciona una plaza',
        alertCancel: 'Si anulas este tiquete deberás hacer un reembolso en efectivo o la cuenta bancaria del usuario.',
        idCopied: 'Id de tiquete copiado con éxito!',
        refundOnlyTicket: 'Reembolsar solo este boleto',
        refundAccumulated: 'Reembolsar saldo acumulado',
        capacityTitle: 'AFORO VEHICULAR',
        capacityDescription: 'Utiliza los filtros de búsqueda para conocer el aforo estimado de un role de servicio en particular',
        searchStretch: 'Buscar tramo',
        capacityTabs: [
          { action: 'tickets', name: 'BOLETERÍA' },
          { action: 'boarding', name: 'CARRETERA' },
        ]
      },
      status: [
        { displayName: 'Activo', value: 1 },
        { displayName: 'Consumido', value: 2 },
        { displayName: 'Cancelado', value: 3 },
        { displayName: 'Reembolsado', value: 4 }
      ],
      salesBreakdown: {
        title: 'Desglose de ventas boletería',
        description: 'Se muestra la información de boletos vendidos por cada tramo de la ruta.',
        importantTitle: 'CONSIDERACIÓN IMPORTANTE',
        importantDescription: 'Recuerda que el recaudo de ventanilla (ventas) está correlacionado a las marcas de abordaje del conductor, por lo cual, éste último no posee el dinero equivalente a las ventas de boletos. Si el usuario del boleto físico no aborda, considera que el dinero de la venta siempre ingresó y tú lo tienes, por lo que se recomienda no rebajarlo o cobrarlo el conductor aún cuando éste no tenga el comprobante físico.',
        ticketsAmount: 'Cantidad boletos',
        stretchPrice: 'Tarifa tramo',
        discounts: 'Total descuentos',
        cashDelivery: 'Entrega neta',
        menu: [
          { icon: 'table-rows', name: 'Descarga Excel' }
        ]
      },
      driverBreakdown: {
        title: 'Desglose de recaudo por conductor',
        description: 'Se muestra la información de boletos físicos y digitales que han sido leídos y validados, así como las cifras de recaudo en efectivo.',
        importantTitle: 'CONSIDERACIÓN IMPORTANTE',
        importantDescription: 'Recuerda que el recaudo de ventanilla (ventas) está correlacionado a las marcas de abordaje del conductor, por lo cual, éste último no posee el dinero equivalente a las ventas de boletos. Si el usuario del boleto no aborda, considera que el dinero de la venta siempre ingresó y tú lo tienes, por lo que se recomienda no rebajarlo o cobrarlo el conductor aún cuando éste no tenga el comprobante físico.',
        associatedTickets: 'Total boletos asociados',
        paperValidated: 'Boletos físicos validados',
        digitalTickets: 'Boletos digitales validados',
        boardings: 'Abordajes regulares',
        elderBoardings: 'Abordajes adulto mayor',
        stretchPrice: 'Tarifa tramo',
        discount: 'Descuento adulto mayor',
        refunds: 'Cantidad reversiones',
        netDelivery: 'Entrega neta',
        menu: [
          { icon: 'table-rows', name: 'Descargar desglose por tramo' },
          { icon: 'table-rows', name: 'Descargar compilado por tarifas' }
        ]
      },
      configuration: {
        title: 'Condiciones',
        subTitle: 'Condiciones de ventas',
        desc: 'Utiliza los parámetros para definir tus condiciones de venta online, operación y otros términos importantes que los usuarios deban conocer.  Lo que nos indiques acá se considerará como parte de tus políticas comerciales.',
        acceptConditions: 'Entiendo y acepto que estas condiciones de venta formarán parte de mis políticas de servicio, las cuales se mostrarán al usuario final antes de cada compra y que Passer las aplicará según lo aquí dispuesto.',
        instructions: {
          title: 'INSTRUCCIONES PARA PASSER',
          desc: 'Passer se encarga de la atención y soporte de todos los usuarios que realicen compras y transacciones a través de la app, pero siempre en apego a tus normas y políticas. Por favor, indícanos algunos puntos o temas importantes que deseas que apliquemos y tengamos en consideración con relación a las políticas de operación.',
          specials: 'Instrucciones especiales'
        },
        operation: {
          title: 'POLÍTICAS DE OPERACIÓN',
          desc: 'Los atributos de operación te permiten definir normas y características en función de lo que sea más viable para tu negocio. Esta información será presentada al usuario final para su conocimiento.',
          boarding_way: '¿PERMITIR ABORDAJES EN CARRETERA?',
          printCopy: '¿IMPRIMIR COPIA DE LOS BOLETOS?',
          sale_after: '¿PERMITIR COMPRAS DESPUÉS DE LA HORA DE SALIDA?',
          max_time: 'Tiempo máximo para compra desde la app',
          maxTimeDES: 'Tiempo máximo para venta desde boletería física',
          changes: '¿ADMITIR CAMBIOS?',
          max_departure_time: 'Tiempo máximo antes de la salida',
          max_arrival_time: 'Tiempo máximo después de la salida',
          max_changes: 'Máximo cambios permitidos',
          max_value: 'Valor máximo permitido',
          maxTimeValid: 'Expiración',
          availabilityBuyTitle: 'DISPONIBILIDAD PARA COMPRA',
          availabilityBuyDescription: 'Máximo de tiempo con el que permites comprar con antelación',
          authorizationTitle: 'AUTORIZACIÓN PARA DESCUENTOS',
          authorizationDescription: 'Indica si deseas que los vendedores tengan que contar o no con la autorización de un administrador de suscripción para poder realizar descuentos.',
          noAuth: 'No requiere autorización',
          yesAuth: 'Requiere autorización',
          timeBeforeCancellations: 'Tiempo máximo antes de la salida',
          timeAfterCancellations: 'Tiempo máximo después de la salida',
          onlyOriginal: 'Solo en plaza original',
          anyPlace: 'Cualquier plaza',
          cancellationAllow: '¿ADMITIR ANULACIONES?'
        }
      },
      refunds: {
        title: 'Reembolsos digitales',
        description: 'Se muestran las solicitudes de reembolsos de dinero provenientes de boletos digitales y que han sido atendidas por personal de soporte y servicio de Passer.',
        detailsTitle: 'DETALLE DE LOS BOLETOS',
        refundOffice: 'Plaza reembolso',
        reasonDecline: 'Motivo rechazo',
        declineRefund: 'RECHAZAR REEMBOLSO',
        otherReason: 'Razón otro motivo',
        reference: 'Referencia transferencia',
        refundAmount: 'MONTO REEMBOLSADO',
        menuList: [
          { icon: 'eye', name: 'Detalles' },
          { icon: 'payments', name: 'Reembolsar' },
          { icon: 'block', name: 'Rechazar' }
        ],
        statusList: [
          { displayName: 'Pendiente', value: 1 },
          { displayName: 'Reembolsado', value: 2 },
          { displayName: 'Rechazado', value: 3 }
        ],
        reasonDeclineList: [
          { displayName: 'Incumplimiento políticas', value: 1 },
          {  displayName: 'Falsedad información', value: 2 },
          {  displayName: 'Administración no autoriza', value: 3 },
          {  displayName: 'Otro', value: 4 }
        ]
      }
    },
    transactions: {
      title: 'RECAUDO Y LIQUIDACIONES',
      generateExcel: 'Generar reporte en Excel',
      inProgress: {
        title: 'EN CURSO',
        emptyDataTitle: 'No hay recaudos',
        emptyDataDescription: 'Aún no hay recaudos en curso registrados.',
        menu: [
          { icon: 'credit_card_off', name: 'Rechazos cobro electrónico' }
        ]
      },
      finished: {
        title: 'FINALIZADAS',
        emptyDataTitle: 'Sin transacciones finalizadas',
        emptyDataDescription: 'Aún no hay transacciones finalizadas.',
        menu: [
          { icon: 'wallet', name: 'Detalles ecommerce y físico' }
        ],
        menuDownload: [
          { icon: 'table-rows', name: 'Descarga Excel' },
          // { icon: 'description', name: 'Descargar PDF' }
        ]
      },
      conciliations: {
        title: 'CONCILIACIONES',
        totalPhysicalTickets: 'Total boletos físicos',
        ticketsSales: 'Venta bruta boletos',
        discountElder: '- Descuentos adultos',
        discountChanges: '- Descuentos cambios',
        discountAuth: '- Descuentos autorizados',
        cancellations: '- Anulaciones (Reintegros)',
        driverSale: 'Entrega neta conductor',
        total: 'Total entrega neta',
        shipmentsTitle: 'CONCILIACIONES - LOGÍSTICA',
        shipmentsDescription: 'Facturación y consolidados correspondientes a los servicios de envíos.',
        menu: [
          { icon: 'table-rows', name: 'Excel consolidado boletería' },
          { icon: 'table-rows', name: 'Excel consolidado conductores' },
          { icon: 'table-rows', name: 'Excel consolidado de anulaciones' },
          { icon: 'description', name: 'PDF consolidado boletería' },
          { icon: 'description', name: 'PDF consolidado conductores' }
        ],
        menuEcommerce: [
          { icon: 'table-rows', name: 'Excel consolidado' },
          { icon: 'description', name: 'PDF consolidado' }
        ],
        menuShipment: [
          { icon: 'table-rows', name: 'Excel consolidado envíos' },
          { icon: 'description', name: 'PDF consolidado envíos' },
        ],
        menuListShipment: [
          { icon: 'assignment', name: 'Vendedores vinculados' }
        ]
      },
      modals: {
        deniedTransactions: {
          title: 'TRANSACCIONES RECHAZADAS',
          description: 'Las transacciones rechazadas corresponden únicamente al cobro electrónico. Tu empresa tiene una garantía de hasta ₡5.000 por cada pago rechazado.',
          rejectionAmount: 'Cantidad rechazos'
        },
        linkedAgents: {
          title: 'VENDEDORES VINCULADOS - BOLETERÍA FÍSICA',
          description: 'Detalle de vendedores vinculados a las ventas de boletos fîsicos. Ciclo de facturación de las 00:00 a las 23:59 del <b>{{date}}</b>',
          menuList: [
            { icon: 'print', name: 'Imprimir cierre' },
            { icon: 'table-rows', name: 'Descarga desglose de ventas' }
          ]
        },
        linkedDrivers: {
          title: 'CONDUCTORES VINCULADOS - RECAUDO FÍSICO',
          description: 'Detalle de conductores vinculados al ciclo de facturación de las 00:00 a las 23:59 del <b>{{date}}</b>',
          discountElder: '- Descuentos adultos',
          physicalTickets: 'Boletos físicos',
          digitalTickets: 'Boletos digitales',
          cashTickets: 'Efectivo pasajes',
          cashDelivery: 'Entrega neta',
          grossCollection: 'Recaudo bruto',
          menuList: [
            { icon: 'print', name: 'Imprimir cierre' },
            { icon: 'table-rows', name: 'Descargar desglose por tramo' },
            { icon: 'table-rows', name: 'Descargar compilado por tarifas' }
          ]
        },
        linkedRolesEcommerce: {
          title: 'ROLES VINCULADOS - ECOMMERCE',
          description: 'Detalle de los roles asociados a la conciliación seleccionada para el ciclo de facturación de las 00:00 a las 22:59 del {{date}}'
        },
        linkedRoles: {
          title: 'ROLES VINCULADOS - BOLETERÍA FÍSICA',
          description: 'Detalle de los roles asociados a la conciliación seleccionada para el ciclo de facturación de las 00:00 a las 23:59 del <b>{{date}}</b>'
        },
        deductionDetails: {
          title: 'DETALLE DE LA DEDUCCIONES Y COMPESACIONES',
          subTitle: 'CONSIDERACIÓN IMPORTANTE',
          description: 'Passer aplica un tasa de compensación igualada a la tasa de deducciones a las transacciones de ecommerce para que siempre puedas recibir tu base de facturación mínima esperada, sin rebajos.',
          totalRetentions: 'TOTAL DE RETENCIONES',
          totalCompensations: 'TOTAL DE COMPENSACIONES'
        },
        linkedAgentsShipment: {
          title: 'VENDEDORES VINCULADOS - LOGÍSTICA',
          description: 'Detalle de agentes vinculados a las venta del servicio de envíos. Ciclo de facturación de las 00:00 a las 23:59 del {{date}}',
          menuList: [
            { icon: 'print', name: 'Imprimir cierre' },
            { icon: 'table-rows', name: 'Descargar desglose de ventas' }
          ]
        }
      },
      ecommerce: {
        considerationTitle: 'CONSIDERACIÓN IMPORTANTE',
        considerationDescription: 'El cálculo de deducciones, así como de compensaciones, se realiza en función de los parámetros de retenciones que el dueño de grupo administrativo ha indicado en el módulo de Passer Payments. El monto de ',
        considerationDescription2: '\"deuda Passer\"',
        considerationDescription3: ' es un saldo pendiente por concepto de descuentos y que Passer te acreditará directamente cada día de manera adicional a la liquidación del banco.',
        enterprisesTitle: 'CONCILIACIONES - ECOMMERCE',
        enterprisesDescription: 'Facturación y liquidación correspondientes a ventas online (app). La liquidación esperada contempla la facturación de un día calendario en el rango de las 00:00 hrs a hasta las 23:59 hrs',
        ticketTitle: 'CONCILIACIONES - BOLETERÍA FISÍCA Y RECAUDO FÍSICO',
        ticketDescription: 'Facturación y liquidación correspondientes a ventas físicas',
        pendingTitle: 'AJUSTES DE LIQUIDACIÓN DE VENTAS PENDIENTES',
        pendingDescription: 'El monto pendiente corresponde al saldo que, debido al ciclo de ventas según banco, no pudo ser acreditado a tu cuenta al día hábil siguiente de la fecha de venta correspondiente. Este monto lo verás debidamente reflejando junto la liquidación del día anterior a la fecha de ajuste.',
        invoicedBase: 'Base facturada',
        deductions: '- Deducciones (banco + IVA)',
        compensation: '+ Compensación deducciones',
        compensationTitle: 'Compensaciones',
        deductionsTitle: '- Deducciones',
        invoicedTotal: '= Total facturado',
        debtAmount: '+ Deuda Passer',
        expectedLiquidation: 'Liquidación esperada',
        pendingAmount: 'Monto pendiente',
        liquidationAmount: '= Liquidación banco',
        ticketsNumber: 'Cantidad boletos',
        menu: [
          { icon: 'account-balance', name: 'Detalle deducciones' },
          { icon: 'bus', name: 'Roles vinculados' }
        ],
        menuPending: [
          { icon: 'receipt_long', name: 'Detalles' }
        ],
        ticketStore: {
          window: 'Ventanilla',
          ticket: 'Boletos',
          adult: '- Descuentos adultos',
          discount: '- Descuentos autorizados',
          changes: '- Descuentos cambios',
          cancellations: '- Anulaciones',
          cash: 'Entrega neta',
          grossSales: 'Venta bruta',
          menuListTicket: [
            { icon: 'bus', name: 'Roles vinculados' },
            { icon: 'assignment', name: 'Vendedores vinculados' },
            { icon: 'drivers', name: 'Conductores vinculados' }
          ]
        }
      },
      electronicPayment: {
        totalAmounts: 'Se muestran los montos totales según el rango de fecha seleccionado',
        totalCollection: 'TOTAL RECAUDO',
        totalRetention: 'TOTAL RETENCIONES',
        totalComission: 'TOTAL COMISIONES PASSER',
        totalLiquidation: 'TOTAL LIQUIDACIONES',
        menu: [
          { icon: 'directions_bus_filled', name: 'Roles vinculados' }
        ]
      },
      detailsCollection: {
        title: 'Detalles recaudo ecommerce y físico',
        description: 'Se muestra información completa para el role seleccionado. Por favor considera que el banco podría aplicar otros rebajos y retenciones que aquí no se contemplan, revisa tu estado de cuenta bancaria para conocer más detalles sobre tus movimientos, o bien, contacta con tu agente de servicio institucional.',
        roleDescription: 'DESCRIPCIÓN DEL ROLE',
        importantDataRoleTitle: 'CONSIDERACIÓN IMPORTANTE',
        importantDataRoleDesc: 'Los conductores y vendedores asociados a este role, los podrás consultar directamente en la pestaña \"Conciliaciones\" una vez se hayan realizado todos los cierres correspondientes',
        collectDetail: 'VENTAS ECOMMERCE',
        transactions: 'Total de transacciones',
        tickets: 'Total de boletos',
        incomeWithholding: 'Rentención renta',
        baseAmount: 'MONTO BASE DE VENTAS',
        compensatoryCharge: 'COBRO COMPENSATORIO',
        totalCharged: 'TOTAL COBRADO',
        expectedLiquidation: 'LIQUIDACÓN ESPERADA',
        physicalSales: 'VENTAS BOLETERÍA FÍSICA (VENTANILLA)',
        totalSale: 'Venta bruta',
        cancellations: 'Monto total anulaciones',
        totalTerminal: 'ENTREGA NETA',
        driverCollection: 'RECAUDO CONDUCTOR',
        totalElder: 'Total adultos mayores',
        totalFuel: 'Total combustible',
        totalTickets: 'Recaudo bruto',
        totalBurden: 'Total carga',
        pendingDiscountTitle: 'SALDO PENDIENTE POR DESCUENTOS',
        pendingDiscountDescription: 'El saldo pendiente por descuentos corresponde a las ventas que fueron correctamente procesadas dentro del ciclo de procesamiento Enterprises pero que recibieron un descuento (regalía). El monto total de estas transacciones será acreditado por Passer a tu cuenta al día siguiente del role.',
        totalTransactions: 'CANTIDAD TRANSACCIONES',
        totalTicket: 'CANTIDAD DE BOLETOS',
        totalDiscount: 'MONTO TOTAL DESCUENTO',
        closingPendingTitle: 'CIERRES PENDIENTES',
        closingPendingDescription: 'Existen cierres de recaudo pendientes para este role. Por favor realiza los cierres para poder generar los reportes de conciliaciones completos.',
        totalCollect: 'Recaudo total efectivo',
        luggageCollect: 'Recaudo carga',
        paperTickets: 'Boletos físicos',
        digitalTickets: 'Boletos digitales',
        grossSale: 'Venta bruta del tramo'
      },
      closingDriver: {
        refunds: '- REVERSIONES',
        elder: '- ADULTOS MAYORES',
        physics: 'BOLETOS FÍSICOS',
        digital: 'BOLETOS DIGITALES',
        transferTickets: 'MARCAS TRASBORDO',
        grossSales: 'RECAUDO BRUTO',
        fuel: '- COMBUSTIBLE',
        burden: '+ CARGA',
        effectiveDelivery: 'ENTREGA NETA',
        fuelDescription: 'Descripción de combustible',
        signSupervisor: 'Firma supervisor',
        signDriver: 'Firma conductor',
        receive: 'Recibir/Imprimir'
      }
    },
    units:{
      search_plate: 'Buscar placa',
      search_num: 'Buscar número',
      circ: 'Circulación',
      circ_out: 'Fuera de circulación',
      total_standing: 'Total de pie',
      behind: 'Atras',
      state: 'Puedes ingresar el vehículo pero mantenerlo en un estado de inactividad, con lo cual no será considerado en los roles.',
      list_vehicles: {
        title: 'Lista de vehículos',
        no_units: 'No hay vehículos registrados',
        no_unitsD: 'Aún no has creado vehículos y configuraciones. Ingresa a la pestaña \"Agregar\" para comenzar a resgistrar nuevos vehículos.'
      },
      create_modify: {
        title: 'Agregar/Consultar',
        subtitle: 'DISEÑO Y ASIGNACÓN DE FLOTA VEHICULAR',
        config_new: 'Nuevo modelo',
        config_exist: 'Consultar modelo',
        tabList: [
          {action: 'configNew', name: 'Nuevo modelo'},
          {action: 'configExist', name: 'Consultar modelo'}
        ],
        generalInformation: {
          title: 'Información general',
          infoTitle: 'INFORMACIÓN GENERAL DEL MODELO',
          infoDescription: 'Datos básicos del vehículo',
          capacityTitle: 'ESTRUCTURA DEL AFORO',
          capacityDescription: 'Ingresa la información que permitirá al sistema crear la distribución del diagrama de aforo vehicular.',
          floorAmount: 'Cantidad de pisos',
          floor1: 'Primer piso',
          floor2: 'Segundo piso',
          totalSeats: 'Total de asientos (ambos pisos)',
          totalRows: 'Total de filas',
          doorAmount: 'Columnas lado puerta',
          driverAmount: 'Columnas lado conductor',
          finalSeat: 'Asiento central atrás',
          standingT: '¿Vendes de pie?',
          standingAmount: 'Cantidad pasajeros de pie',
          porcStanding: 'Porcentaje de pie',
          withoutNumberTitle: '¿VENDER SIN NUMERACIÓN?',
          withoutNumberDesc: 'Si los asientos o espacios de este modelo no poseen rotulación numerada puedes optar por vender sin numeración.',
          prefTitle: 'ASIENTOS PREFERENCIALES',
          prefDescription: 'Si tus servicios cuentan con servicios preferenciales (adultos mayores, discapacidad, etc...) puedes indicar los números de asiento de hasta 10 espacios para este modelo.'
        },
        model: {
          title: 'Gráfico del modelo'
        },
        vehicles: {
          title: 'Asociar vehículos',
          subtitle: 'AGREGAR NUEVO VEHÍCULO',
          description: 'Ingresa la placa y número de aquellos vehículos que compartan la información y gráfico de este modelo. Puedes omitir este paso y hacerlo en otro momento.',
          menuList: [
            {icon: 'delete', name: 'Eliminar'},
            {icon: 'power-on', name: 'Cambiar estado'},
            {icon: 'swap-horiz', name: 'Reasignar'}
          ]
        },
        new_model: {
          desc: 'Crea y diseña acá la estructura de tu flota, por ejemplo, capacidad, filas y demás características, luego, indica las placas de los vehículos que se apegan a esta configuración. Si tienes dudas sobre cómo funciona puedes ',
          capacity: 'ESTRUCTURA DEL AFORO',
          standingT: 'Vender de pie',
          cant_floor: 'Cantidad de pisos',
          cant_standing: 'Cantidad pasajeros de pie',
          porc_standing: 'Porcentaje de pie',
          row_col: 'Filas y columnas',
          row_colD: 'Define la cantidad de columnas que posee este vehículo para colocar asientos. Se generarán hasta 65 y deberás agregar mínimo 40 asientos por cada uno. Si tienes dudas sobre cómo funciona puedes ',
          floor_1: 'Piso 1',
          floor_2: 'Piso 2',
          cant_row: 'Cantidad de filas',
          cant_door: 'Columnas lado puerta',
          cant_driver: 'Columnas lado conductor',
          pref_desc: 'Digita los números de asiento que serán designados como preferenciales, por ejemplo, el 30 y 31. Debes ingresar al menos 4 asientos.',
          generate: 'Generar diagrama',
          veh_desc: 'Digita las placas de los vehículos que comparten esta configuración',
          add_act: 'Agregar como activo',
          add_inact: 'Mantener inactivo',
          chg_status: 'Cambiar estado',
          sit_pref: 'Asientos preferenciales',
          finalS: 'Asiento central atrás',
          req_data: 'Datos del modelo requeridos.'
        },
        modify_model: {
          desc: 'Estas son las configuraciones de modelos que ya has creado y los vehículos que has asignado. Puedes reasignarlos, eliminarlos o crea una nueva configuración para nuevos modelos.',
          models: 'Modelos',
          findM: 'Buscar modelo',
          new_diagram: 'Nuevo diagrama',
          veh_asoc: 'VEHÍCULOS ASOCIADOS',
          new_vehT: 'NUEVO VEHÍCULO',
          new_vehD: 'Ingresa la placa y número de unidad del vehículo que quieres asociar al modelo ',
          reasignT: 'REASIGNAR VEHÍCULO',
          reasignD: 'Ingresa el modelo al que deseas mover este vehículo. Actualmente está con el modelo ',
          no_model: 'No tienes otros modelos a los cuales reasignar vehículos.'
        },
        diagram: {
          title: 'DIAGRAMA DEL VEHÍCULO',
          desc: 'Arrastra y suelta los asientos a los espacios. Si dejas espacios en blanco significa que ahí no habrán asientos, por ejemplo, para colocar sillas de ruedas o una puerta.',
          sell_without: 'Vender sin numeración',
          save_config: 'GUARDAR CONFIGURACIÓN'
        },
        listModels: {
          search: 'Buscar modelo',
          models: 'Modelos',
          menu: [
            { icon: 'scatter-plot', name: 'Modificar información y gráfico' },
            { icon: 'edit', name: 'Modificar gráfico' },
            { icon: 'bus', name: 'Modificar vehículos' },
            { icon: 'delete', name: 'Eliminar' }
          ]
        }
      },
      alerts: {
        brand_err: 'Error al cargar marcas.',
        model_err: 'Error al cargar modelos de buses.'
      },
      new_Unit: {
        auto: 'Vehículo',
        floor1: 'Un piso',
        floor2: 'Dos pisos',
        consultT: {
          floor1: 'Asientos 1er piso',
          floor2: 'Asientos 2do piso',
          pref: 'Preferenciales',
          afor: 'Estructura del aforo'
        }
      },
      mod: {
        title: 'Modificar número de unidad',
        now: 'Número actual',
        new: 'Nuevo número'
      },
      modals: {
        successC: 'Se ha agregado la estructura de este modelo con sus respectivos vehículos.',
        successCM: 'Se ha modificado la estructura de este modelo con éxito.',
        succVC: 'Los vehículos se han asignado con éxito a la estructura del modelo seleccionado.'
      },
      types: {
        0: 'Microbus',
        1: 'Buseta pequeña',
        2: 'Buseta grande',
        3: 'Bus estandar (1 piso)',
        4: 'Bus especial (2 pisos)'
      }
    },
    valid_pos: {
      alerts: {
        select_one: 'Debes seleccionar una fila antes de poder detectar el validador.',
        status_true: 'Este validador ya ha sido detectado.',
        assign: 'Debes detectar primero el validador, antes de poder asignar el UID Key.',
        detected: 'Validador detectado con éxito.',
        err_types: 'Error al cargar tipos de validadores',
        errorTransportations: 'Error al cargar los vehículos',
        err_uid: 'Error al cargar UID Keys detectados.',
        err_status3: 'Acción no permitida por estado inactivo',
        limit: 'Límite de UID Keys alcanzado.'
      },
      assigned: {
        title: 'Validadores y Terminales asignados',
        reassign: 'Reasignar',
        conn: 'conexión',
        footer: 'Asegúrate que todos los datos son correctos antes de asignar este dispositivo.',
        modal_remove: {
          title: 'Retirar dispositivo',
          desc: '¿Estás seguro que deseas retirar de operación este dispositivo de cobro? Si lo haces el cobro electrónico dejará de funcionar.',
          title_succ: 'Retiro solicitado',
          desc_succ: 'Se ha generado la solicitud de retiro.'
        },
        modal_reassign: {
          title: 'Reasignar dispositivos',
          desc: 'Las reasignación es un movimiento físico de dispositivo.',
          serial: 'Serial que será reasignado',
          veh_actual: 'Vehículo actual',
          new_veh: 'Nuevo vehículo',
          email: 'Email responsable gestión'
        },
        modal_phone: {
          title: 'SIM Card',
          desc: 'SIM Card asignada a este este dispositivo',
          currentSIM: 'SIM Card actual',
          modifyTitle: 'MODIFICAR SIM CARD',
          modifyDescription: 'Ingresa el número de teléfono de la nueva SIM Card'
        },
        modalDetails: {
          softwareVersion: 'Versión de software',
          lastConnection: 'Última comunicación lista denegación'
        },
        menu: [
          { icon: 'swap-horiz', name: 'Reasignar' },
          { icon: 'cancel', name: 'Retirar' },
          { icon: 'sim-card', name: 'Línea telefónica' },
          { icon: 'eye', name: 'Detalles' }
        ]
      },
      assign: {
        tabs: [
          {
            name: 'VALIDADORES',
            action: "validators"
          },
          {
            name: 'OTRAS TERMINALES',
            action: "otherTerminals"
          }
        ],
        vehicleTitle: 'VEHÍCULOS SIN ASIGNAR VALIDADOR O TERMINAL',
        vehicleValidDescription: 'Selecciona el vehículo al cual asignarás el validador',
        vehicleTerminalDescription: 'Selecciona el vehículo al cual asignarás el validador o terminal',
        emptySerialsTitle: 'Sin seriales',
        emptySerialsDescription: 'Parece que tu suscripción no tiene, o aún no se le han generado los seriales para validadores. Por favor consulta con soporte y servicio Enterprises.',
        emptyChecks: 'Debes seleccionar tanto un serial como un vehículo para realizar la asignación.'
      },
      add: {
        title: 'Asignar',
        detected: 'Detectado',
        detect: 'Detectar',
        delete: 'Borrar',
        no_generate: 'Aún no has generado identificadores para dispositivos. Presiona el botón \"Generar Keys\" para comenzar.',
        detecting_uid: 'Detectando UID Keys, Por favor espera.',
        modals: {
          assign_desc: 'Asigna las los dispositivos y otros elementos que serán ligados a esta llave única.',
          sim: ' o similar',
          generate_desc: 'Ingresa la cantidad de identificadores que necesitas'
        }
        }
    },
    weeks_days: {
      monday: 'Lunes',
      tuesday: 'Martes',
      wednesday: 'Miércoles',
      thursday: 'Jueves',
      friday: 'Viernes',
      saturday: 'Sábado',
      sunday: 'Domingo'
    },
    table: {
      error : "Ha ocurrido un error obteniendo la información",
      reload: "Recargar"
    }
  }
};
