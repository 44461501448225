import { loginEndpoints } from "./network.data";

export function getDataObject(object: any, param: string, secondParam: string, defaultReturn: any): any {
  try{
    if(object){
      return (!secondParam ? object[param] : object[param][secondParam]);
    }
    else return defaultReturn;
  } catch(_){
    return defaultReturn;
  }
}

export function gotToNewTab(url): void {
  window.open(url, '_blank').focus();
}

export function deleteStressM(stg: string): string {
  return stg
    .normalize('NFD')
    .replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,'$1')
    .normalize();
}

export function setImage(item): string {
  switch(item.toLowerCase()){
    case 'visa' : return 'fab fa-cc-visa';
    case 'master' : return 'fab fa-cc-mastercard';
    case 'discover' : return 'fab fa-cc-discover';
    case 'amex' : return 'fab fa-cc-amex';
    case 'american_express' : return 'fab fa-cc-amex';
    case 'dinners' : return 'fab fa-cc-diners-club';
    case 'jcb' : return 'fab fa-cc-jcb';
    default: return 'fas fa-credit-card';
  }
}

export const YesNo = {
  yes: true,
  no: false
};

export function statusValidatorName(status: number): string {
  switch(status){
    case 1: return 'labels.disconn';
    case 2: return 'employees.table.status_active';
    case 3: return 'employees.table.status_inactive';
    default: return '';
  }
}

export function downloadFile(href: string, name: string): void {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = href;
  a.download = name;
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  a.remove();
}

export function isLoginEndpoint(url: string): boolean {
  const requestFound = Object.values(loginEndpoints).some(value => url.includes(value));
  if (requestFound) {
    return true;
  }

  return false;
}

export function includesCommaDot(value: number | string): boolean {
  return ('' + value).includes('.') || ('' + value).includes(',');
}

export const openAccountLink = 'https://cuentasces.bnenlinea.com/BNCR.CES.WEB/CrearCliente/IdentificacionCliente.aspx';
