import { Injectable } from '@angular/core';
import { Socket, SocketIoConfig } from 'ngx-socket-io';
import { environment } from '@environments/environment';
import { last, lastValueFrom, Observable, takeUntil, timer } from 'rxjs';
import { SocketEventTypes } from '@enums/socket.enum';
import { ServiceSeatSelect } from '@models/entities/tickets/ticketsData';
import { UpdatePositionBus } from '@models/entities/operation/operation';

@Injectable()
export class SocketProvider extends Socket {

  constructor(private _socket: Socket) {
    super({
      url: environment.socket_url,
      options: {
        reconnection: true,
        timeout: 10000,
        transports: ['polling']
      }
    });
  }

  initSocket(user: string, copny: any): void {
    if (this._socket) this._socket.disconnect();
    const socketIoConfig: SocketIoConfig = {
      url: environment.socket_url,
      options: {
        reconnection: true,
        timeout: 10000,
        transports: ['polling']
      }
    };
    this._socket = new Socket(socketIoConfig);
    this._socket.connect();
    this._socket.emit(SocketEventTypes.REGISTER, { user, copny });
    this.reconnect(user, copny);
  }

  reconnect(user: string, copny: any): void {
    this._socket.removeAllListeners('reconnect');
    this._socket.on('reconnect', () => {
      console.log('Reconnect...');
      this._socket.emit(SocketEventTypes.REGISTER, { user, copny });
    });
  }

  onBusUpdated = (callback: any): any => {
    this._socket.removeAllListeners(SocketEventTypes.BUS_UPDATED);
    this._socket.on(SocketEventTypes.BUS_UPDATED, (data: UpdatePositionBus) => callback(data) );
  }

  isConnected(): boolean { return this._socket?.ioSocket?.connected; }
  onDisconnect(): void { this._socket.disconnect(); }
}

@Injectable()
export class SocketTicketProvider extends Socket {
  constructor(private _socket: Socket) {
    super({
      url: environment.socket_ticket_url,
      options: {
        reconnection: true,
        timeout: 10000,
        transports: ['polling']
      }
    });
  }

  // tslint:disable-next-line: variable-name
  initSocketTikets(user: any, role: any, origin: string, destination: string, isreconnection?: boolean): void {
    if (this._socket) this._socket.disconnect();
    const socketIoConfig: SocketIoConfig = {
      url: environment.socket_ticket_url,
      options: {
        reconnection: true,
        timeout: 10000,
        transports: ['polling']
      }
    };
    this._socket = new Socket(socketIoConfig);
    this._socket.connect();
    this._socket.emit(SocketEventTypes.REGISTER, {user, role, origin, destination, platform: 'DES',  isreconnection});
    this.reconnect(user, role, origin, destination, 'DES', isreconnection);
  }

  _getselectedSeats = () => {
    this._socket.emit(SocketEventTypes.GET_SELECTED_SEAT);
  }

  getSeatsFromOtherUsers = (callback: any): any => {
    this._socket.removeAllListeners(SocketEventTypes.SELECTED_SEATS);
    this._socket.on(SocketEventTypes.SELECTED_SEATS, (data: any) => callback(data) );
  }

  emitSendSeat = (seat: number, selected: boolean, service: ServiceSeatSelect): Observable<ServiceSeatSelect> => {
    return new Observable((subscriber) => {
       this._socket.emit(SocketEventTypes.SEND_SEAT, { ...service, seat, selected },
        (confirmation: ServiceSeatSelect) => {
          subscriber.next(confirmation);
          subscriber.complete();
       });
    });
  }

  async selectMySeats(seat: number, selected: boolean, service: ServiceSeatSelect): Promise<ServiceSeatSelect> {
    this._socket.removeAllListeners(SocketEventTypes.SEND_SEAT);
    const seatInfo$ = this.emitSendSeat(seat, selected, service).pipe(
      takeUntil(timer(30000)),
      last()
    );

    return await lastValueFrom(seatInfo$);
  }

  selectedStanding = (callback: any): any => {
    this._socket.removeAllListeners(SocketEventTypes.SELECTED_STANDDING);
    this._socket.on(SocketEventTypes.SELECTED_STANDDING, (data: any) => callback(data) );
  }

  isFullIsComp(): any {
    this._socket.removeAllListeners('availability-change');
    return new Promise(resolve => {
      this._socket.on('availability-change', (data: any) => { resolve(data); });
    });
  }

  recieveMessage = (callback): any => {
    this._socket.removeAllListeners(SocketEventTypes.ERROR);
    this._socket.on(SocketEventTypes.ERROR, (data: any) => callback(data) );
  }

  recieveMessageTime(): any {
    this._socket.removeAllListeners(SocketEventTypes.TIME_EXPIRED);
    return new Promise(resolve => {
      this._socket.on(SocketEventTypes.TIME_EXPIRED, (data: any) => { resolve(data); });
    });
  }

  disconnect(): any {
    this._socket.removeAllListeners(SocketEventTypes.DISCONNECT);
    return new Promise(resolve => {
      this._socket.on(SocketEventTypes.DISCONNECT, (data: any) => { resolve(data); });
    });
  }

  onDisconnect(): void { this._socket.disconnect(); }
  onConnect(): void { this._socket.connect(); }
  isConnected(): boolean { return this._socket?.ioSocket?.connected; }

  // tslint:disable-next-line: variable-name
  reconnect(user: any, role: any, origin: string, destination: string, platform, isreconnection: boolean): void {
    this._socket.removeAllListeners('reconnect');
    this._socket.on('reconnect', () => {
      console.log('Reconnect...');
      this._socket.emit(SocketEventTypes.REGISTER, { user, role, origin, destination, platform, isreconnection});
    });
  }
}

@Injectable()
export class SocketPrintProvider extends Socket {
  constructor(private _socket: Socket) {
    super({
      url: environment.socket_print_url,
      options: {
        reconnection: true,
        timeout: 10000,
        transports: ['polling']
      }
    });
  }

  print(data: any): void {
    if (this._socket) this._socket.disconnect();
    const socketIoConfig: SocketIoConfig = {
      url: environment.socket_print_url,
      options: {
        reconnection: true,
        timeout: 10000,
        transports: ['polling']
      }
    };
    this._socket = new Socket(socketIoConfig);
    this._socket.connect();
    this._socket.emit('print', data);
  }

  isConnected(): boolean { return this._socket && this._socket.ioSocket.connected; }
  onConnect(): void { this._socket.connect(); }
  onDisconnect(): void { this._socket.disconnect(); }
}
