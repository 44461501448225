import {NgModule} from '@angular/core';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import {MatTabsModule} from '@angular/material/tabs';
import {MatRadioModule} from '@angular/material/radio';
import {MatStepperModule} from '@angular/material/stepper';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatExpansionModule} from '@angular/material/expansion';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@NgModule({
  imports: [
    ReactiveFormsModule, FormsModule, CommonModule, MatProgressSpinnerModule, MatChipsModule,
    MatDialogModule, MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule,
    MatIconModule, MatTooltipModule, MatSidenavModule, MatButtonModule,
    MatToolbarModule, MatMenuModule, MatTabsModule, MatSelectModule, MatRadioModule,
    MatStepperModule, MatAutocompleteModule, MatCheckboxModule, MatProgressBarModule,
    MatSnackBarModule, MatSlideToggleModule, MatCardModule, MatExpansionModule,
    NgxMatSelectSearchModule
  ],
  exports: [
    ReactiveFormsModule, FormsModule, CommonModule, MatProgressSpinnerModule, MatChipsModule,
    MatDialogModule, MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule,
    MatIconModule, MatTooltipModule, MatSidenavModule, MatButtonModule,
    MatToolbarModule, MatMenuModule, MatTabsModule, MatSelectModule, MatRadioModule,
    MatStepperModule, MatAutocompleteModule, MatCheckboxModule, MatProgressBarModule,
    MatSnackBarModule, MatSlideToggleModule, MatCardModule, MatExpansionModule,
    NgxMatSelectSearchModule
  ]
})
export class AppMaterialModule {}
