import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoaderManagmentService } from '@providers/loader-managment/loader-managment.service';
import { TranslationService } from '@providers/translation/translation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private loading: LoaderManagmentService,
    private translation: TranslationService,
    private translate: TranslateService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.initProcess();
  }

  async initProcess(): Promise<void> {
    this.loading.dismissLoading();
    try {
      const userLang = await this.translation.getTranslateUse();
      if (userLang) {
        this.setTranslate(userLang);
      } else {
        this.setTranslate();
      }
    } catch(err) {
      this.setTranslate();
    }
  }

  setTranslate(userLang: string = 'es'): void {
    this.translate.addLangs(['en', 'es']);
    this.translate.setDefaultLang(userLang);
    this.translate.use(userLang);
    this.translation.setUserTranslate(userLang);
  }
}
