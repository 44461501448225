import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { TitleDescComponent } from '@components/title-desc/title-desc';
import { SnackBar } from '@models/entities/general/general';


@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  snackBarOpened: MatSnackBarRef<any>;
  snackData: Array<SnackBar> = [];

  constructor(private _snackBar: MatSnackBar) { }

  reviewModalRep(title: string, message: string): boolean {
    return this.snackData.findIndex( data => data.title === title && data.message === message) === -1;
  }

  createsnackData(title: string, message: string, duration: number): void {
    this.snackData.push({title, message, duration});
  }

  showSnackBar(title: string, desc: string, duration: number = 5): void {
    if(this.reviewModalRep(title, desc)){
      this.createsnackData(title, desc, duration);
      if(!this.snackBarOpened){
        this.openSnackBar(title, desc, duration);
        this.afterCloseModal();
      }
    }
  }

  openSnackBar(title, desc, duration): void {
    this.snackBarOpened = this._snackBar.openFromComponent(TitleDescComponent, {
      duration: duration * 1000,
      data:{
        classDiv: '',
        title, desc,
        classTitle: 'f13',
        classDesc: 'f12'
      }
    });
  }

  afterCloseModal(): void {
    const subscribe: Subscription = this.snackBarOpened.afterDismissed().subscribe( () => {
      this.snackBarOpened = null;
      this.snackData[0].subscribe.unsubscribe();
      this.snackData.shift();
      if(this.snackData.length > 0){
        const snack = this.snackData[0];
        this.openSnackBar(snack.title, snack.message, snack.duration);
        this.afterCloseModal();
      }
    });

    this.snackData[0].subscribe = subscribe;
  }

  async copyText(text: string, textToShow: string): Promise<void> {
    try {
      await navigator.clipboard.writeText(text);
      this.showSnackBar(textToShow, '');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }
}
