export enum EIcons {
  ACCOUNT_BALANCE = 'account-balance',
  AIRLINE_SEAT = 'airline-seat',
  ANDROID = 'android',
  ARROW_LEFT = 'arrow-left',
  ASSIGNMENT = 'assignment',
  BUS = 'bus',
  BUS_SOLID = 'bus-solid',
  CALENDAR_BUSY = 'calendar-busy',
  CAMERA = 'camera',
  CANCEL = 'cancel',
  CARD = 'card',
  CASHIER = 'cashier',
  CHECK = 'check',
  CLIPBOARD = 'clipboard',
  CLOCK = 'clock',
  CLOSE = 'close',
  COMPRESS = 'compress-arrows',
  CONTACT = 'contact',
  COPY = 'copy',
  CREDIT_CARD = 'credit-card',
  DELETE = 'delete',
  DESCRIPTION = 'description',
  DIRECT = 'direct',
  DOWNLOAD = 'download',
  DRIVERS = 'drivers',
  EDIT = 'edit',
  ELDERYLY = 'elderly',
  EMOJI = 'emoji',
  EXPAND = 'expand-arrows',
  EYE = 'eye',
  EYE_OFF = 'eye-off',
  FILTER = 'filter',
  FILTER_OFF = 'filter-off',
  GENERATE = 'generate',
  INDIRECT = 'indirect',
  LOG_OUT = 'log-out',
  MAP_MARKED = 'map-marked',
  MENU = 'menu',
  MINUS = 'minus',
  MINUS_CIRCLE = 'minus-circle',
  MORE_VERT = 'more-vert',
  NOTIFICATIONS = 'notifications',
  PAYMENTS = 'payments',
  PENDING_ACTIONS = 'pending-actions',
  PERSON_REMOVE = 'person-remove',
  PLUS = 'plus',
  PLUS_CIRCLE = 'plus-circle',
  POWER_ON = 'power-on',
  PRINT = 'print',
  REBOOT = 'reboot',
  ROUTE = 'route',
  SCATTER_PLOT = 'scatter-plot',
  SEND = 'send',
  SETTINGS = 'settings',
  SETTINGS_ETHERNET = 'settings-ethernet',
  SIM_CARD = 'sim-card',
  SLIDERS = 'sliders',
  SORT_DOWN = 'sort-down',
  STAR = 'star',
  SWAP_HORIZ = 'swap-horiz',
  SWITCH_ACCOUNT = 'switch-account',
  TABLE_ROWS = 'table-rows',
  TICKET = 'ticket',
  UPLOAD = 'upload',
  WALLET = 'wallet',
  YOUTUBE = 'youtube'
};

export const icons: any = {
  [EIcons.ACCOUNT_BALANCE]: 'assets/icon/account-balance.svg#account-balance',
  [EIcons.AIRLINE_SEAT]: 'assets/icon/airline-seat.svg#airline-seat',
  [EIcons.ANDROID]: 'assets/icon/android.svg#android',
  [EIcons.ARROW_LEFT]: 'assets/icon/arrow-left.svg#arrow-left',
  [EIcons.ASSIGNMENT]: 'assets/icon/assignment.svg#assignment',
  [EIcons.BUS]: 'assets/icon/bus.svg#bus',
  [EIcons.BUS_SOLID]: 'assets/icon/bus-solid.svg#bus-solid',
  [EIcons.CALENDAR_BUSY]: 'assets/icon/calendar-busy.svg#calendar-busy',
  [EIcons.CAMERA]: 'assets/icon/camera.svg#camera',
  [EIcons.CANCEL]: 'assets/icon/cancel.svg#cancel',
  [EIcons.CARD]: 'assets/icon/card.svg#card',
  [EIcons.CASHIER]: 'assets/icon/cashier.svg#cashier',
  [EIcons.CHECK]: 'assets/icon/check.svg#check',
  [EIcons.CLIPBOARD]: 'assets/icon/clipboard.svg#clipboard',
  [EIcons.CLOCK]: 'assets/icon/clock.svg#clock',
  [EIcons.CLOSE]: 'assets/icon/close.svg#close',
  [EIcons.COMPRESS]: 'assets/icon/compress-arrows.svg#compress-arrows',
  [EIcons.CONTACT]: 'assets/icon/contact.svg#contact',
  [EIcons.COPY]: 'assets/icon/copy.svg#copy',
  [EIcons.CREDIT_CARD]: 'assets/icon/credit-card.svg#credit-card',
  [EIcons.DELETE]: 'assets/icon/delete.svg#delete',
  [EIcons.DESCRIPTION]: 'assets/icon/description.svg#description',
  [EIcons.DIRECT]: 'assets/icon/direct.svg#direct',
  [EIcons.DOWNLOAD]: 'assets/icon/download.svg#download',
  [EIcons.DRIVERS]: 'assets/icon/drivers.svg#drivers',
  [EIcons.EDIT]: 'assets/icon/edit.svg#edit',
  [EIcons.ELDERYLY]: 'assets/icon/elderly.svg#elderly',
  [EIcons.EMOJI]: 'assets/icon/emoji.svg#emoji',
  [EIcons.EXPAND]: 'assets/icon/expand-arrows.svg#expand-arrows',
  [EIcons.EYE]: 'assets/icon/eye.svg#eye',
  [EIcons.EYE_OFF]: 'assets/icon/eye-off.svg#eye-off',
  [EIcons.FILTER]: 'assets/icon/filter.svg#filter',
  [EIcons.FILTER_OFF]: 'assets/icon/filter-off.svg#filter-off',
  [EIcons.GENERATE]: 'assets/icon/generate.svg#generate',
  [EIcons.INDIRECT]: 'assets/icon/indirect.svg#indirect',
  [EIcons.LOG_OUT]: 'assets/icon/log-out.svg#log-out',
  [EIcons.MAP_MARKED]: 'assets/icon/map-marked.svg#map-marked',
  [EIcons.MENU]: 'assets/icon/menu.svg#menu',
  [EIcons.MINUS]: 'assets/icon/minus.svg#minus',
  [EIcons.MINUS_CIRCLE]: 'assets/icon/minus-circle.svg#minus-circle',
  [EIcons.MORE_VERT]: 'assets/icon/more-vert.svg#more-vert',
  [EIcons.NOTIFICATIONS]: 'assets/icon/notifications.svg#notifications',
  [EIcons.PAYMENTS]: 'assets/icon/payments.svg#payments',
  [EIcons.PENDING_ACTIONS]: 'assets/icon/pending-actions.svg#pending-actions',
  [EIcons.PERSON_REMOVE]: 'assets/icon/person-remove.svg#person-remove',
  [EIcons.PLUS]: 'assets/icon/plus.svg#plus',
  [EIcons.PLUS_CIRCLE]: 'assets/icon/plus-circle.svg#plus-circle',
  [EIcons.POWER_ON]: 'assets/icon/power-on.svg#power-on',
  [EIcons.PRINT]: 'assets/icon/print.svg#print',
  [EIcons.REBOOT]: 'assets/icon/reboot.svg#reboot',
  [EIcons.ROUTE]: 'assets/icon/route.svg#route',
  [EIcons.SCATTER_PLOT]: 'assets/icon/scatter-plot.svg#scatter-plot',
  [EIcons.SEND]: 'assets/icon/send.svg#send',
  [EIcons.SETTINGS]: 'assets/icon/settings.svg#settings',
  [EIcons.SETTINGS_ETHERNET]: 'assets/icon/settings-ethernet.svg#settings-ethernet',
  [EIcons.SIM_CARD]: 'assets/icon/sim-card.svg#sim-card',
  [EIcons.SLIDERS]: 'assets/icon/sliders.svg#sliders',
  [EIcons.SORT_DOWN]: 'assets/icon/sort-down.svg#sort-down',
  [EIcons.STAR]: 'assets/icon/star.svg#star',
  [EIcons.SWAP_HORIZ]: 'assets/icon/swap-horiz.svg#swap-horiz',
  [EIcons.SWITCH_ACCOUNT]: 'assets/icon/switch-account.svg#switch-account',
  [EIcons.TABLE_ROWS]: 'assets/icon/table-rows.svg#table-rows',
  [EIcons.TICKET]: 'assets/icon/ticket.svg#ticket',
  [EIcons.UPLOAD]: 'assets/icon/upload.svg#upload',
  [EIcons.WALLET]: 'assets/icon/wallet.svg#wallet',
  [EIcons.YOUTUBE]: 'assets/icon/youtube.svg#youtube'
};
