import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from '@models/entities/user';
import { IdpAccessTokenResponse } from '@models/responses/login-response';
import { LoaderManagmentService } from '@providers/loader-managment/loader-managment.service';
import { SessionDataProvider } from '@providers/session-data/session-data';
import { UserManagerService } from '@providers/user-manager/user-manager.service';
import { LoginService } from 'src/endpoints/login.service';

@Component({
  selector: 'app-confirm-credentials',
  templateUrl: './confirm-credentials.component.html'
})

export class ConfirmCredentialsComponent implements OnInit {
  passwordControl = new FormControl('', [Validators.required]);
  eyePass = true;
  email = '';
  isSkeletonPresent = false;

  constructor(
    private dialogRef: MatDialogRef<any>,
    private loading: LoaderManagmentService,
    private loginService: LoginService,
    private sessionData: SessionDataProvider,
    private userManagerService: UserManagerService
  ){}

  ngOnInit(): void {
    this.email = this.userManagerService.email;
  }

  closeModal(close = false): void {
    if (!close) {
      this.dialogRef.close();
      return;
    }

    if (this.passwordControl.invalid) {
      this.passwordControl.markAllAsTouched();
      return;
    }

    this.reEnterLogin();
  }

  async reEnterLogin(): Promise<void> {
    if (this.loading.isSkeleton()) {
      this.isSkeletonPresent = true;
      this.loading.dismissSkeleton();
    }
    this.loading.presentLoading();
    const response = await this.loginService.initLogin(this.email, this.passwordControl.value);
    const { success, data } = response;
    if (success) {
      await this.reviewSelectGroup(data);
    } else {
      this.loading.dismissLoading();
      this.closeModal();
    }
  }

  async reviewSelectGroup(dataIDP: IdpAccessTokenResponse): Promise<void> {
    const response = await this.loginService.loginSelectSubscription(dataIDP);
    const { success, data } = response;
    if (success) {
      if (data?.length > 1) {
        const { _id } = this.sessionData.subscriptionIdp;
        const subscription = data.find(subs => subs._id === _id);
        this.sessionData.subscriptionIdp = subscription;
      }
      if (this.sessionData.subscriptionIdp) {
        await this.refreshToken(data);
      } else {
        this.loading.dismissLoading();
        this.closeModal();  
      }
    } else {
      this.loading.dismissLoading();
      this.closeModal();
    }
  }

  async refreshToken(userSubscriptionsToclient: Subscription[]): Promise<void> {
    const response = await this.loginService.logingResfreshToken(userSubscriptionsToclient);
    if (response) {
      await this.saveDataUser();
    } else {
      this.loading.dismissLoading();
      this.closeModal();
    }
  }

  async saveDataUser(): Promise<void> {
    const response = await this.loginService.loginSaveUser();
    const { success, data } = response;
    if (success) {
      this.userManagerService.saveUser(data);
      this.sessionData.isLogged = true;
      await this.loginService.setSessionDataStorage();
      if (this.isSkeletonPresent) {
        this.loading.presentSkeleton();
        this.isSkeletonPresent = false;
      }
      this.loading.dismissLoading();
      this.dialogRef.close(true);
    } else {
      this.loading.dismissLoading();
      this.closeModal();
    }
  }
}