import { Injectable } from '@angular/core';
import { UserManagerService } from '@providers/user-manager/user-manager.service';

@Injectable({
  providedIn: 'root'
})
export class RolesManagmentService {

  constructor(private userService: UserManagerService) { }

  public get onlyListRole(): boolean {
    if (this.userService?.role) {
      const { role } = this.userService;
      return role === 2 || role === 5;
    }
    return false;
  }

  public get onlyMechanicList(): boolean {
    if (this.userService?.role) {
      const { role } = this.userService;
      return role === 3;
    }
    return false;
  }

  public get onlyMonitoring(): boolean {
    if (this.userService?.role) {
      const { role } = this.userService;
      if (role === 2) {
        return true;
      }
    }
    return false;
  }

  public get onlySalesAndTickets(): boolean {
    if (this.userService?.role) {
      const { role } = this.userService;
      return role !== 1;
    }
    return false;
  }
}
