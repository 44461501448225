export const enLocale = {
  language: 'en',
  data: {
    actions: {
      off: 'Turn off',
      on: 'Turn on',
      off_on: 'Off/On',
      open: 'Open',
      action: 'Action',
      assign: 'Assign',
      reassign: 'Reassign',
      noAction: 'Action not allowed',
      update_count: 'Update count',
      updateSave: 'Update and save',
      active_deactive: 'Activate/Inactivate',
      change: 'Change',
      change_active: 'Change and activate',
      active: 'Active',
      modify: 'Modify',
      mod_data: 'Modify data',
      inv_Form: 'Invalid format',
      search: 'Search',
      continue: 'Continue',
      send: 'Send',
      sendCode: 'Send code',
      select_all: 'Select all',
      select_allnt: 'Deselect all',
      add: 'Add',
      addClose: 'Add / Close',
      apply: 'Apply',
      accept: 'Accept',
      load: 'Load',
      generate: 'Generate',
      not_apply: 'Do not apply',
      save: 'Save',
      save_ch: 'Save changes',
      next: 'Next',
      previous: 'Previous',
      colect: 'collective',
      direct: 'Direct',
      newPlace: 'New place',
      close: 'Close',
      cancel: 'Cancel',
      understood: 'Get it',
      solicit: 'Solicit',
      edit: 'Edit',
      verify: 'Verify',
      view: 'view',
      clean: 'Clean',
      delete: 'Delete',
      assigning: 'By assigning',
      way_sel: 'Select via',
      seat_sel: 'Select seats',
      donwload: 'Proceed with download',
      all: 'All',
      send_Ag: 'Resend code',
      valid: 'Validate',
      verify_asoc: 'Verify and associate',
      send_sol: 'Send request',
      send_report: 'Send report',
      return: 'Return',
      save_config: 'Save configuration',
      sync: 'Synchronize',
      move: 'Move',
      mod_role: 'Modify role',
      sendCodeVerify: 'Send verification code',
      recover: 'Recover',
      create: 'Create',
      select: 'Select',
      authorize: 'Authorize',
      receive: 'Receive',
      cancelTicket: 'Cancel Ticket',
      exitNoSaving: 'Exit without saving',
      exitSaving: 'Save and exit',
      modifyTime: 'Modify time',
      modifyMarker: 'Modify marker',
      modifyExit: 'Modify/Exit',
      preSave: 'Presave',
      getPaid: 'Get paid',
      addSelection: 'Pre-assign selection',
      saveAssign: 'Save and assign',
      update: 'Update',
      addRow: 'Add row',
      decline: 'Decline'
    },
    alerts: {
      passerErrorMessage: 'Sorry, something has happened and it is not possible to process the request, please try again',
      errDTST: 'Connection problems',
      errDTSD: 'Sorry, it seems that there was an Internet micro cut. Close this window and try again, if the error persists contact us.',
      error_timeoutT: 'No response',
      error_timeoutD: 'It seems that something is wrong and there is no response from the server. Our apologies. Please wait and try in a few minutes.',
      error_maintenanceT: 'Under maintenance',
      error_maintenanceD: 'Our apologies, the service is under maintenance. It will be restored as soon as possible. Please try in a few minutes.',
      title_err: 'An error has occurred',
      requ_data: 'Required data',
      log0: 'Authentication failed. User not found.',
      log2: 'Authentication failed. User with pending account activation.',
      log3: 'Authentication failed. User with pending password reset.',
      log4: 'Incorrect code.',
      log5: 'You must enter the email address of your passer account to send you a link in which you can modify your password.',
      log6: 'Check your email. We have sent you a link in which you can recover your password.',
      req_code: 'Required code',
      tickets_det: 'You must type: Date range, Customer name or Ticket code.',
      tickets_fields: 'You must select all fields (Origin, Destination, Way, Schedule, Date), before you can assign seats.',
      tick_phone: 'There is no user with this phone.',
      tick: 'You must select at least one seat or indicate the amount of standing tickets to sell.',
      pric: 'There was an error loading the price of the trip. Try entering the data again.',
      journey_sched: 'The selected route does not have working hours. Try another journey or way.',
      prefT: 'Preferential seat',
      prefD1: 'The seat number ',
      prefD2: ' is preferred so remember that if a person with special needs requires it you will have to choose another.',
      prefStanding: 'This seat is preferred so remember that if a person with special needs requires it you will have to choose another.',
      seat_busy: 'The selected space is occupied.',
      noMoreTime: 'Time out',
      noMoreTimeText: 'Sorry, 5 minutes have passed and your seats have been released',
      seats_discT: 'There was a problem',
      seats_discD: 'The connection to the socket server failed. Check your internet connection or try again later.',
      ficha: 'Information updated on ',
      perm: 'You must select an option',
      perm2: 'Yout must select at least one option.',
      create_t: 'Not available',
      create_d: 'This part of the system is still in development.',
      role_tik: 'You can not perform this action because the item you are trying to delete is associated with an operational role or has already sold tickets.',
      permDelT: 'Denied action',
      permDelD: 'The user you are trying to remove is the only Super Administrator and you must have at least one Super Administrator in your company.',
      permChD: 'El usuario al que tratas de cambiar su tipo de acceso es el único Superadministrador y debes tener al menos un Superadministrador en tu compañía.',
      email_dupl: 'This user already exists in the Passer sYstem.',
      noAccT: 'No access',
      noAccD: 'You are not assigned to any office, please contact your administrator.',
      noAccTS: 'Closed office',
      noAccDS: 'You are not in office hours, please try when the office is open according to your schedule.',
      suspendedAccount: 'This account is suspended. Please contact your account manager.',
      send_t: 'Code sent',
      hasNoPaymentsTitle: 'WARNING - ECOMMERCE CREDENTIALS',
      hasNoPaymentsDescription: 'This subscription is of the <b>{{type}}</b> type and ecommerce credentials have not yet been assigned for online sales processing. Please correct or you will not be able to sell your services online.',
      logOutTitle: 'Logout',
      logOutDescription: 'You have made 3 unsuccessful attempts, for security reasons the session closed and you will have to log in again.',
      subscriptionBase: 'This action is not allowed for your subscription type. Increase your plan to activate this feature.',
      dateRange: 'You must select a start and end date.',
      imageSize: 'Maximum upload size is 10 MB',
      multipleFiles: 'Cannot use multiple files',
      anotherSessionTitle: 'Multiple sessions',
      anotherSessionDescription: 'We detected that you have several sessions open, we will close this session and you will continue with the last one you logged in with.',
      updateTitle: 'IMPORTANT UPDATE',
      updateDescription: 'We noticed that you have a somewhat old version. We invite you to please update to continue using the system. The new versions bring many performance, stability and aesthetic improvements.',
      ERRORS: {
        MINLENGTH: 'The minimum length for this field is',
        MAXLENGTH: 'The maximum length for this field is',
        MIN: 'The minimum amount for this field is',
        MAX: 'The maximum amount for this field is',
        REQUIRED: 'This field is required.',
        CHARS: 'characters',
        INVALID_FORMAT: 'INVALID FORMAT',
        PASSWORD_VALID: 'The password must have 1 uppercase, 1 lowercase and a number',
        PASSWORD_MINLENGTH: 'The password must have a minimum of 8 characters',
        ERROR_CONFIRMPASSWORD: 'Passwords do not match.'
      },
      code: {
        fileT: 'Too large files',
        fileD: 'It seems that you are saving files with too large sizes. Please try to upload them again, upload less heavy files or contact the Passer Enterprises service.',
        copnyData2: 'You must enter all the data requested previously of the company. Check that the legal card has at least 10 characters.',
        copnyError: 'You must select at least one company',
        canT: 'You must enter all the corresponding data of the routes and stretches added.',
        canVD: 'You can not enter equal plates.',
        canVD2: 'You can not enter the same numbers of units.',
        dup_term: 'You can only enter your terms once a day.',
        empty_oficial: 'You must select an origin and destination before adding a regulated sales route.',
        rep_oficial: 'You have already added this route to the regulated sale list.',
        selected_oficial: 'You must select one of the options from the list of regulated sales routes.',
        origin_first: 'The start must be a leg before the end.',
        err_buss: 'Error loading entity type catalog.'
      },
      modal: {
        titS: "Successful action",
        titE: "Unknown error",
        msgS: "Changes or adjustments have been generated and / or saved successfully.",
        msgE: "Something is wrong, we feel it. Please try again, if the problem persists use the assistance chat or call 4001-8280.",
        msgErr: "Something is wrong, we feel it. Please try again, if the problem persists, contact the Passer Enterprises service.",
        no_susc_add: "Your passer account does not have a subscription added. Please contact the Enterprises service.",
        no_susc_act: "Your passer account does not have an active subscription. Please contact the Enterprises service.",
        credentialsTitle: "VERIFY IDENTITY",
        credentialsDescription: "For security, please re-enter your Passer account password to keep your session active",
        tiq_perm_term: {
          tit_tmOut: 'Timeout',
          desc_tmOut: 'Sorry, more than 5 minutes passed and the seats you had selected were released so that other users could buy them. Please try again.',
          desc_tmOutM: 'You can not have less than 0 standing tickets, nor more than the maximum allowed.'
        }
      },
      office: {
        newOff: 'You must type and select the respective data (Name - Country - Province / State - Canton / City - Address - Telephone) of the new office.',
        data_sche: 'You must enter an opening time, a closing time and select at least one day of the week.',
        days_err: 'There seems to be a scheduling conflict on two or more days. Please review and correct.',
        range_err: 'One of the schedules does not comply with the allowed format. The closing time must be at least one hour greater than the entry time.'
      },
      employee: {
        4: 'You must type all the information requested (Entry - License type - Expires - Points record).',
        ficha1: 'You must type all the required information (Name, Surname, ID Type, Identification, Country, Address, Country code, Phone, Email, Entry).',
        ficha2: 'You must type all required licenses information (Type, EXpiration).',
        modal: {
          lic: 'You must select a type of license and its expiration date.'
        },
        empl_inac: {
          title: 'Inactivate driver',
          desc_inacPre: 'If you put this driver in an inactive state, it can not be taken into account for the service roles. Do you want to continue?'
        },
        empl_del: {
          title: 'Remove driver',
          desc_delPre: 'Do you really want to delete this driver? If you do, you can no longer be assigned to roles nor will there be records of your activity. If you prefer, you can inactivate it.'
        }
      },
      unit: {
        seat1: 'You can not position one seat over another.',
        seat2: 'You can not add a seat in the hall.',
        seat3: 'This seat is already assigned on the bus, try to move the one inside the bus.',
        seat4: 'The seat you are trying to add is already inside the bus.',
        plate: 'The digitized plate already exists. Please type another one.',
        spacesError: 'Number of spaces less than allowed, please check.',
        alert33: 'The maximum number of columns is 5, adding the left and right. Keep in mind that only one of the two columns can reach 3.',
        data4: 'You must type at least 4 and maximum 10 different numbers for the preferred seats.',
        not_delLast: 'You must keep at least one vehicle in the system. If you wish to cancel your account contact the support center Enterprises.',
        del: 'When modifying/eliminating this model all the assigned plates will be unlinked, so you will have to assign them again. Do you want to continue?',
        mod: 'When modifying this model, take into account that the changes will be made to all assigned vehicles. Do you want to continue?',
        exist: 'The model with the name ',
        exist2: ' already exists, please try again, but entering another name for the model you want to create.',
        delM: 'The model you are trying to eliminate has assigned vehicles, go consult and delete or reassign the vehicles of that model until there is none left and so you can eliminate this model.',
        unit_func: {
          tit_rem: 'Remove from circulation',
          desc_rem1: 'You are about to remove the vehicle from circulation ',
          desc_rem2: '. If you do it, you will not be able to assign it to the operation roles.',
          tit_del: 'Remove vehicle',
          desc_del1: 'You are about to delete the vehicle ',
          desc_del2: '. Remember that removing a vehicle does not imply the removal of its respective reader.'
        }
      },
      no_route: {
        addStrTitle: 'Add stretches',
        addStrDescription: 'You must add all the stretches along the route.',
        capacityT: 'Add capacity',
        capacityD: 'You must add the capacity for each stretch of the route.',
        no_jourT: 'Data entry error',
        no_jourD: 'You must enter a start and end point for the journey you want to create / modify.',
        tit_click1: 'Drag marker',
        desc_click1: 'You have already added both points (Origin - Destination), to modify the route you just have to drag the desired point.',
        ch_routeT: 'Necessary vehicle',
        ch_routeD: 'You can not change the status of the Journey, because you do not have at least one vehicle created.',
        desc_back: 'If you return you will lose the changes made. Are you sure you want to continue?',
        route_list: {
          tit_in: 'Inactivate route',
          desc_inPre: 'You are about to inactivate a journey. If you continue, this can not be taken into account for the operation roles. Do you want to continue?'
        },
        route_del: {
          desc_delPre: 'Do you really want to delete this route? It will also lose all information of other modules that are associated.'
        }
      },
      profile: {
        modalCh:{
          email: 'Invalid email format',
          logOut: 'Changes or adjustments have been generated and / or saved successfully. Now you must log in again to be able to use all the features of the site normally.'
        }
      },
      stop: {
        1: 'You must enter a point on the map to continue.',
        4: 'Changes of the stop that is being modified have not yet been saved.',
        5: 'You must select one of the stops before you can delete/modify it.',
        6: 'You can not delete/modify the first two stops, due to they represent the origin and destination points respectively.'
      },
      stretch: {
        1: 'There is no stretches to assign. You have already created all the stretches for this journey. If not, please check that stretches have been created in the canons section or that an error has not been generated at the time of obtaining the stretches.',
        2: 'You must type the hour and minutes of the stretch. The hours and minutes must be greater than or equal to 0, the minutes less than 60. If you enter 0 hours, you must type at least one minute.',
        3: 'Changes of the stretch that is being modified have not yet been saved.',
        4: 'You must select an \"Origin-Destination\".',
        5: 'You can not move the marker before or after an marker earlier or next to it respectively.',
        7: 'You must select one of the stretches before you can delete/modify it.',
        8: 'You have reached the last stretch of the route, you should not place the marker on the map, just enter the travel time information and press \"Add\".',
        10: 'There are no more stretches to add.',
        11: 'You must enter a travel time shorter or equal than the next stretch.',
        12: 'You must enter a travel time longer or equal than the previous stretch.',
        noAddMarker: 'You have already added a marker, to modify the place you just have to drag the added marker.',
        lastMarker: 'You cannot move the last marker, if you want to perform this action you must go to the \"PARENT JOURNEY\" tab and move the end marker of the parent journey'
      },
      roles: {
        1: 'This driver already has a previous assigned role that prevents assigning this new role. Be aware that there is at least 1 hour difference between the start time of the new role and the closing time of the previous role.',
        2: 'Click here to see the criteria for creating roles.',
        3: 'Please select a date and time to continue the process.',
        4: 'The driver does not have availability during this time.',
        5: 'You must select a driver and unit.',
        6: 'The journey and via selected do not have work schedules.',
        7: 'Before viewing the card of the Driver, you must select a driver.',
        8: 'The journey and via selected do not have working hours for the remainder of today.',
        9: 'An error has occurred. Check that the departure date is less than the closing date. In case of having the same dates, check that the departure time is less than the closing time.',
        10: 'If you want to make this role recurring, you must add an end date.',
        dup_roleT: 'Role duplicate',
        dup_roleD: 'There is already a role with these same parameters, if you want to add an extra, create a new schedule and then try again.',
        tit_newR: 'Role successful',
        desc_newR1: 'The role has been created successfully for ',
        desc_newR2: ' in unity ',
        desc_newR3: ', for the journey ',
        remove2: 'You must select a new driver to perform driver removal.',
        platCant: 'Be sure to select a new plate and enter the number of transfers to proceed.',
        no_move: 'You cannot remove the vehicle because the role is already on progress. What you can do is make a transfer.',
        no_delete: 'You cannot remove the vehicle because the role is already on progress.',
        no_transf: 'In order to transfer, the role status must be \"In progress\".',
        role_no_active: 'The selected role is not active.',
        roleTemp: 'This action is not allowed for temporary roles. In order to perform these actions, you must first create the role in the <b>NEW ROLE</b> section',
        restartTrip: 'You can only restart a trip whose role is in progress',
        move:{
          4: 'You must select a new unit to carry out the driver transfer.'
        }
      }
    },
    copny:{
      sel_photo: 'You must upload an image before you can change it. If you want to exit the modal without making changes, press the \"Close\" button',
      alerts: {
        asoCop_no: 'No associated companies were found.',
        asoCop_err: 'Error loading associated companies.',
        empty_terms: 'You must fill in all the fields of the terms and conditions of your service.',
        shipmentLimitError: 'Could not get the upload configuration.',
        packageTypeError: 'Could not obtain the list of packages with their respective rates.',
        weightError: 'Could not get weight configuration.',
        contactError: 'Could not get support contacts.'
      },
      companyData: {
        imageWall: 'UPLOAD THE COVER OF YOUR COMPANY',
        infoCompanyTitle: 'COMPANY INFORMATION',
        infoCompanyDescription: 'Add the company information that will be related to this subscription.',
        legalRepTitle: 'LEGAL REPRESENTATIVE',
        legalRepDescription: 'Add the information of the physical person who represents the company.',
        loadCrop: 'Load and crop your company logo',
        loadCropWall: 'Load and crop yourcompany cover',
        twoRepresentatives: 'You can only add two representatives',
        representativeEmpty: 'You must enter at least one representative.'
      },
      headers: {
        price: "Rate",
        packageType: "Package Type",
        position: "Position"
      },
      termsData: {
        hasNoPolicies: 'I do not have my own policies, use the general policies of Passer Enterprises',
        termTitle: 'TERMS AND CONDITIONS OF YOUR SERVICE',
        termDescription: 'This information will be reflected on the Passer website so that users can consult it and learn about your use policies.',
        changeReemb: 'Policies on exchanges and refunds',
        scheduleService: 'Policies on schedules and provision of services',
        userAttention: 'User service policies',
        otherService: 'Other service considerations'
      },
      tabs: {
        profile: "Company Profile",
        config: "Configuration"
      },
      configuration: {
        title: 'Conditions',
        subTitle: 'Sales conditions',
        desc: 'Use the parameters to define your online sales conditions, operation and other important terms that users should know. What you tell us here will be considered as part of your commercial policies.',
        acceptConditions: 'I understand and accept that these conditions of sale will be part of my service policies, which will be displayed to the end user before each purchase and that Passer will apply them as provided herein.',
        params: "The billing parameters determine the way in which the calculations will be made according to the measurement model selected for each item, as well as the legal form with which you will carry out the billing",
        tabList: [
          { action: 'tickets', name: 'Tickets' },
          { action: 'logistic', name: 'Logistic' },
          { action: 'busApp', name: 'BUS APP' },
          { action: 'support', name: 'Support' }
        ],
        tabLogistic: [
          { action: 'weight', name: 'WEIGHT' },
          { action: 'costs', name: 'TARIFY' },
          { action: 'charge', name: 'CHARGE' },
        ],
        tickets: {
          title: 'OPERATION POLICIES',
          desc: 'The operation attributes allow you to define rules and characteristics based on what is most feasible for your business. This information will be presented to the end user for their information.',
          boarding_way: 'ALLOW HIGHWAY BOARDING?',
          sale_after: 'ALLOW PURCHASES AFTER DEPARTURE TIME?',
          max_time: 'Maximum time for purchase from app',
          maxTimeDES: 'Maximum time for sale from physical box office',
          changes: 'ADMIT CHANGES?',
          max_departure_time: 'Maximum time before departure',
          max_arrival_time: 'Maximum time after departure',
          max_changes: 'Maximum allowed changes',
          max_value: 'Maximum allowed value',
          maxTimeValid: 'Expiration',
          availabilityBuyTitle: 'AVAILABILITY TO BUY',
          availabilityBuyDescription: 'Maximum time with which you allow to buy in advance',
          authorizationTitle: 'AUTHORIZATION FOR DISCOUNTS',
          authorizationDescription: 'Indicate whether or not you want sellers to be authorized by a subscription administrator to be able to make discounts.',
          noAuth: 'No authorization required',
          yesAuth: 'Requires authorization',
          timeBeforeCancellations: 'Maximum time before departure',
          timeAfterCancellations: 'Maximum time after departure',
          onlyOriginal: 'Only in original place',
          anyPlace: 'Any place',
          cancellationAllow: 'ALLOW CANCELLATIONS?',
          allowOnRoad: 'ALLOW ROAD APPROACHES?',
          printCopy: 'PRINT A COPY OF THE TICKETS?',
          refundTitle: 'DIGITAL REFUNDS',
          refundAlert: 'Digital refunds are those for tickets purchased through the Passer app for end users.'
        },
        busApp : {
          title: 'Driver App Feature Settings',
          description: 'Use these settings to enable or disable features for your drivers.',
          reversionTitle: 'CHARGE REVERSALS',
          reversionDescription: 'The reversion feature allows drivers to reverse a charge made during their route (not tickets). Reversals are deducted from their take',
          ticketOnRoadTitle: 'TICKETS ON ROAD',
          ticketOnRoadDescription : 'This feature enables the driver to sell tickets from their app.',
          enable: 'Enable',
          disable: 'Disable',
          notificationTitle: 'TRIP COMPLETED NOTIFICATIONS',
          notificationDescription: 'Do you want subscription administrators or role managers to receive an email every time the driver completes a trip?',
          allUsers: 'All administrators and enrollers/operators',
          onlySomeUsers: 'Only some administrators and enrollers'
        },
        instructions: {
          title: "GENERAL INSTRUCTIONS",
          desc: "Important policies, criteria or topics that you want us to apply and take into consideration in relation to your operation",
          specials: "Describe the instructions"
        },
        support: {
          title: "Instructions for the Passer support service",
          description: "Please let us know relevant information that you would like our customer service and support staff to take into account and apply when addressing incidents."
        },
        contacts: {
          title: "CONTACTS",
          description: "People, phone numbers and emails that Passer support staff can contact in case of emergencies or questions."
        },
        billing: {
          title: "BILLING",
          legalTitle: "LEGAL FIGURE",
          legalDescription: "Use the legal ID of this Enterprises subscription",
          consultTributation: "Consult taxation",
          comercialName: "Business name",
          legalName: "Legal name",
          typeOfTaxPayer: "Taxpayer type:",
          economicActivity: "Economic activity:",
          electronicBillingTitle: "ELECTRONIC RECEIPTS",
          electronicBillingTDescription: "Issue electronic receipts?",
          remainderBilling: "The consulted tax information will be used",
          agency: "Agency",
          indexFact: "Consecutive billing",
          atvUser: "ATV User",
          atvPass: "ATV Password",
          pin: "pin",
          emitCredit: "Issue credit notes?",
          reminderCredit: "A credit note will be considered when you cancel all or part of the charge made for a shipping service.",
          cancelSend: "CANCELLATION OF SHIPMENTS",
          cancelSendTime: "Only before shipping time",
          canceSendNotime: "After shipping time",
          maxTimeCancel: "Maximum time for cancellation",
        },
        weight: {
          title: "VOLUMETRY",
          description: "Charging by volumetric weight is ideal when the item has a relatively low weight but a disproportionate space occupation to said weight. The standard conversion factor usually ranges between 4000 and 6000.",
          volumeFactor: "Volumetric factor",
          pricekg: "Rate per kilo",
          priceLib: "Rate per pound",
          fixWeight: "FIXED WEIGHT",
          fixWeightDesciption: "Defines the charging rate for each kilo or pound of weight."
        },
        costs: {
          title: "PARCELS",
          description: "The fixed charge for packages is ideal if you already have a standard rate for different types of packages regardless of their size and weight.",
          packageName: "Package name",
          price: "Rate"
        },
        charge: {
          description: "The charge value defines the maximum amount of shipments (regardless of weight and measurements) that can be loaded on each vehicle.",
          unlimitedShipments: "Unlimited shipments",
          maxShipments: "Maximum shipments"
        }
      }
    },
    dashboard:{
      title: 'MONITORING AND SUPPORT',
      unit_search_input: 'Enter the number of unit',
      act_speed: 'Current speed',
      pass: 'Passengers',
      now_board: 'On board at this moment',
      day_board: 'Transported during the day',
      ret_role: 'Remove from the role',
      remove: 'Remove',
      alerts: {
        no_unit: 'The unit is not found. Try selecting another of the options given.',
        number_unit: 'You must enter the unit number before performing the search.',
        no_infoBus: 'Could not get selected bus information.',
        err_route: 'Error loading route.',
        err_info: 'Error loading data.',
        errorRestriction: 'Error loading restrictions.',
        dateEmptyTitle: 'Unassigned time',
        dateEmptyDescription: 'You have not created the first time condition yet.',
        warningDeleteTitle: 'Delete Restriction',
        warningUpdateTitle: 'Change restriction status',
        warningDeleteDescription: 'You are about to delete a restriction. Do you want to continue?',
        warningUpdateDescription: 'You are about to change the status of the restriction. Do you want to continue?',
        secondRestrictionTitle: 'Time required',
        secondRestrictionDescription: 'The second time condition is required. If you do not want to add time conditions, clear the first condition.'
      },
      monitoring: {
        title: 'Monitoring',
        trace: 'Trace',
        taps: 'Electronic payment taps',
        tickets: 'Onboard tickets',
        arrival: 'Approximate arrival',
        back: 'Return',
        total: 'Total collection',
        outService: 'Out of service',
        comunication: 'COMUNICATION',
        sendNotification: 'Send a notification to this vehicle',
        updateValidator: 'Validator status has been successfully updated',
        warningTitle: 'REMOVE VALIDATOR FROM SERVICE',
        warningDescription: 'You are about to take out of service the validator for electronic payment and verification of tickets for vehicle {número}, plate {placa}. Do you want to continue?',
        modalTitle: 'SEND MESSAGE TO VEHICLE',
        modalDescription: 'A push notification and message will be sent to the app inbox of the driver of vehicle number {{numero}}'
      },
      restrictions: {
        title: 'Restrictions',
        subTitle: 'Online sales restrictions',
        description: 'Restrictions are temporary actions that you can define to control the online sale of a certain route, stretch or times, but without having to completely disable the services. A practical example of this is when due to weather circumstances or other factors a service cannot cross certain stretches in a range of hours, perhaps due to landslides, floods or blockages.',
        tabs: [
          {
            name: 'RESTRICTIONS',
            action: "restrictionList"
          },
          {
            name: 'NEW RESTRICTION',
            action: "newRestriction"
          }
        ],
        restrictionLabel: 'Restriction label',
        menuRestriction: [
          { icon: 'power-on', name: 'Activate/Deactivate'},
          { icon: 'delete', name: 'Delete'},
          { icon: 'notifications', name: 'Notify users'}
        ],
        emptyListTitle: 'No restrictions created',
        emptyListDescription: 'You have not created restrictions yet. Tap the \"NEW RESTRICTION\" button to start creating restrictions.'
      },
      newRestriction: {
        newRestriction: 'NEW RESTRICTION',
        statusRestriction: 'Status of the restriction',
        inactive: 'Inactive',
        active: 'Active',
        if: 'If',
        is: 'Is',
        to: 'TO',
        criterion: 'Criterion',
        optionalConditions: 'OPTIONAL CONDITIONS',
        isEqual: 'Equal to',
        value: 'Value',
        and: 'and',
        condition: 'Condition',
        conditionPlusList: [
          { name: 'Greater than', value: '>' },
          { name: 'Greater/Equal To', value: '>=' }
        ],
        conditionLessList: [
          { name: 'Less than', value: '<' },
          { name: 'Less than/Equal to', value: '<=' }
        ]
      },
      notifications: {
        title: 'Notifications',
        description: 'Notifications are short messages that you can send to users of your services to inform them about a particular topic of relevance. People will be able to see the message on their home screen, notification bar, and Passer app inbox. This function greatly helps to improve the quality and user experience of your services, since you help people in a timely manner and avoid inconveniences. Notification fee.',
        tabs: [
          {
            name: 'NEW',
            action: 1
          },
          {
            name: 'RECORD',
            action: 2
          }
        ],
        newNotification: {
          descriptionNoti: 'NOTIFICATION DESCRIPTION',
          activeServices: 'ACTIVE SERVICES',
          activeServicesDescription: 'You can send this notification to all services that have tickets sold.',
          message: 'Message (max 350 characters)',
          redirectTitle: 'REDIRECTION',
          redirectDescription: 'Where will users go when opening the notification?',
          inbox: 'Passer app inbox',
          external: 'External Site',
          specificDate: 'Send this notification on a specific date and time',
          individualTitle: 'INDIVIDUAL USER',
          individualDescription: 'Enter phone number, including country code',
          massive: 'MASSIVE',
          massiveDescription: 'Select a vehicle or role date',
          alerts: {
            noRoleTitle: 'Role not selected',
            noRoleMessaege: 'You must select at least one role to be able to continue sending the notification.',
            newOperatorNotification: 'New operator notification',
            userError: 'Error loading user.',
            OperatorNotificationeError: 'Error loading Notifications.',
            OperatorNotificationsErrorToSave: 'Failed to save notification'
          }
        }
      },
      recordNotification: {
        all: 'All',
        onlyProg: 'Only scheduled',
        menu: [
          { icon: 'delete', name: 'Delete' },
          { icon: 'eye', name: 'View' }
        ],
        emptyListTitle: 'No notifications created',
        emptyListDescription: 'You have not created Notifications yet. Tap the \"NEW\" button to start creating notifications.'
      },
      alertsNotifications: {
        title: 'Alerts',
        description: 'Allows users to learn about incidents or situations on the route, for example, landslides or accidents, that could affect services.',
        journeyTag: 'Parent journey or tag',
        footerRoute: 'Click the route on the map to add a marker. People will be able to see this in their Passer app.',
        situation: 'Incident or situation',
        range: 'Range of the alert',
        message: 'Message (optional)'
      },
      viewNotification: {
        scheduledTitle: 'SCHEDULED NOTIFICATION',
        scheduledDescription: 'The following message will be sent on date {{fecha}} at {{hora}}',
        notification: 'NOTIFICATION',
        notifyTitle: 'NOTIFY USERS',
        notifyDescription: 'A notification will be sent alerting all users using the Passer app'
      }
    },
    datetime: {
      date_role: 'Role date',
      scheduleInitEnd: 'Start and end times',
      saleDate: 'Sale date',
      settingDate: 'Setting date',
      date_range: 'Date range',
      filter_date: 'Filter date',
      date: 'Date',
      dates: 'Dates',
      schedule: 'Schedule',
      schedules: 'Schedules',
      manage_date: 'Disable dates',
      selectDates: "Select dates ",
      initDate: 'Start Date',
      openingTime: 'Opening time',
      closingTime: 'Closing time',
      initHour: 'Start time',
      endDate: 'End Date',
      endHour: 'End Hour',
      dateInitEnd: 'Start and end date',
      birthday: 'Birhtday',
      date_ini: 'Departure date',
      date_buy: 'Purchase date',
      dateHour_buy: 'Purchase date/hour',
      date_close: 'Closing date',
      date_ing: 'Income date',
      dateFin: 'End date',
      date_fin_rec: 'Recurrence end date',
      hour_ini: 'Departure hour',
      dateHourDeparture: 'Date/hour departure',
      departureParentHour: 'Parent departure time',
      hour_arrival: 'Arrival hour',
      dateHourArrival: 'Date/hour arrival',
      hour_close: 'Approximate closing time',
      boarding_time: 'Boarding Time (Approx',
      datetimeRole: 'Role date/hour',
      'date/hour_boarding': 'Boarding date/hour',
      hour_boarding: 'Boarding hour',
      sendDate: 'Send date',
      sendDateHour: 'Send date/hour',
      deliveryDateHour: 'Delivery date/hour',
      deliveryDate: 'Delivery date',
      deliveryHour: 'Delivery hour',
      arrivalDate: 'Arrival date',
      datetime: 'Date and hour',
      range_role: 'Date range',
      out: 'Departure',
      finish: 'Arrival',
      day: 'Day',
      hour: 'Hour',
      hourUpper: 'HOUR',
      hours: 'Hours',
      minutes: 'Minutes',
      year: 'Year',
      years: 'year(s)',
      months: 'month(es)',
      'month/year': 'Month / Year',
      days: 'day(s)',
      daysT: 'Days',
      hoursMin: 'hr(s)',
      minutesMin: 'min(s)',
      searchDate: 'Search date',
      requestDate: 'Request date',
      requestDateHour: 'Request date/hour',
      serviceDateHour: 'Service date/hour',
      sig_Mon: {
        1: 'Mo',
        2: 'Tu',
        3: 'We',
        4: 'Th',
        5: 'Fr',
        6: 'Sa',
        7: 'Su',
        L: 'Mon',
        M: 'Tue',
        I: 'Wed',
        J: 'Thu',
        V: 'Fri',
        S: 'Sat',
        D: 'Sun'
      },
      full_month: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December'
      }
    },
    descriptions: {
      no_results: 'There are no records according to the search parameters.',
      no_parameters: 'You have not entered any search parameters.',
      photo: {
        titleS: 'Load and crip your company logo',
        titleC: 'Load and trim bill logo',
        logo_load: 'Load logo',
        img_load: 'Load image'
      }
    },
    employees: {
      drivers: 'Drivers',
      driver_list: {
        title: 'Drivers list',
        active_role: 'Active role',
        no_employees: 'No registered drivers',
        no_employeesD: 'You have not created drivers yet. Go to the \"New driver\" tab to start registering new drivers.',
        reset: 'Reset password',
        send_noti: 'Send notification',
        disableAccessTitle: 'Access for bus driver',
        disableAccessDescription: 'You are about to remove access to the Passer Bus Driver App for this driver <b>{{name}}</b>. The driver will be able to Do you want to continue?',
        allowReverse: 'Allow charge reversals',
        allowSales: 'Allow route ticket sales',
        menuList: [
          {icon: 'contact', name: 'Profile and reviews'},
          {icon: 'person-remove', name: 'Remove driver'},
          {icon: 'android', name: 'OTP activation Bus Driver'},
          {icon: 'power-on', name: 'Revoke/Grant Bus Driver access'},
          // {icon: 'send', name: 'employees.driver_list.send_noti'},
          {icon: 'power-on', name: 'Enable/Disable driver'}
        ]
      },
      new_driver: {
        title: 'New driver',
        info_lab: 'Labor information',
        address: 'Address and contact',
        actual_points: 'Actual points (optional)',
        statusT: 'Status and app drivers',
        statusD: 'Indicate if you want to add this driver ready for roles (active) or create it without being taken into account for roles (inactive). You can change its status whenever you want.',
        access_bus: 'Grant access to the Bus Driver App',
        add_driver: 'Add driver',
        points: 'points',
        data_licR: 'Required license data (License type - Expires)',
        lengthLimit: 'You can only add one license per driver.',
        duplicateTitle: 'Duplicate License',
        duplicateDescription: 'You cannot add the same type of license twice'
      },
      alerts: {
        empl: 'Error loading drivers.',
        tran_errLic: 'Transaction processed with errors. Error saving licenses ',
        lic_err: 'Error saving licenses ',
        yet_dev: 'This part of the system is still in development.',
        err_phoneT: 'Action denied',
        err_phoneD: 'Driver without added phone. You must go to the card of the driver and add a phone number to which you want to send the SMS.',
        warn_smsT: 'ACCESS FOR BUS DRIVER',
        warn_smsD: 'You will send an access code to ',
        warn_smsD2: '. The driver can use this code to access the Passer Bus Driver App <br>Do you want to send it?',
        role_activeT: 'Active role',
        role_activeD: 'You can not delete driver because he has an active role.',
        soonTitle: 'Coming soon',
        soonDescription: 'Driver feedback and ratings section.'
      },
      table: {
        position: 'Position',
        lic_point: 'License points',
        status: 'Status',
        status_active: 'Active',
        status_inactive: 'Inactive',
        qualification: 'Qualification',
        consultEdit: 'Consult and edit',
        actions: 'Actions'
      },
      forms: {
        lastname1: 'First Lastname',
        lastname2: 'Second Lastname',
        birthday: 'Birthday',
        state: 'State'
      },
      actions: {
        lic: 'Add new license type',
        annot: 'Add annotation',
        ini: 'Start',
        send_noti: 'Write a message, no longer than 100 characters, to send a notification to the Bus Driver App for this driver.'
      },
      personal_info: {
        title: 'Personal data',
        left_data: {
          title: 'General driver information',
          address_contact: 'Address and contact',
          save: 'Save information'
        },
        right_data: {
          title: 'Comments and Ratings',
          desc: 'This is how users have commented and rated this driver',
          val_global: 'Overall rating',
          val_gen: 'Overall ratings by category',
          val_comm: 'Individual comments and ratings'
        }
      },
      labor_info: {
        title: 'Labor data',
        seniority: 'Seniority',
        licenses: 'Licenses',
        lic: 'License',
        gran: 'Awarded',
        pointss: 'Points',
        lost_poi: 'Lost points',
        creat: 'Created by',
        emis: 'Broadcast',
        expire: 'Expire',
        exp: 'Exp',
        points: 'Point registration',
        add_license: 'Add license',
        add_pen: 'Add fine',
        succ: {
          title: 'Satisfactory operation',
          desc: 'The new driver has been successfully added to the system.',
          desc2: 'Now you can assign it to a work role.',
          un_desc: 'Driver',
          un_desc2: 'has been eliminated successfully.',
          un_desc3: 'Remember that you will no longer be available to be assigned to a work role.'
        }
      },
      comments: {
        title: 'Comments',
        comparison: 'Choose 2 drivers to compare their performance with each other',
        drive: 'Driving',
        opinions: 'opinion(s)',
        pru: 'Prudent',
        tem: 'Reckless',
        norm: 'Normal',
        user_serv: 'User service',
        appal: 'Appalling',
        bad: 'Bad',
        reg: 'Regular',
        good: 'Good',
        excel: 'Excellent'
      },
      unsubscribe: {
        title: 'Unsubscribe',
        mot_baj: 'Reason for the low',
        dismi: 'Dismissal',
        resig: 'Renounce',
        ch_post: 'Change of position',
        other: 'Other',
        alert_tit: 'You will unsubscribe this employee',
        alert_desc: 'When you cancel it, it will be completely eliminated from the system, and you will not be able to take it into account for the roles again. If you want to suspend it temporarily, you can use the option permissions.'
      },
      permissions: {
        title: 'Permissions',
        out_rol: 'Remove from role',
        new: 'New permission'
      },
      annotations: {
        title: 'Annotations',
        anot: 'Annotation',
        new: 'New annotation',
        dig_ann: 'Enter your annotation here...'
      },
      penalties: {
        title: 'Penalties'
      }
    },
    footer: {
      login:{
        0: 'Support',
        1: 'Terms of use',
        2: 'Developers'
      }
    },
    header: {
      permissions: 'Access',
      passerAccount: 'Passer account',
      ofice: 'Operation location',
      subscriptionProfile: 'Subscription configuration',
      collectLiquidation: 'Collection and liquidations',
      video: 'Video tutorials',
      valid_pos: 'Validators and POS',
      logWith: 'logged in as:',
      logOut: 'Sign out'
    },
    labels: {
      position: "Position",
      jobPlace: "Place or job position",
      licenseActive: 'Active',
      licenseExpired: 'Expired',
      adjustment: 'Adjustment',
      agent: 'Agent',
      address: 'Address',
      bus: 'Bus',
      warning: 'Warning',
      capacity: 'Capacity',
      warn: 'Warning',
      apt: 'Opening',
      benefactor: 'Beneficiary',
      benefactorMovil: 'Beneficiary number',
      connection: 'Connection',
      disconn: 'Disconnected',
      features: 'Features',
      cirr: 'Closing',
      route: 'Route',
      parentRoute: 'Parent route',
      way: 'Way',
      wayT: 'Way: ',
      unit: 'Unit',
      copny: 'Company',
      ced_fis: 'Physical ID',
      ced_jud: 'Legal ID',
      idMin: 'ID',
      passport: 'Passport',
      origin: 'Origin',
      destination: 'Destination',
      stretchOrigin: 'Stretch origin',
      stretchDestination: 'Stretch destination',
      parentOrigin: 'Parent Origin',
      parentDestination: 'Parent Destination',
      journey: 'Journey',
      ticketRoute: 'Ticket route',
      schedule: 'Schedule',
      gender: 'Gender',
      female: 'Female',
      male: 'Male',
      opt_select: 'Selected options',
      days_filter: 'Filter days',
      copny_filter: 'Filter company',
      perm_filter: 'Filter permission',
      all_selec: 'All selected',
      name: 'Name',
      lastname: 'Surnames',
      ms: 'Message',
      photo: 'Photo',
      place: 'Place',
      code: 'Code',
      driver: 'Driver',
      yes: 'Yes',
      no: 'No',
      phone: 'Phone',
      movil_phone: 'mobile phone',
      print: 'Print',
      reprint: 'Reprint',
      number: 'Number',
      email: 'Email',
      pass: 'Password',
      user: 'User',
      type_id: 'ID type',
      id: 'Identification',
      tel_assig: 'Assigned telephone',
      active: 'Act',
      inactive: 'Ina',
      tick_online: 'With online sale',
      urb_offline: 'No online sale',
      tik: 'Interurban',
      urb: 'Urban',
      train: 'Train',
      window_dig: 'Digital window',
      boarding: 'Boarding',
      stair: 'Stairs',
      entry: 'Entry',
      row: 'Row',
      rows: 'Rows',
      moves: 'Transactions',
      tick_off: 'Electronic',
      tick_on: 'Online',
      reject: 'Rejections',
      pending: 'pending',
      total_rec: 'Total collection',
      retention: 'retention',
      total_pend: 'Total pending',
      total_acre: 'Total accreditation',
      vehicle: 'Vehicle',
      amount: 'Amount',
      taps: 'Taps',
      country: 'Country',
      recurrent: 'Recurrent',
      init: 'Start',
      end: 'Finish',
      country_operation: 'Country of operation',
      country_birth: 'Country of birth',
      entity_type: 'Entity type',
      legal_name: 'Legal name',
      bank_cred: 'Credentials accreditations',
      type_device: 'Device type',
      phone_optional: 'Phone number (optional)',
      address_ex: 'Exact address',
      ing_lab: 'Date of entry to work',
      label: 'Label',
      rates: 'Rates',
      info: 'Information',
      description: 'Description',
      vehicles: 'Vehicles',
      space: 'Space',
      spaces: 'Spaces',
      more: 'more',
      placeType: 'Place type',
      seat: 'Seat',
      seats: 'Seats',
      seatsArray: 'Seats (E.g., 1,3,4)',
      id_ticket: 'ID Ticket',
      stop: 'Bus stop',
      iva: 'IVA',
      percentage: 'Percentage',
      fixed: 'Fixed',
      wh: 'WhatsApp',
      msg: 'Meta Messenger (Facebook)',
      id_booking: 'ID Booking',
      cant: 'Quantity',
      percent: 'Porcent',
      ways: 'Ways',
      terminal: 'Terminal',
      highway: 'Highway',
      rate_base: 'Base rate',
      tradename: 'Tradename',
      service_type: 'Service type',
      ingress: 'Ingress',
      marital_status: 'Marital status',
      type: 'Type',
      state: 'State',
      rate: 'Rate',
      bank_ret: 'Bank retention',
      incomeRetention: 'IVA retention',
      sinpeRetention: 'SINPE-TP retention',
      gatewayRetention: 'Gateway retention',
      passerComission: 'Passer comission',
      gatewayComission: 'Gateway comission',
      expectedLiquidation: 'Expected Liquidation',
      totalSell: 'Total sell',
      gen_price: 'General price: ',
      stretch: 'Stretch',
      model: 'Model',
      brand: 'Brand',
      numberVehicle: 'Vehicle number',
      plate_v: 'Plate',
      plate: 'License plate',
      mechanic: 'Mechanic',
      contact: 'Contact',
      licenseType: 'License type',
      tickets: 'Tickets',
      colones: 'Colones',
      dollars: 'Dollars',
      sex: 'Sex',
      phoneAccount: 'Phone account',
      emailAccount: 'Email account',
      lastConnection: 'Last connection',
      details: 'Details',
      availability: 'Availability',
      role: 'ID Role',
      electronicCollection: 'Electronic collection',
      ecommerce: 'Ecommerce',
      totalSale: 'Gross sales',
      ticketIn: 'Physical ticket office',
      collect: 'Collection',
      debt: 'Debt',
      numberUnid: 'Unit number',
      idValidator: 'Validator ID',
      validator: 'Validator',
      activeToggle: 'Active',
      inactiveToggle: 'Inactive',
      title: 'Title',
      service: 'Service',
      show: 'Show',
      delivered: 'Delivered',
      undelivered: 'Undelivered',
      opened: 'Opened',
      record: 'Record',
      dataNotFound: 'Data not found',
      original: 'Original',
      copy: 'Copy',
      extraReprint: '- Reprint',
      emojiMartLabels: {
        search: 'Search',
        emojilist: 'List of emoji',
        notfound: 'No Emoji Found',
        clear: 'Clear',
        categories: {
          search: 'Search Results',
          recent: 'Frequently Used',
          people: 'Smileys & People',
          nature: 'Animals & Nature',
          foods: 'Food & Drink',
          activity: 'Activity',
          places: 'Travel & Places',
          objects: 'Objects',
          symbols: 'Symbols',
          flags: 'Flags',
          custom: 'Custom'
        },
        skintones: {
          1: 'Default Skin Tone',
          2: 'Light Skin Tone',
          3: 'Medium-Light Skin Tone',
          4: 'Medium Skin Tone',
          5: 'Medium-Dark Skin Tone',
          6: 'Dark Skin Tone'
        }
      },
      verified: 'Verified',
      ticketCount: 'Total tickets',
      shipmentCount: 'Total shipments',
      traceMoney: 'SALES BREAKDOWN BY STRETCH',
      changes: 'Changes',
      location: 'Location',
      locationPlace: 'Place',
      fuel: 'Fuel',
      burden: 'Burden',
      seller: 'Seller',
      other: 'Other',
      cashRegister: 'Cash register',
      distance: 'Distance',
      less: 'Less',
      breakdown: 'Sales breakdown',
      parentJourney: 'Parent journey',
      adults: 'Adults',
      cancellations: 'Cancellations',
      settings: 'Settings',
      configuration: 'Configuration',
      article: 'Article',
      content: 'Content',
      long: 'Long',
      width: 'Width',
      height: 'Height',
      sender: 'Sender',
      receiver: 'Receiver',
      sent: 'Sent',
      toReceive: 'To receive',
      takeOut: 'Take out',
      dimensions: 'Dimensions',
      volumetricWeight: 'Volumetric weight',
      fixedWeight: 'Fixed weight',
      price: 'Price',
      version: 'Version',
      reimburse: 'Reimburse',
      electrinicInvoice: 'Electronic invoice',
      fullName: 'Full name',
      emailAccountPasser: 'Passer account email',
      reason: 'Reason',
      refund: 'Refund',
      madeBy: 'Made by',
      cash: 'Cash',
      transference: 'Transference',
      method: 'Method',
      userDES: 'DES User',
      emailPasser: 'User Passer Email',
      totalTickets: 'Number of tickets'
    },
    language: {
      changeLang: 'Change Language',
      spanish: 'Spanish',
      english: 'English'
    },
    login: {
      titleT: 'Verification of Steps',
      ing_cod: 'Enter the code you have received here',
      sign: 'Sign in',
      forg_pass: 'Did you forget the password?',
      no_account: 'If you still do not have a Passer Enterprises account, register for free so that your company evolves to electronic and digital.',
      register_copny: 'Register my company',
      auth_two: {
        title: 'Two-factor authentication',
        desc: 'Two-factor authentication requires you to enter a verification code that was sent to your Passer account email or phone number.',
        where_send: 'Where do you want to receive the verification code?',
        receive: 'You will receive the code at ',
        receive2: ' terminated in:'
      },
      copny: {
        title: 'Select a company',
        desc: 'Select the company you want to start this session with.',
        suscription_status: {
          1: 'Cancelled',
          2: 'Active',
          3: 'Pending',
          4: 'Suspended'
        }
      },
      alerts: {
        local: 'Sorry, your browser does not support Web Storage',
        em_pass: 'Email and password do not match.',
        data_err: 'Error loading data',
        reset_err: 'Error trying to reset password. Verify that the email entered is active and that is of your passer account.',
        send_err: 'Failed to send code.',
        closeT: 'Closed system',
        closeD: 'For security reasons the system was closed and you will have to logIn again.',
        passerIdpErrorInvalidGrant: 'Login failed; Invalid email or password.',
        passerIdpErrorInvalidManagementGroup: 'Sorry, the logged in user does not have access to this resource, please try another or contact your Enterprises account manager.',
        passerIdpErrorInvalidSubscription: 'Login error; User with no associated subscriptions.'
      },
      recoverPassord: {
        title: 'RECOVER PASSWORD',
        description: 'Enter your Passer account email',
        email: 'Email',
        backLogin: 'Back to login'
      },
      selectSubcription: {
        title: 'SELECT COMPANY',
        description: 'Select the company you want to start this session with',
        displayName: 'Company name:',
        managementGroup: 'Management Group:',
        roleAssignments: 'Role:'
      }
    },
    logistic: {
      title: 'LOGISTIC',
      issuingPlace: 'Issuing place',
      receptorPlace: 'Receptor place',
      weight: 'Weight',
      volumetry: 'Volumetry',
      fixedRate: 'Fixed Rate',
      measurement: 'Measurement',
      kilogram: 'Kilogram',
      pound: 'Pound',
      packageType: 'Package type',
      detailsTitle: 'OTHER SHIPPING DETAILS',
      receiveShipment: 'Receive in place',
      deliverRecipient: 'Deliver recipient',
      receiveFooter: 'Conditions and policies defined by the transport operator apply. For more information, visit www.passer.live/policies',
      alerts: {
        takeOutTitle: 'TAKE OUT OF TRANSPORT',
        takeOutDescription: 'Are you sure you want to remove the shipment from the assigned transport? Please check that the assigned time has not passed yet.',
        takeOutBadState: 'You cannot take out this shipment due to its current state.',
        noCancelShipment: 'You cannot cancel this shipment due to its current status.',
        noPrint: 'You cannot reprint this shipment due to its current status.',
        noDataAssign: 'You must select a role to which you want to assign the shipment',
        assignBadStatus: 'You cannot assign this shipment due to its current status.',
        chargeAlertTitle: 'Attention:',
        chargeAlertDescription: 'Administration has defined a maximum charge of {{count}} shipments per vehicle.'
      },
      newSending: {
        title: 'NEW SENDING',
        detailsTitle: 'SHIPPING DETAILS',
        senderReceiver: 'Sender and receiver',
        senderName: 'Sender name',
        receiverName: 'Receiver name',
        taxClient: 'Tax client',
        idClient: 'Client ID',
        clientNotRegister: 'The entered ID is not registered in the system or does not correspond to a taxpayer, please verify.'
      },
      manageSending: {
        title: 'MANAGE',
        infoTitle: 'SALE INFORMATION',
        total: 'NET TOTAL to pay',
        warningTitle: 'Assign transport',
        warningDescription: 'Remember to assign a search role and vehicle to make the corresponding shipment.',
        noPackageTitle: 'No packages registered',
        noPackageDescription: 'There are no packages registered yet. Please go to the "New Shipment" tab to start registering packages.',
        cancelShipmet: 'Cancel shipment',
        closingTitle: 'MY SALES',
        closingDescription: 'Billing details by sales location.',
        totalShipments: 'Total shipments',
        printClosing: 'Print closing',
        tabOptions: [
          { action: 'manage', name: 'SEND/RECEIVE' },
          { action: 'closing', name: 'CLOSURES' }
        ],
        stateList: [
          { value: 1, displayName: 'In square' },
          { value: 2, displayName: 'Sent/Assigned' },
          { value: 3, displayName: 'Received' },
          { value: 4, displayName: 'Delivered' },
          { value: 5, displayName: 'Cancelled' }
        ],
        menuList: [
          {icon: 'shipping', name: 'Assign'},
          {icon: 'eye', name: 'Details'},
          {icon: 'print', name: 'Reprint'},
          {icon: 'outbox', name: 'Outbox'},
          {icon: 'block', name: 'Unsend'}
        ],
        detailsMenu: [
          {icon: 'outbox', name: 'Receive in place'},
          {icon: 'receivebox', name: 'Deliver recipient'}
        ]
      },
      assignTransport: {
        title: 'ASSIGN TRANSPORTATION',
        description: 'The services for the selected date are shown. Select one and press "Assign"',
        services: 'TRANSPORTATION SERVICES',
        reassignTitle: 'REASSIGN SERVICE',
        reassignDescription: 'You are about to reassign the shipment from vehicle plate {{plate1}} to vehicle plate {{plate2}}. Do you want to continue with the reassignment',
        assignTitle: 'ASSIGN SERVICE',
        assignDescription: 'You are about to assign the shipment to vehicle license plate {{plate}}. Do you want to make the assignment?',
        receivedAccordance: 'Received in accordance'
      },
      cancellationShipment: {
        title: 'CANCEL SHIPPING',
        description: 'You are about to cancel the shipment <b>{{track}}</b> for an amount of <b>{{amount}}</b>. If you wish to continue, confirm your identity and press "Cancel"',
        warning: 'If you cancel this shipment you must make a refund in cash or to the user bank account.'
      },
      closingModal: {
        title: 'WINDOW (SALE PLACE)',
        totalShipments: 'TOTAL SHIPMENTS',
        grossSales: '+ GROSS SALES SHIPPING',
        cancellations: '- CANCELLATIONS',
        netDelivery: 'NET DELIVERY',
        withoutCash: 'Without cash',
        withCash: 'With cash',
        sellerDelivery: 'SELLER DELIVERY',
        missingOther: 'SURPLUS/MISSING',
        signSupervisor: 'Supervisor signature',
        signSeller: 'Seller signature',
        menuList: [
          {icon: 'print', name: 'Print closing'}
        ]
      },
      taxClient: {
        title: 'TAX CLIENT',
        description: 'Add or modify the information of the client for whom electronic receipts should be issued',
        modifyClient: 'Modify or add a client'
      }
    },
    navbar: {
      operations: 'Vehicle monitoring',
      guides: 'SEE SUPPORT GUIDES',
      ticketOffice: 'Ticket office',
      logistic: 'Logistic',
      control: {
        drivers: 'Employees',
        roles: 'Roles'
      },
      maintance: {
        routes: 'Maintenance Routes',
        units: 'Vehicles registration'
      }
    },
    notFound: {
      title: 'SOMETHING STRANGE HAPPENS',
      desc: 'The page you are looking for disappeared',
      come_back: 'Back to begining'
    },
    office: {
      title: 'Operation locations',
      deleteT: 'Delete place',
      deleteD: 'Do you really want to delete the place ',
      searchSeller: 'Search associated staff',
      menuList: [
        {icon: 'edit', name: 'Consult'},
        {icon: 'delete', name: 'Delete'}
      ],
      officeTypeMin: [
        { id: 0, name: 'Highway' },
        { id: 1, name: 'Terminal/Office' },
        { id: 2, name: 'Terminal' }
      ],
      alerts: {
        placeTypeError: 'Error loading office types.',
        off_err: 'Error loading offices.',
        country_err: 'Error loading countries.',
        noVen_err: 'There are not created sellers.',
        creOff_err: 'Error creating office.',
        tran_err: 'Transaction successful, but with several errors.',
        save_err: 'Error saving seller changes.',
        mod_err: 'Error modifying office.',
        empty_officeT: 'No places created',
        empty_officeD: 'You have not yet created places. Go to the \"New\" button to start creating your first place.',
        infoOfficeInvalid: 'Missing data or invalid format in the tab \"INFORMATION OF THE PLACE\"',
        scheduleInvalid: 'Missing data or invalid format in the tab \"SCHEDULES\"'
      },
      modals: {
        new_off: {
          title: 'Operation place',
          tabs: [
            {
              name: 'PLACE INFORMATION',
              action: "placeInformation"
            },
            {
              name: 'SCHEDULE',
              action: "schedules"
            },
            {
              name: 'SELLERS',
              action: "sellers"
            }
          ],
          showEmail: 'Show email in the app',
          info_gen: 'General information',
          notSunday: 'This place is not open on Sundays',
          notSaturday: 'This place is not open on Saturdays',
          scheduleWeek: 'Monday-Friday schedule',
          scheduleSaturday: 'Saturday schedule',
          scheduleSunday: 'Sunday schedule',
          sellerDesc: 'Add sales staff to this place. This will help you to know which site a sale belongs to',
          writeName: 'Write a name...'
        },
        sched: {
          title: 'Hours and days of customer service',
          add_sche: 'add schedule'
        },
        contact: {
          title: 'COMMUNICATION',
          description: 'Allows users to contact you in an agile and effective way, so you can give a more personalized and timely treatment. Users can contact you from the Passer app.',
          copyLink: 'Copy and paste the link',
          whatsapp: 'WhatsApp API link',
          messeger: 'Messenger link'
        }
      }
    },
    paymentSystem: {
      county: 'City',
      state_sel: 'State',
      send_code: 'Send code',
      details: {
        title: 'Role details',
        desc: 'The complete information of the selected role is displayed. If you want to make a reconciliation between passenger counting systems and electronic payment, enter the data and generate the report.',
        desc_pending: 'The complete information of the selected role is displayed. You could make reconciliations once the respective accreditations have been made.',
        ini_sale: 'Start online sale',
        rec_elect: 'Electronic collection',
        total_rec: ' pending',
        com_tp: 'SINPE-TP commission',
        com_passer: 'Passer commission',
        total_acre: ' pending',
        cont_pass: 'Passenger count',
        cont_desc: 'Enter the data from your counting system to reconcile electronic and online collection with cash. If you have made a connection with Passer Connect, the data will be updated automatically.',
        count_brand: 'Number of brands',
        cash: 'Cash',
        ref_acredT: 'Accreditation references',
        ref_acredD: 'The SINPE-TP reference refers to the settlement of funds that the Central Bank of Costa Rica has made for electronic payment collection. The Passer Online reference refers to the accreditation that Passer has made for the concept of online sales collection.',
        date_cor: 'Cut date online sale',
        send_reportT: 'Send report',
        send_reportD: 'Send this information to your accountant or financial department',
        count_online: 'Number of online transactions',
        count_elect: 'Number of electronic transactions'
      },
      alerts: {
        loadCode_err: 'Error loading role codes.',
        rec_err: 'Error loading collections.',
        infoBan_err: 'Error loading bank information.',
        typeId_err: 'Error loading type identification catalog.',
        repErrT: 'Report not available',
        repErrD: 'There is no report for the selected date and role.',
        repErrOneD: 'An error occurred while obtaining the reports with the roles: '
      },
      concil: {
        title: 'Conciliation',
        ref: 'Reference',
        status: {
          1: 'Assigned',
          2: 'Temporary',
          3: 'On road',
          4: 'Finished',
          5: 'Disabled',
          6: 'Deleted'
        }
      },
      moves: {
        view_det: 'View details',
        cant: 'Quantity',
        collect: 'Collection',
        pen: 'Completed pendings',
        acred: 'Accredited'
      }
    },
    roles: {
      new_role: 'New role',
      list_tab: 'Role board',
      role_description: 'Role description',
      idCopied: 'Role id copied successfully!',
      standingAvailable: 'Standing available:',
      searchDriver: 'Search driver',
      alerts: {
        unit_err: 'Error loading units.',
        jour_err: 'Error loading journeys.',
        role_err: 'Error loading roles.',
        err_data: 'An error occurred while loading data.',
        err_routes: 'Error loading routes.',
        drAb_err: 'Error loading enabled drivers.',
        unAb_err: 'Error loading enabled units.',
        unOp_err: 'Error loading operational units.',
        driverChangeTitle: 'Driver Change',
        driverChangeDescription: 'You already have one or more roles pre-assigned with the driver <b>{{driver}}</b>, if you select a different one, the data you already had pre-assigned will be deleted.',
        hourMissingTitle: 'Missing hour',
        hourMissingDescription: 'You must select at least one time to continue.',
        driverMissingTitle: 'Missing driver',
        driverMissingDescription: 'You must select a driver to continue.',
        unitMissingTitle: 'Missing vehicle',
        unitMissingDescription: 'You must select a vehicle to continue.',
        addPreassigned: 'Data preassigned successfully',
        deletePreassignedTitle: 'Delete data',
        deletePreassignedDescription: 'Are you sure you want to delete this data from the preassigned list?',
        deleteRowCollection: 'Collection row deleted successfully.',
        preassignTitle: 'Pre-assign roles',
        preassignDescription: 'You have not pre-assigned any roles yet. To save and assign them, first press Pre-assign Selection”.',
        noSelectedData: 'You have not selected any ticket or collection rows. If you make this change, no transfer marks will be recorded for the receiving driver.',
        deleteTransferTitle: 'DELETE ROW',
        deleteTransferDescription: 'You are about to delete a row. If you do this, the new driver will not be registered for this transfer. Do you wish to continue?'
      },
      new_role_data: {
        title: 'CREATE AND ASSIGN NEW ROLES',
        description: 'Enter all the parameters that make up this role: Route, schedules, drivers and vehicles.',
        infoJourney: 'You are about to create a role for the following service: <b>PARENT JOURNEY:</b> {{journey}} / <b>WAY:< /b> {{way}}',
        onlineSales: 'This route has active online sales from the Passer app',
        planner: 'PLANNER',
        search_label: 'Search for origin, tag or way',
        schedules: 'Date and hours',
        sched_desc: 'Add the date and hours of service for this role.',
        review: 'Check that everything is fine before creating and saving the role.',
        role_rec: 'Make this role recurring',
        title_r: 'Driver and vehicle',
        desc_r: 'Select the vehicle and driver that will operate this service. Only those that are available or do not clash with other roles will be shown.',
        disp_driver: 'Drivers available',
        view_prof: 'View profile',
        disp_veh: 'Vehicles available',
        create_role: 'Create new role',
        preassigns: 'PREASSIGNS',
        slectedDriver: 'Selected driver:',
        menu: [
          { icon: 'delete', name: 'Delete' }
        ]
      },
      role_list: {
        more_fil: 'More filters',
        send_reg: 'Send registration',
        send_regD: 'Send this information to other interested parties',
        rec_roles: 'Recurring roles',
        all_roles: 'All roles',
        apply_fil: 'Apply filters',
        empty_roleT: 'No roles created',
        empty_roleD: 'You have not yet created roles. Go to the \"New role\" tab to start creating your first services.',
        pending: 'Pending',
        startTripAlert: 'You are about to restart the trip of the role {{role}} on {{date}}. Do you want to continue?',
        startTripSuccess: 'The trip has been restarted successfully',
        menuList: [
          {icon: 'bus', name: 'Modify vehicle'},
          {icon: 'person-remove', name: 'Modify driver'},
          {icon: 'delete', name: 'Delete role'},
          {icon: 'swap-horiz', name: 'Transshipment'},
          {icon: 'payments', name: 'View tickets'},
          {icon: 'scatter-plot', name: 'Consult capacity'},
          {icon: 'reboot', name: 'Restart trip'}
        ]
      },
      modify_driver_unit: {
        titleUnit: 'Modify vehicle',
        titleDriver: 'Modify driver',
        descriptionVehicle: 'Use the \"Modify\" field to change the vehicle assigned to the selected role. App users will be notified.',
        descriptionDriver: 'Select a new driver to continue the current service role. Review the rules and operation here.',
        desc_link: 'Review here the rules and operation.',
        warningMessage: '<b>Consider that:</b> The same service role will be maintained, and only the driver will be changed',
        actual_driver: 'Current driver',
        actual_veh: 'Current Vehicle',
        search_driver: 'Search driver (name or lastname)',
        search_unit: 'Search vehicle (plate or unit number)',
        remove: 'Remove and change',
        reassignTitle: 'Reassignment of capacity',
        reassignDescription: 'Use the quantity and fare fields as appropriate (tickets or collection) to authorize the transfer of passengers from the current vehicle of the driver to the new driver.',
        advice: 'Important:',
        advice2: 'Transshipment refers to a movement record for counting brands, which the administrator will deduct from the receiving driver or vehicle as appropriate and at his or her discretion. It does not involve financial or collection transactions.',
        searchRate: 'Search rate',
        tabs: [
          {
            name: 'TICKETS',
            action: "tickets"
          },
          {
            name: 'COLLECTION',
            action: "collection"
          }
        ]
      },
      addCollection: {
        title: 'NEW COLLECTION ROW',
        description: 'You will add a new row to the outgoing driver collection'
      },
      transfer: {
        title: 'Transfer',
        desc: 'Use this feature if you need to move passengers from one vehicle to another. The role of both vehicles will be maintained and the amount you move will not be recorded as collection for the second vehicle.',
        veh_send: 'Vehicle that send',
        veh_rec: 'Search vehicle that receives',
        alertRemoveRoleTitle: 'REMOVE CURRENT VEHICLE',
        alertRemoveRoleDescription: 'Check the following box if you wish to remove the current vehicle from the role and keep the new vehicle you are assigning.',
        ret_role: 'Remove the current vehicle from its role.',
        cant_tras: 'Transfer number',
        transfer: 'Transfer',
        unit_err: 'Receiver unit required',
        rolesReceptor: 'ROLES OF THE RECEIVING VEHICLE',
        errorSelectedRole: 'You must select a role from the table to continue the transfer.',
        transferPassenger: 'Transferred passengers',
        reassignTitle: 'Capacity Reassignment',
        reassignDescription: 'Use the quantity and fare fields as appropriate (tickets or collection) to authorize the transfer of passengers from the current vehicle to the receiving vehicle.'
      },
      delete: {
        title: 'Delete role',
        description: 'You are about to delete the role <b>{{trayecto}}</b> for date <b>{{fecha}}</b> with departure time <b>{{hora}}</b>',
        title_s: 'Role with tickets sold',
        desc_s: 'This role has ',
        desc_s3: ' tickets sold. Electronic ticket users will be notified, but you must notify physical ticket users by appropriate means.',
        disableRole: 'Disable this same time for the remainder of the day'
      },
      profile_driver: {
        send_wh: 'Send a WhatsApp',
        view_complete: 'View complete Driver file',
        comments: 'What users comment',
        service: 'Service',
        driving: 'Driving',
        vocab: 'Vocabulary',
        no_comments: 'No comments'
      },
      salesReport: {
        title: 'Sales report',
        phisicalTickets: 'PHISICAL TICKETS:',
        digitalTickets: 'DIGITAL TICKETS:'
      },
      datetime: {
        title: 'DATE AND TIMES OF THE ROLE',
        description: 'Select the route schedules that you want to assign to this service role. If you do not find the desired schedule then create it in the \"routes\" module'
      }
    },
    routes: {
      title: 'Routes maintance',
      no_filters: 'You have not applied any filters yet.',
      alerts: {
        str_err: 'Error loading stretches.',
        tar_err: 'Error loading tariffs.',
        err_maps: 'There was a problem with obtaining data from google maps, we will try again. Do you want to try again?, please contact the Passer Enterprises service.',
        err_compServN: 'The selected route has not been created or is deactivated. If it is deactivated, go to the route module to activate that route.',
        err_compServ: 'Error loading journey (Company Services).',
        noPlaces: 'There is no ',
        noPlaces2: ' created yet.',
        timeSelected: 'You must add a time to continue.',
        daySelected: 'You must select at least one day to continue.',
        dateSelected: 'You must select at least one date to continue.',
        noCharacterValid: 'The character \"-\" is not valid in the stertch name.',
        removeSchedule: 'Removed schedule',
        dataLoadedTitle: 'Incorrect data',
        sameDataLoadedTitle: 'Data does not match',
        verifyDataTitle: 'VERIFY THE DATA',
        verifyDataDescription: 'Some of the data in the array does not meet the criteria. Please verify that you are using the correct format, and that there are no inconsistent characters, such as "dots" and "commas"',
        dataLoadedLengthDescription: 'Please verify that the amount of data loaded is as indicated, the data to be loaded must be from the second point of the tariff matrix.',
        generateTitle: 'Unloaded file',
        generateDescription: 'You must first load the data before you can generate the journey cuts',
        noDataLoaded: 'Before you can save the route information you must press \"Generate\" to load the complete information of the loaded cuts.',
        moreDataMatrix: 'You are trying to enter more data than required. First add the number of rows necessary to copy the information to the table.',
        moreDataOlderMatrix: 'You are trying to enter more data than required. Make sure you have the same number of rows needed to copy the information to the table.',
        emptyOlderMatrix: 'It looks like you have blank cells, with decimals or cells that are not numbers. Please check before continuing.',
        noDataOlderMatrixTitle: 'Older Matrix',
        noDataOlderMatrixDesc: 'You must enter the rates for the older adult matrix.',
        preSave: 'The new rates have been successfully pre-saved. Close this window and press \"Save changes\"',
        deleteRouteTitle: 'Delete route',
        deleteRouteDescription: 'You are about to delete the route <b>{{route}}</b> with the tag <b>{{tag}}</b>. Do you want to delete the route?'
      },
      route_list: {
        title: 'Rutas creadas',
        active: 'On',
        deactive: 'Off',
        servicetype: 'Service type',
        service_type: 'Modality',
        empty_routeT: 'No routes created',
        empty_routeD: 'You have not yet created routes. Go to the \"New route\" tab to start creating your first services.',
        base_config: 'Basic configuration',
        matrix: 'Rate matrix',
        matrix_journey: 'Matrix of routes and fares',
        map: 'Map',
        menuList: [
          { icon: 'edit', name: 'Basic configuration'},
          { icon: 'edit', name: 'Edit rates and cuts'},
          { icon: 'edit', name: 'Edit rates matrix'},
          { icon: 'edit', name: 'Edit route cuts'},
          { icon: 'delete', name: 'Delete route'},
          { icon: 'power-on', name: 'Off/On'},
          { icon: 'clock', name: 'Schedules'},
          { icon: 'credit-card', name: 'Rates'}
        ]
      },
      new_route: {
        title: 'Nueva ruta',
        basic_config: {
          title: 'Basic route configuration',
          create_label: 'Route label',
          empty_data: 'You must type the label which will identify the route to create',
          checkDesc: 'By creating schedules and dates you confirm and attest that they are accurate and truthful, that you have configured them precisely and that the services will be provided according to the dates and times established here. At the same time, you release Passer from any liability for non-compliance.',
          journeyTypeDirect: 'It only makes short stops at checkpoints, terminals, rest places or similar. It does not allow boarding on the road in general.',
          journeyTypeIndirect: 'Make constant stops during the journey. Users can board at any authorized stop.',
          specials: 'Specials',
          extras: 'Extras',
          serv_type: {
            title: 'Do you want to sell tickets online for this route?',
            yes_tool: 'If you activate this option, the services on this route can be consulted and booked online by users from the Passer app.'
          },
          sched: {
            title: 'MANAGEMENT OF SCHEDULES AND DAYS OF SERVICE'
          },
          tabSchedule: [
            { action: 'regular', name: 'REGULAR SCHEDULES' },
            { action: 'special', name: 'SPECIAL AND EXTRA SCHEDULES' }
          ],
          menu: [
            { icon: 'pending-actions', name: 'Consult schedules' }
          ]
        },
        matrix: {
          title: 'Operation matrix',
          desc: 'Defines the scheme of stretches, routes and ways that operate within this route. Add the rate that corresponds to each one.',
          way_codeT: 'Way name',
          way_codeD: 'Enter the name of the way or subdivision.',
          way_name: 'Way name',
          code_route: 'Route code',
          scheme: 'Scheme of stretches and rates',
          rowDescription: 'By default you have 2 rows (parent stretch). You can add more rows depending on your type of route, or eliminate those you do not need.',
          deleteRow: 'Delete row',
          addRows: 'Add rows',
          tariffTitle: 'Tariff adjustment',
          tariffDescription: 'Use this option to make a massive change to the fare for each stretch.',
          reg_stretchesT: 'REGULATED SALES JOURNEY (optional)',
          reg_stretchesD: 'Define a start point and an end point whose middle stretches must be charged with the same price as the regulated journey rate. In this way you will be indicating to the system where a stretch begins and ends whose boarding points share the same rate according to the authorization of the regulatory entity.',
          add_stretch: 'Enter stretches',
          count_stretches: 'Number of stretches',
          rate_pay: 'Fee to be charged',
          empty_inputs: 'You must type the name of the way and the route code.',
          adjustmentList: [
            { name: 'Increase', value: 1 },
            { name: 'Decrease', value: 2 }
          ],
          menuList: [
            { icon: 'delete', name: 'Delete' }
          ]
        },
        new_route: {
          title: 'Operational maps',
          desc: 'Operational maps allow you to manage locations, times and important geographic information for your own control and enrich the experience of end users. Use the \"Parent route\", \"Intermediate stretches\" and \"Authorized stops\" tabs to add the information.',
          journey_fath: 'Parent journey',
          stretches: 'Journey cuts',
          stops: 'Authorized stops',
          journey_fatT: 'Parent journey of the route',
          journey_fatD: 'Click on the map to add markers and point out the starting and ending position of the parent route, that is, the first place of departure and the last place of arrival.',
          search_google: 'Search on Google Maps',
          add_marker: 'You must add the markers of the parent path.',
          checkDisclaimer: 'I have read, accept and understand the fare matrix and map configuration disclaimers.',
          tabList: [
            {action: 'journeyFath', name: 'Parent journey'},
            {action: 'stretches', name: 'Journey cuts'}
          ]
        },
        stops: {
          title: 'Stops and Terminals',
          desc: 'Click on the map to indicate the location of stops and terminals. This will be reflected by users in the Passer application and will help improve their travel experience. You can also have more precise control over the time and location of your vehicles. If you have questions about how it works you can ',
          name_stop: 'Stop name',
          time: 'Time travel',
          road_stop: 'Highway stop',
          terminal: 'Terminal / Office',
          subTitle: 'Description and frequency',
          subDescription: 'Enter a name by which users can recognize this stop. Also, you can indicate how often the service will be passing through this location.',
          type: 'Type (optional)',
          nameStop: 'Reference name',
          frequency: 'Frequency'
        },
        stretches: {
          title: 'GEOLOCATE COURTS',
          desc: 'Load the matrix of coordinates and travel times of each stretch or cut so that the system adds the mark to the map. The time you indicate will allow us to calculate the departure time of the services from each route break. ',
          fileFormat: 'Download Excel format for matrix.',
          adminRates: 'Manage rates',
          alertLoadInfo: 'The information was loaded successfully. Press \"Generate\" to add the geographic markers of each cut to the map.',
          subTitle: 'Travel time',
          subDescription: 'Enter the travel time of the service from the parent origin, in this case ',
          subDescription2: ' until each of the stretches of the journey until reaching the parent destination',
          depName: 'Parent origin',
          nextStretch: 'Next stretch',
          time_tr: 'Travel time',
          admin_stretchesT: 'Manage stretches',
          admin_stretchesD: 'Use the controls and functions to turn stretches on or off in special situations. You can also define the online sales capacity if you activated this option.',
          searchStretch: 'Search stretch',
          stateStretch: 'Cut state',
          distanceStretch: 'Previous cut distance',
          distanceOrigin: 'Parent distance',
          arrivalName: 'Destination Court',
          coordinates: 'Coordinates',
          modifyTimeTitle: 'MODIFY TIME',
          modifyTimeDescription: 'Add a new number of hours and minutes to change the estimated travel time of this cut ({{tramo}})',
          elderAlert: 'You can use the rate management options to modify prices or how they apply to certain circumstances. In the case of senior adult rates, you must press the "Adults" button to generate the differentiated matrix, otherwise you will not be able to save the route.',
          menuList: [
            {icon: 'edit', name: 'Modify time'},
            {icon: 'edit', name: 'Modify marker'},
            {icon: 'power-on', name: 'Activate/Inactivate'}
          ]
        }
      },
      modals: {
        calendar: {
          title: 'BLOCK DATES',
          description: 'Use the "Add date" button to disable specific days.',
          alert: 'This function is especially useful when there are cases of force majeure that make it necessary to temporarily suspend service hours.',
          addDate: 'Add date',
          disabledDates: 'Blackout dates'
        },
        aforo: {
          title: 'Capacity',
          desc: 'The capacity allows you to define the online sales capacity by stretch, so you can control the profitability of each one. Define the percentage of sale that you want to be assigned to each one, in this way users will only be able to buy that number of seats for the stretch in question. As a general rule, the percentage cannot be less than 50%, in addition, the parent destination will always be 100%.',
          errData: 'Make sure you have entered all the data from the capacity table. Also, each percentage must be at least 50 and no more than 100.'
        },
        ratesSchedules: {
          title: 'RATES FOR THE JOURNEY:',
          titleSchedule: 'SERVICE SCHEDULES FOR THE JOURNEY:',
          warning: 'If you want to make changes, go to <b>\"route maintenance\"</b> and select <b>\"Basic settings\"</b>'
        },
        datetimeRoute: {
          title: 'DATE AND TIMES OF THE ROLE',
          description: 'Enter a time (am/pm) and then select the days of the week, or dates, you want to assign the time to.',
          applyAll: 'Apply to all days of the week',
          regularSchedule: 'It is a regular schedule',
          regularDescription: 'These are the root schedules and correspond to the departure time of the first stretch of the journey. The departure times of the following stretches are automatically calculated according to the time you have assigned in the “Course cuts” section.',
          specialSchedule: 'It is a special schedule',
          specialDescription: 'Use this option to create particular schedules for a specific date that will then disappear. When you create a special schedule, regular schedules for that same date will not be displayed.',
          extraSchedule: 'It is an extra schedule',
          extraDescription: 'These are additional schedules and will be displayed along with the regular or special shedules.'
        },
        disclaimer: {
          title: 'DISCLAIMERS',
          description: 'By creating the rate matrices and geographic marking on maps, you understand and accept the following provisions and disclaimers, releasing Passer from any liability arising from non-compliance on your part or from the uploading of erroneous information.',
          disclaimerList: [
            '1. The travel time that you define allows you to determine the approximate closing time of each role.',
            '2. The distance between tariff cuts (stretches) is the length between one cut and another. This distance allows you to calculate the applicable rate for seniors when you activate the automatic calculation option.',
            '3. You confirm and accept that the data contained in the table of distances and rates for seniors is correct, and to your complete satisfaction.',
            '4. By establishing travel times and distances between tariff cuts you confirm and guarantee that you have reviewed them, done them accurately and in a clear manner.',
            '5. You release Passer from any responsibility for non-compliance with schedules.',
            '6. You release Passer from any liability for erroneous fare calculations resulting from incorrect information that you have uploaded or added.'
          ],
          consult: 'If you have questions, do not hesitate to use the service channels to help and advise you. It will always be a pleasure to serve you.'
        },
        olderMatrix: {
          title: 'RATES FOR SENIOR ADULTS',
          description: 'Add the senior rate that would apply as appropriate between each leg you have added to the matrix.'
        },
        specialSchedules: {
          title: 'SCHEDULES',
          description: 'The schedules for the >b>{{date}}</b>'
        }
      },
      savedRoute: {
        title: 'Previous route',
        description: 'It looks like you have a route in progress, do you want to continue?'
      },
      preventRoute: {
        title: 'Unsaved route',
        description: 'Do you want to save the route before you leave?'
      }
    },
    profile:{
      verify_password: 'Verify your current password to modify and save your access credentials.',
      verify_phoneT: 'Verify phone',
      verify_phoneD: 'If you change your phone number you must verify it. Press \"Send code\" to receive a code via SMS on your mobile device.',
      administrative_password: 'Administrative password',
      active_payment: 'Activate two-step verification to add an extra layer of security to your account. You will receive a code on your mobile phone or email each time you want to access your account.',
      location: 'Location',
      enter_code: 'Enter code',
      verify_code: 'Code verified successfully',
      alerts: {
        photo: 'The dimensions of the image must be greater than ',
        pixels: ' pixels.',
        error_verify: 'Error verifying code',
        all_code: 'You must enter the 4 digits of the code before verifying.'
      },
      account: {
        title: 'Passer account',
        description: 'Your Passer account is a universal, exclusive and personal key, so you can easily, safely and without complications access all the platforms and services that Passer offers you with the same Email, Telephone, Password or PIN.<br/><br/> Each platform has unique characteristics, so you will have to make some configurations and load additional information for each case.<br/><br/> When you modify the account data, they will be modified in a general way, so make sure you remember them and carry out the corresponding verifications.',
        savingAccountTitle: 'Savings account',
        savingAccountDescription: 'Request your NATIONAL BANK savings account and debit card free of charge and without complex paperwork so that you can receive payment and make purchases with great benefits.',
        donwloadApp: 'Download the app for free',
        openAccount: 'OPEN ACCOUNT',
        credentialsTitle: 'ACCESS CREDENTIALS',
        credentialsDescription: 'If you change your credentials press \"Send code\" to receive a code via SMS on your mobile device or email account.',
        currentPassword: 'Current password',
        newPassword: 'New password',
        confirmPassword: 'Confirm new password',
        twoSteps: 'TWO-STEPS VERIFICATION',
        twoStepsDescription: 'Enable or disable two-step verification for your Passer account. We recommend having this option enabled for added security.',
        pinAccountTitle: 'PIN ACCOUNT',
        pinAccountDescription: 'You will use the account PIN in all your transactions and purchases of the Passer services.'
      },
      access: {
        title: 'My Accesses',
        description: 'Enterprise accesses are the companies or businesses to which a group administrator owner has given you access through your Passer account.',
        companySubscription: 'Company/Subscription',
        accessType: 'Access type',
        owner: 'Group owner',
        yourAccess: 'Your Access',
        yourConnection: 'Your Connection',
        menuList: [
          { icon: 'switch-account', name: 'Work here' }
        ]
      }
    },
    suspendedAccess: {
      title: 'SUSPENDED ACCESS',
      description: 'A group owner or administrator has revoked your access to this subscription or account. Please try to login with another user or contact your company administration.',
      return: 'Back to login'
    },
    tickets:{
      title: 'Ticket office',
      asign_seats: 'Assign seats',
      timeLeft: 'Remaining Time:',
      timeoutTitle: 'NO RESPONSE',
      timeoutD: 'Sorry, we could not reach the server. For security we will block the seat for 5 minutes, please try another one.',
      timeoutStandingD: 'Sorry, we could not reach the server, please try again.',
      withoutNumbering: 'Without Numbering',
      standing: 'Standing',
      sitting: 'Sitting',
      boardings: 'Boardings',
      alerts: {
        vend_err: 'Error loading sellers.',
        tick_err: 'Error loading Tickets.',
        sch_err: 'Error loading schedules.',
        pay_more: 'You must pay more or equal to the price shown in the total sale.',
        schServ_err: 'Error loading service schedules.',
        seat_err: 'Error loading seats.',
        client_err: 'Error loading clients.',
        code_err: 'Error loading ticket codes.',
        oneT_err: 'Error loading ticket.',
        no_ticketsT: 'There are no tickets sold',
        no_ticketsD: 'You have not yet tickets sold. Go to the \"Window\" tab to start selling tickets.',
        noOfficeTitle: 'No places available',
        noOfficeDescription: 'At this time none of your sales places are in active service hours, or you have not yet been assigned place. Please review or consult with your subscription administrator.',
        errorCondition: 'Error loading sales conditions.',
        pinInvalid: 'Invalid PIN, please check and try again',
        digitalTitle: 'Digital Ticket',
        digitalDescription: 'This ticket was sent to the Passer app, so it is not possible to print it',
        successBought: 'Successful purchase',
        successBoughtDescription: 'A ticket has been generated for ',
        alertMinValue: 'The minimum value of the {{value}} is',
        sameDiscount: 'This senior ticket has a <b>0%</b> discount, therefore it will be charged and recorded at closings as a <b>"regular"</b> sale',
        pendingTicketTitle: 'Pending transaction',
        pendingTicketDescription: 'It appears that the sale you tried to make may have been made. Please verify before retrying.',
        pendingErrorTitle: 'Transaction failed',
        pendingErrorDescription: 'The previous sales transaction was not completed. Please run the sale again.',
        missingTicketsTitle: 'Missing Tickets',
        missingTicketsDescription: 'Apparently of the selected {{count1}}, only {{count2}} could be processed for sale. Please reselect and sell the missing tickets.',
        busInfo1: 'Vehicle <b>{{plate}}</b> has approximately <b>{{amount}}</b> space(s) left.',
        busInfo2: 'Please use the <b>\"Check capacity\"</b> option for more details or to view other services.',
        lastUpdate: 'Last update:'
      },
      tabSolds: [
        { action: 'tickets', name: 'TICKETS' },
        { action: 'closings', name: 'CLOSINGS' }
      ],
      tickets_list:{
        title: 'Sold',
        generate_change: 'Generate change',
        total_income: 'Total income',
        total_tickets: 'Total tickets',
        unidentified: 'unidentified',
        cancelTicket: 'Cancel ticket',
        officeEmptyTitle: 'No open cash register',
        officeEmptyDescription: 'To cancel a ticket you must have at least one cash register open. Go to the <b>\"Window\"</b> tab to open one.',
        noCancelTicket: 'You cannot cancel a canceled/refunded ticket.',
        noPrint: 'You cannot reprint a canceled/refunded ticket.',
        warningCancel: 'You are about to cancel the ticket {{num}}. This action is not reversible, so before continuing make sure you check the data carefully and that it is the correct ticket. Press continue to cancel the ticket.',
        menuList: [
          {icon: 'print', name: 'Reprint'},
          {icon: 'delete', name: 'Cancel ticket'}
        ]
      },
      ticketsClosing: {
        menuSeller: [
          { icon: 'print', name: 'Print closing' },
          { icon: 'wallet', name: 'Breakdown' }
        ],
        menuDriver: [
          { icon: 'cashier', name: 'Receive' },
          { icon: 'wallet', name: 'Breakdown' }
        ],
        sellers: {
          title: 'MY SALES',
          description: 'Billing details by sales location',
          totalTransactions: 'Total transactions',
          totalTickets: 'Total tickets',
          grossSales: 'Gross Sales',
          discounts: '- Authorized discounts',
          discountsMin: '- Authorized disc.',
          discountChanges: '- Discount changes',
          discountChangesMin: '- Disc. changes',
          discountElder: '- Adult discounts',
          discountElderMin: '- Adult disc.',
          cancellation: '- Cancellation',
          total: 'Net delivery',
          print: 'Print closure',
          other: '- OTHER',
          signSeller: 'Seller signature',
          sellerDelivery: 'SELLER DELIVERY',
          missingOther: 'SURPLUS/MISSING',
          withCash: 'With cash',
          withoutCash: 'Without cash'
        },
        admins: {
          title: 'SALES OF OTHER PLACES',
          description: 'Information on sales made by other sellers. Pending closures shown.'
        },
        drivers: {
          title: 'CLOSING DRIVERS',
          description: 'Roles pending closure are shown',
          warningTitle: 'Cierre conductor',
          warningDescription: 'You are about to do a closing driver, this action cannot be changed, so check the data entered carefully before continuing. If you think everything is in order, press <b>\"Continue\"</b> to close and print.',
          warningSaleTitle: 'Window close',
          warningSaleDescription: 'You are about to close the window, this action cannot be changed, so check the data entered carefully before continuing. If you think everything is in order, press <b>\"Continue\"</ b> to close and print.',
          closeTitle: 'WINDOW (LOCATION)',
          driverDelivery: 'DRIVER DELIVERY',
          missingOther: 'SURPLUS/MISSING'
        }
      },
      tickets_sales: {
        title: 'Window',
        carrier: 'TO THE CARRIER',
        tab_left: {
          title: 'SERVICE DESCRIPTION',
          salesPlace: 'Sales place',
          selectPlace: 'Select place',
          selected: 'Selected',
          load_seats: 'Select spaces',
          send_tick: 'Send ticket to app',
          send_tickD: 'Use this option if you want to send tickets directly to the app of user.',
          elder: 'Elderly',
          elderD: 'The senior discount is automatically calculated based on travel distances. Or enter a manual discount.',
          seatsSelected: 'Number of spaces: ',
          standingSelected: 'Standing spaces: ',
          consultCapacity: 'Consult capacity'
        },
        tab_right: {
          title: 'SALES INFORMATION',
          total_sale: 'Total sale',
          codes: 'Changes and discounts',
          id_tick: 'ID ticket',
          otherDiscount: 'Other discount',
          withoutDiscount: 'Without discount',
          payWith: 'Pay with',
          change: 'Change',
          total: 'Total net to pay',
          pros_buy: 'Process purchase'
        },
        seats_modal: {
          title: 'Select seats',
          withoutSeat: 'This service allows standing sales',
          totalStanding: 'Available standing spaces',
          busy: 'Busy',
          free: 'Free'
        }
      },
      views: {
        seats_modal:{
          title: 'For the selected date and time there are ',
          title2: ' available.',
          floor: 'Select a floor',
          first_floor: 'First floor',
          second_floor: 'Second floor',
          available: 'Available',
          taken: 'Taken',
          selected: 'Selected',
          corridor: 'Corridor',
          back: 'Back',
          seats: 'Seats',
          pref: 'Preference'
        },
        sales_modal: {
          success_sell: 'Ticket successfully sold',
          alert: 'Alert'
        },
        details_modal: {
          code_details: 'Check code details',
          boarding_data: 'Boarding date and hour',
          boarding_place: 'Boarding place'
        }
      },
      modals: {
        title: 'Generate change code',
        desc: 'You are going to generate a change for the ticket with ID ',
        desc2: ' Do you want to continue?',
        desc3: 'Remember to keep the original ticket',
        title_generate: 'Pending change',
        trip_data: 'Trip data',
        amount: 'Price',
        noChangesTitle: 'APPLY CHANGE',
        noChangesDescription: 'You will apply a change to ticket {{id}}. The amount will be deducted from the net total to be paid.',
        verifyInfo: 'Verify the following information',
        applicablePercentage: 'Applicable Percentage',
        changeAmount: 'Change amount',
        adminTitle: 'DISCOUNT AUTHORIZATION',
        adminDescription: 'You are about to authorize a discount, please select your name and enter your Passer account PIN',
        refundTitle: 'REFUND AUTHORIZATION',
        refundDescription: 'You are about to authorize a refund, please select your name and enter your Passer account PIN',
        adminSubscription: 'Subscription admin',
        adminPIN: 'Passer Account PIN',
        openCashTitle: 'Open cash',
        openCashDescription: 'Enter the amount with which you will start this work day',
        adminEmpty: 'You must select an administrator to continue.',
        cancelTicketTitle: 'CANCEL TICKET',
        cancelTicketDescription: 'You are about to cancel ticket with id {{num}}. If you wish to continue, complete the following information and press \"Cancel\".',
        selectPlace: 'Select a place',
        alertCancel: 'If you cancel this ticket you must make a refund in cash or the bank account of user.',
        idCopied: 'Ticket id copied successfully!',
        refundOnlyTicket: 'Refund this ticket only',
        refundAccumulated: 'Refund accumulated balance',
        capacityTitle: 'VEHICLE CAPACITY',
        capacityDescription: 'Use the search filters to find out the estimated capacity of a particular service role',
        searchStretch: 'Search stretch',
        capacityTabs: [
          { action: 'tickets', name: 'TICKETS' },
          { action: 'boarding', name: 'ROAD' },
        ]
      },
      status: [
        { displayName: 'Active', value: 1 },
        { displayName: 'Consumed', value: 2 },
        { displayName: 'Cancelled', value: 3 },
        { displayName: 'Refunded', value: 3 }
      ],
      salesBreakdown: {
        title: 'Ticket office sales breakdown',
        description: 'Information on tickets sold for each stretch of the route is displayed.',
        importantTitle: 'IMPORTANT CONSIDERATION',
        importantDescription: 'Remember that the window collection (sales) is correlated to the boarding marks of driver, therefore, the latter does not have the money equivalent to ticket sales. If the user of the physical ticket does not board, consider that the money from the sale always came in and you have it, so it is recommended not to reduce it or collect it from the driver even if he does not have the physical receipt.',
        ticketsAmount: 'Tickets amount',
        stretchPrice: 'Stretch Rate',
        discounts: 'Total discounts',
        cashDelivery: 'Net Delivery',
        menu: [
          { icon: 'table-rows', name: 'Download Excel' }
        ]
      },
      driverBreakdown: {
        title: 'Collection breakdown by driver',
        description: 'Information on physical and digital tickets that have been read and validated is displayed, as well as cash collection figures.',
        importantTitle: 'IMPORTANT CONSIDERATION',
        importantDescription: 'Remember that the window collection (sales) is correlated to the boarding marks of driver, therefore, the latter does not have the money equivalent to ticket sales. If the user of the ticket does not board, consider that the money from the sale always came in and you have it, so it is recommended not to reduce it or collect it from the driver even if he does not have the physical receipt.',
        associatedTickets: 'Total associated tickets',
        paperValidated: 'Physical tickets validated',
        digitalTickets: 'Validated digital tickets',
        boardings: 'Regular boardings',
        elderBoardings: 'Elder Boardings',
        stretchPrice: 'Stretch Rate',
        discount: 'Senior adult discount',
        refunds: 'Amount refunds',
        netDelivery: 'Net Delivery',
        menu: [
          { icon: 'table-rows', name: 'Download breakdown by stretch' },
          { icon: 'table-rows', name: 'Download compiled by rates' }
        ]
      },
      configuration: {
        title: 'Conditions',
        subTitle: 'Sales conditions',
        desc: 'Use the parameters to define your online sales conditions, operation and other important terms that users should know. What you tell us here will be considered as part of your commercial policies.',
        acceptConditions: 'I understand and accept that these conditions of sale will be part of my service policies, which will be displayed to the end user before each purchase and that Passer will apply them as provided herein.',
        instructions: {
          title: 'INSTRUCTIONS FOR PASSER',
          desc: 'Passer is responsible for the care and support of all users who make purchases and transactions through the app, but always in accordance with your rules and policies. Please, tell us some important points or issues that you want us to we apply and take into consideration in relation to the operating policies.',
          specials: 'Special Instructions'
        },
        operation: {
          title: 'OPERATION POLICIES',
          desc: 'The operation attributes allow you to define rules and characteristics based on what is most feasible for your business. This information will be presented to the end user for their information.',
          boarding_way: 'ALLOW HIGHWAY BOARDING?',
          printCopy: 'PRINT A COPY OF THE TICKETS?',
          sale_after: 'ALLOW PURCHASES AFTER DEPARTURE TIME?',
          max_time: 'Maximum time for purchase from app',
          maxTimeDES: 'Maximum time for sale from physical box office',
          changes: 'ADMIT CHANGES?',
          max_departure_time: 'Maximum time before departure',
          max_arrival_time: 'Maximum time after departure',
          max_changes: 'Maximum allowed changes',
          max_value: 'Maximum allowed value',
          maxTimeValid: 'Expiration',
          availabilityBuyTitle: 'AVAILABILITY TO BUY',
          availabilityBuyDescription: 'Maximum time with which you allow to buy in advance',
          authorizationTitle: 'AUTHORIZATION FOR DISCOUNTS',
          authorizationDescription: 'Indicate whether or not you want sellers to be authorized by a subscription administrator to be able to make discounts.',
          noAuth: 'No authorization required',
          yesAuth: 'Requires authorization',
          timeBeforeCancellations: 'Maximum time before departure',
          timeAfterCancellations: 'Maximum time after departure',
          onlyOriginal: 'Only in original place',
          anyPlace: 'Any place',
          cancellationAllow: 'ALLOW CANCELLATIONS?'
        }
      },
      refunds: {
        title: 'Digital refunds',
        description: 'Refund requests for digital tickets that have been handled by Passer support and service staff are displayed.',
        detailsTitle: 'Tickets details',
        refundOffice: 'Reimbursement place',
        reasonDecline: 'Reason for decline',
        declineRefund: 'DECLINE REFUND',
        otherReason: 'Other reason',
        reference: 'Transfer reference',
        refundAmount: 'AMOUNT REIMBURSED',
        menuList: [
          { icon: 'eye', name: 'Details' },
          { icon: 'payments', name: 'Refund' },
          { icon: 'block', name: 'Decline' }
        ],
        statusList: [
          { displayName: 'Pending', value: 1 },
          { displayName: 'Refunded', value: 2 },
          { displayName: 'Refused', value: 3 }
        ],
        reasonDeclineList: [
          { displayName: 'Policy Violation', value: 1 },
          { displayName: 'False Information', value: 2 },
          { displayName: 'Administration does not authorize', value: 3 },
          { displayName: 'Other', value: 4 }
        ]
      }
    },
    transactions: {
      title: 'COLLECTION AND LIQUIDATIONS',
      generateExcel: 'Generate report in Excel',
      inProgress: {
        title: 'IN PROGRESS',
        emptyDataTitle: 'There are no collections',
        emptyDataDescription: 'There are no registered collections in progress yet.',
        menu: [
          { icon: 'credit_card_off', name: 'Electronic collection rejections' }
        ]
      },
      finished: {
        title: 'FINISHED',
        emptyDataTitle: 'No completed transactions',
        emptyDataDescription: 'No completed transactions yet.',
        menu: [
          { icon: 'wallet', name: 'Ecommerce and physical details' }
        ],
        menuDownload: [
          { icon: 'table-rows', name: 'Download Excel' },
          // { icon: 'description', name: 'Download PDF' }
        ]
      },
      conciliations: {
        title: 'CONCILIATIONS',
        totalPhysicalTickets: 'Total physical tickets',
        ticketsSales: 'Gross ticket sales',
        discountElder: '- Adult discounts',
        discountChanges: '- Discount changes',
        discountAuth: '- Authorized discounts',
        cancellations: '- Cancellations (Refunds)',
        driverSale: 'Driver net delivery',
        total: 'Total net delivery',
        shipmentsTitle: 'CONCILIATIONS - LOGISTICS',
        shipmentsDescription: 'Invoicing and consolidation corresponding to shipping services.',
        menu: [
          { icon: 'table-rows', name: 'Consolidated Excel ticket office' },
          { icon: 'table-rows', name: 'Consolidated Excel drivers' },
          { icon: 'table-rows', name: 'Excel consolidated cancellations' },
          { icon: 'description', name: 'Consolidated PDF ticket office' },
          { icon: 'description', name: 'Consolidated PDF drivers' }
        ],
        menuEcommerce: [
          { icon: 'table-rows', name: 'Consolidated Excel' },
          { icon: 'description', name: 'Consolidated PDF' }
        ],
        menuShipment: [
          { icon: 'table-rows', name: 'Consolidated Excel shipments' },
          { icon: 'description', name: 'Consolidated PDF shipments' },
        ],
        menuListShipment: [
          { icon: 'assignment', name: 'Linked Sellers' }
        ]
      },
      modals: {
        deniedTransactions: {
          title: 'REJECTED TRANSACTIONS',
          description: 'Rejected transactions correspond only to electronic collection. Your company has a guarantee of up to ₡5,000 for each rejected payment.',
          rejectionAmount: 'Amount of rejections'
        },
        linkedAgents: {
          title: 'LINKED SELLERS - PHYSICAL TICKET OFFICE',
          description: 'Details of sellers linked to the sales of physical tickets. Billing cycle from 00:00 to 23:59 on <b>{{date}</b>}',
          menuList: [
            { icon: 'print', name: 'Print closure' },
            { icon: 'table-rows', name: 'Sales breakdown download' }
          ]
        },
        linkedDrivers: {
          title: 'LINKED DRIVERS - PHYSICAL COLLECTION',
          description: 'Details of drivers linked to the billing cycle from 00:00 to 23:59 on <b>{{date}}</b>',
          discountElder: '- Adult discounts',
          physicalTickets: 'Physical tickets',
          digitalTickets: 'Digital tickets',
          cashTickets: 'Cash tickets',
          cashDelivery: 'Net delivery',
          grossCollection: 'Gross collection',
          menuList: [
            { icon: 'print', name: 'Print closure' },
            { icon: 'table-rows', name: 'Download breakdown by stretch' },
            { icon: 'table-rows', name: 'Download compiled by rates' }
          ]
        },
        linkedRolesEcommerce: {
          title: 'LINKED ROLES - ECOMMERCE',
          description: 'Detail of the roles associated with the reconciliation selected for the billing cycle from 00:00 to 22:59 from {{date}}'
        },
        linkedRoles: {
          title: 'LINKED ROLES - PHYSICAL TICKET OFFICE',
          description: 'Detail of the roles associated with the selected reconciliation for the billing cycle from 00:00 to 23:59 on <b>{{date}}</b>'
        },
        deductionDetails: {
          title: 'DETAIL OF DEDUCTIONS AND COMPENSATIONS',
          subTitle: 'IMPORTANT CONSIDERATION',
          description: 'Passer applies a compensation rate equal to the deduction rate to ecommerce transactions so you can always receive your expected minimum billing base, without rebates.',
          totalRetentions: 'TOTAL RETENTIONS',
          totalCompensations: 'TOTAL COMPENSAtIONS'
        },
        linkedAgentsShipment: {
          title: 'LINKED SELLERS - LOGISTICS',
          description: 'Details of agents linked to the sales of the shipping service. Billing cycle from 00:00 to 23:59 on {{date}}',
          menuList: [
            { icon: 'print', name: 'Print closing' },
            { icon: 'table-rows', name: 'Download sales breakdown' }
          ]
        }
      },
      ecommerce: {
        considerationTitle: 'IMPORTANT CONSIDERATION',
        considerationDescription: 'The calculation of deductions, as well as compensations, is carried out based on the withholding parameters that the administrative group owner has indicated in the Passer Payments module. The amount of ',
        considerationDescription2: '\"Passer debt\"',
        considerationDescription3: ' is an outstanding balance due to discounts and that Passer will credit you directly each day in addition to the settlement of the bank.',
        enterprisesTitle: 'RECONCILIATIONS - ECOMMERCE',
        enterprisesDescription: 'Invoicing and settlement corresponding to online sales (app). The expected settlement contemplates the billing of a calendar day in the range of 00:00 hrs to 23:59 hrs',
        ticketTitle: 'CONCILIATIONS - PHYSICAL TICKET OFFICE AND PHYSICAL COLLECTION',
        ticketDescription: 'Invoicing and settlement corresponding to physical sales',
        pendingTitle: 'PENDING SALES LIQUIDATION ADJUSTMENTS',
        pendingDescription: 'The pending amount corresponds to the balance that, due to the sales cycle depending on the bank, could not be credited to your account the business day following the corresponding sale date. You will see this amount duly reflecting together with the liquidation of the day prior to the adjustment date.',
        invoicedBase: 'Base invoiced',
        deductions: '- Deductions (bank + IVA)',
        compensation: '+ Deductions compensation',
        compensationTitle: 'Compensations',
        deductionsTitle: 'Deductions',
        invoicedTotal: '= Total invoiced',
        debtAmount: '+ Debt Passer',
        expectedLiquidation: 'Expected Liquidation',
        pendingAmount: 'Pending amount',
        liquidationAmount: '= Bank liquidation',
        ticketsNumber: 'Ticket quantity',
        menu: [
          { icon: 'account-balance', name: 'Detail deductions' },
          { icon: 'bus', name: 'Linked Roles' }
        ],
        menuPending: [
          { icon: 'receipt_long', name: 'Details' }
        ],
        ticketStore: {
          window: 'Window',
          ticket: 'Tickets',
          adult: '- Senior discounts',
          changes: '- Exchange discounts',
          discount: '- Authorized discounts',
          cancellations: '- Cancellations',
          cash: 'Net delivery',
          grossSales: 'Gross Sales',
          menuListTicket: [
            { icon: 'bus', name: 'Linked Roles' },
            { icon: 'assignment', name: 'Attached Sellers' },
            { icon: 'drivers', name: 'Attached drivers' }
          ]
        }
      },
      electronicPayment: {
        totalAmounts: 'Total amounts are displayed according to the selected date range',
        totalCollection: 'TOTAL COLLECTION',
        totalRetention: 'TOTAL RETENTIONS',
        totalComission: 'TOTAL PASSER COMMISSIONS',
        totalLiquidation: 'TOTAL LIQUIDATIONS',
        menu: [
          { icon: 'directions_bus_filled', name: 'Linked roles' }
        ]
      },
      detailsCollection: {
        title: 'Details of ecommerce and physical collection',
        description: 'Complete information is shown for the selected role. Please consider that the bank could apply other discounts and withholdings that are not contemplated here, check your bank account statement to find out more details about your movements, or contact with your institutional service agent.',
        roleDescription: 'ROLE DESCRIPTION',
        importantDataRoleTitle: 'IMPORTANT CONSIDERATION',
        importantDataRoleDesc: 'You can consult the drivers and sellers associated with this role directly in the \"Conciliations\" tab once all the corresponding closures have been made',
        collectDetail: 'Ecommerce sales',
        transactions: 'Total of transactions',
        tickets: 'Total tickets',
        incomeWithholding: 'Income withholding',
        baseAmount: 'BASE AMOUNT OF SALES',
        compensatoryCharge: 'COMPENSATORY CHARGE',
        totalCharged: 'TOTAL CHARGED',
        expectedLiquidation: 'EXPECTED LIQUIDATION',
        physicalSales: 'PHYSICAL TICKET SALES (WINDOW)',
        totalSale: 'Gross sales',
        cancellations: 'Total cancellation amount',
        totalTerminal: 'NET DELIVERY',
        driverCollection: 'DRIVER COLLECTION',
        totalElder: 'Total older adults',
        totalFuel: 'Total fuel',
        totalTickets: 'Gross collection',
        totalBurden: 'Total burden',
        pendingDiscountTitle: 'OUTSTANDING BALANCE FOR DISCOUNTS',
        pendingDiscountDescription: 'The outstanding balance for discounts corresponds to the sales that were correctly processed within the Enterprises processing cycle but that received a discount (royalty). The total amount of these transactions will be credited by Passer to your account the day after the role.',
        totalTransactions: 'NUMBER OF TRANSACTIONS',
        totalTicket: 'QUANTITY OF TICKETS',
        totalDiscount: 'TOTAL DISCOUNT AMOUNT',
        closingPendingTitle: 'PENDING CLOSINGS',
        closingPendingDescription: 'There are pending collection closings for this role. Please complete the closings to be able to generate the complete reconciliation reports.',
        totalCollect: 'Total effective collection',
        luggageCollect: 'Load collection',
        paperTickets: 'Physical tickets',
        digitalTickets: 'Digital Tickets',
        grossSale: 'Gross sale of the stretch'
      },
      closingDriver: {
        refunds: '- REFUNDS',
        elder: '- OLDER ADULTS',
        physics: 'PHYSICAL TICKETS',
        digital: 'DIGITAL TICKETS',
        transferTickets: 'TRANSHIPMENT BRANDS',
        grossSales: 'GROSS RECEIPT',
        fuel: '- FUEL',
        burden: '+ BURDEN',
        effectiveDelivery: 'NET DELIVERY',
        fuelDescription: 'Fuel Description',
        signSupervisor: 'Supervisor signature',
        signDriver: 'Driver signature',
        receive: 'Receive/Print'
      }
    },
    units:{
      search_plate: 'Search license plate',
      search_num: 'Search number',
      circ: 'Circulation',
      circ_out: 'Out of circulation',
      total_standing: 'Total standing',
      behind: 'Behind',
      state: 'You can enter the vehicle but keep it in a state of inactivity, which will not be considered in the roles.',
      list_vehicles: {
        title: 'Vehicle list',
        no_units: 'There are no registered vehicles',
        no_unitsD: 'You have not yet created vehicles and configurations. Go to the \"Add\" tab to start registering new vehicles.'
      },
      create_modify: {
        title: 'Add/Consult',
        subtitle: 'DESIGN AND ASSIGNMENT OF VEHICULAR FLEET',
        config_new: 'New model',
        config_exist: 'Consult model',
        tabList: [
          {action: 'configNew', name: 'New model'},
          {action: 'configExist', name: 'Consult model'}
        ],
        generalInformation: {
          title: 'General information',
          infoTitle: 'GENERAL MODEL INFORMATION',
          infoDescription: 'Basic vehicle data',
          capacityTitle: 'CAPACITY STRUCTURE',
          capacityDescription: 'Enter the information that will allow the system to create the distribution of the vehicle capacity diagram.',
          floorAmount: 'Number of floors',
          floor1: 'First floor',
          floor2: 'Second floor',
          totalSeats: 'Total seats (both floors)',
          totalRows: 'Total rows',
          doorAmount: 'Door side columns',
          driverAmount: 'Driver side columns',
          finalSeat: 'Central seat back',
          standingT: 'Do you sell standing up?',
          standingAmount: 'Number of standing passengers',
          porcStanding: 'Percent standing',
          withoutNumberTitle: 'SELL WITHOUT NUMBERING?',
          withoutNumberDesc: 'If the seats or spaces of this model do not have numbered labeling, you can choose to sell without numbering.',
          prefTitle: 'PREFERRED SEATS',
          prefDescription: 'If your services have preferential services (elderly adults, disabilities, etc...) you can indicate the seat numbers of up to 10 spaces for this model.'
        },
        model: {
          title: 'Model graph'
        },
        vehicles: {
          title: 'Associate vehicles',
          subtitle: 'ADD NEW VEHICLE',
          description: 'Enter the license plate and number of those vehicles that share the information and graphic of this model. You can skip this step and do it at another time.',
          menuList: [
            {icon: 'delete', name: 'Delete'},
            {icon: 'power-on', name: 'Change status'},
            {icon: 'swap-horiz', name: 'Reasign'}
          ]
        },
        new_model: {
          desc: 'Create and design here the structure of your fleet, for example, capacity, rows and other characteristics, then indicate the plates of the vehicles that adhere to this configuration. If you have doubts about how it works you can ',
          capacity: 'CAPACITY STRUCTURE',
          standingT: 'Sell standing up',
          cant_floor: 'Number of floors',
          cant_standing: 'Number of standing passengers',
          porc_standing: 'Percent standing',
          row_col: 'Rows and columns',
          row_colD: 'Defines the number of columns that this vehicle has to place seats. Up to 65 will be generated and you must add a minimum of 40 seats for each one. If you have doubts about how it works, you can ',
          floor_1: 'Floor 1',
          floor_2: 'Floor 2',
          cant_row: 'Number of rows',
          cant_door: 'Door side columns',
          cant_driver: 'Driver side columns',
          pref_desc: 'Enter the seat numbers that will be designated as preferential, for example, 30 and 31. You must enter at least 4 seats.',
          generate: 'Generate diagram',
          veh_desc: 'Enter the license plates of the vehicles that share this configuration',
          add_act: 'Add as active',
          add_inact: 'Keep inactive',
          chg_status: 'Change status',
          sit_pref: 'Preferential seats',
          finalS: 'Central seat back',
          req_data: 'Required model data.'
        },
        modify_model: {
          desc: 'These are the configurations of models that you have already created and the vehicles that you have assigned. You can reassign them, delete them or create a new configuration for new models.',
          models: 'Models',
          findM: 'Search model',
          new_diagram: 'New diagram',
          veh_asoc: 'ASSOCIATED VEHICLES',
          new_vehT: 'NEW VEHICLE',
          new_vehD: 'Enter the license plate and unit number of the vehicle you want to associate with the model ',
          reassignT: 'REASSIGN VEHICLE',
          reassignD: 'Enter the model which you want to move this vehicle. It is currently with the model',
          no_model: 'You have no other models to reassign vehicles to.'
        },
        diagram: {
          title: 'VEHICLE DIAGRAM',
          desc: 'Drag and drop the seats to the spaces. If you leave spaces blank it means that there will be no seats there, for example, to place wheelchairs or a door.',
          sell_without: 'Sell without numbering',
          save_config: 'SAVE CONFIGURATION'
        },
        listModels: {
          search: 'Search model',
          models: 'Models',
          menu: [
            { icon: 'scatter-plot', name: 'Modify information and graph' },
            { icon: 'edit', name: 'Modify graph' },
            { icon: 'bus', name: 'Modify vehicles' },
            { icon: 'delete', name: 'Delete' }
          ]
        }
      },
      alerts: {
        brand_err: 'Error loading brands.',
        model_err: 'Error loading bus models.'
      },
      new_Unit: {
        auto: 'Vehicle',
        floor1: 'One floor',
        floor2: 'Two floors',
        consultT: {
          floor1: 'Seating 1st floor',
          floor2: 'Seating 2nd floor',
          pref: 'Preferential',
          afor: 'Structure of the capacity'
        }
      },
      mod: {
        title: 'Modify unit number',
        now: 'Current number',
        new: 'New number'
      },
      modals: {
        successC: 'The structure of this model with their respective vehicles has been added.',
        successCM: 'The structure of this model has been modified successfully.',
        succVC: 'The vehicles have been successfully assigned to the structure of the selected model.'
      },
      types: {
        0: 'Microbus',
        1: 'Small bus',
        2: 'Big bus',
        3: 'Standard bus (1 floor)',
        4: 'Special bus (2 floors)'
      }
    },
    valid_pos: {
      alerts: {
        select_one: 'You must select a row before you can detect the validator.',
        status_true: 'This validator has already been detected.',
        assign: 'You must first detect the validator, before you can assign the UID Key.',
        detected: 'Validator detected successfully.',
        err_types: 'Error loading validator types',
        errorTransportations: 'Error loading vehicles',
        err_uid: 'Error loading detected UID Keys.',
        err_status3: 'Action not allowed due to inactive status',
        limit: 'UID Keys limit reached.'
      },
      assigned: {
        title: 'Validators and assigned Terminals',
        reassign: 'Reassign',
        conn: 'connection',
        footer: 'Make sure all data is correct before assigning this device.',
        modal_remove: {
          title: 'Remove device',
          desc: 'Are you sure you want to take this collection device out of operation? If you do, the electronic collection will stop working.',
          title_succ: 'Withdrawal requested',
          desc_succ: 'The withdrawal request has been generated.'
        },
        modal_reassign: {
          title: 'Reassign devices',
          desc: 'The reassignment is a physical movement of the device.',
          serial: 'Serial to be reassigned',
          veh_actual: 'Current vehicle',
          new_veh: 'New vehicle',
          email: 'Email responsible for management'
        },
        modal_phone: {
          title: 'SIM Card',
          desc: 'SIM Card assigned to this this device',
          currentSIM: 'Current SIM Card',
          modifyTitle: 'MODIFY SIM CARD',
          modifyDescription: 'Enter the phone number of the new SIM Card'
        },
        modalDetails: {
          softwareVersion: 'Software Version',
          lastConnection: 'Last communication deny list'
        },
        menu: [
          { icon: 'swap-horiz', name: 'Reasign' },
          { icon: 'cancel', name: 'Remove' },
          { icon: 'sim-card', name: 'Phone line' },
          { icon: 'eye', name: 'Details' }
        ]
      },
      assign: {
        tabs: [
          {
            name: 'VALIDATORS',
            action: "validators"
          },
          {
            name: 'OTHER TERMINALS',
            action: "otherTerminals"
          }
        ],
        vehicleTitle: 'VEHICLES WITHOUT ASSIGNING VALIDATOR OR TERMINAL',
        vehicleValidDescription: 'Select the vehicle to which you will assign the validator',
        vehicleTerminalDescription: 'Select the vehicle to which you will assign the validator or terminal',
        emptySerialsTitle: 'No Serials',
        emptySerialsDescription: 'It appears that your subscription does not have, or has not yet been generated, serials for validators. Please check with Enterprises support and service.',
        emptyChecks: 'You must select both a serial and a vehicle to perform the mapping.'
      },
      add: {
        title: 'Assign',
        detected: 'Detected',
        detect: 'Detect',
        delete: 'Borrar',
        no_generate: 'You have not yet generated identifiers for devices. Press the \"Generate Keys\" button to start.',
        detecting_uid: 'Detecting UID Keys, Please wait.',
        modals: {
          assign_desc: 'Assign the devices and other elements that will be linked to this unique key.',
          sim: 'or similar',
          generate_desc: 'Enter the amount of identifiers you need'
        }
      }
    },
    weeks_days: {
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday'
    },
    table: {
      error : "An error occurred while retrieving the information",
      reload: "Reload"
    }
  }
};
