<div id="modalGeneral" class="modal fade-modal in-modal">
  <div class="modal-dialog modal-sm succes-modal">
    <div class="modal-content">
      <div class="modal-body text-center">
        <div class="succes-modal__title-modal text-center space p-b-8">
          <h5 class="f12 fw-bold upper m-0 {{classTitle}}" [innerHTML]="title"></h5>
        </div>
        <p class="text-center space f13 m-b-0 p-y-0" [innerHTML]="message"></p>
        <div class="space btns-footer flex align-items-center" [ngClass]="{'justify-content-between': !!btnCancel && !!btnSave, 'justify-content-center': !btnCancel || !btnSave}" *ngIf="!!btnCancel || !!btnSave">
          <button *ngIf="btnCancel" type="button" class="btn btn-cancel fw-bold upper min-w-150px w-auto h-px-45" aria-label="Close" (click)="close()">{{ btnCancel }}</button>
          <button *ngIf="btnSave" type="button" class="btn btn-cargar fw-bold upper min-w-150px w-auto h-px-45" aria-label="Close" (click)="close(true)">{{ btnSave }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
