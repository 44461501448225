import { ChangeDetectorRef, Component, Input, SimpleChanges, ViewRef } from '@angular/core';
import { icons } from '@data/icons.data';

@Component({
  selector: 'base-icon',
  templateUrl: './base-icon.component.html',
  styleUrls: ['./base-icon.component.scss'],
})
export class BaseIconComponent {
  
  @Input() iconClass: string = '';
  @Input() iconColor: string = 'primary';
  @Input() iconSize: string = 'small';
  @Input() label: string = '';
  @Input() srcIcon: string = '';

  srcSelected: string = '';

  constructor(private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.srcSelected = icons[this.srcIcon];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['srcIcon']?.currentValue) {
      this.srcSelected = icons[this.srcIcon];
    } else {
      this.srcSelected = '';
    }
    if (!(<ViewRef>this.cdRef).destroyed) {
      this.cdRef.detectChanges();
    }
  }
}
