export const ROLES_ADMIN = {
  MONITOR: 'Monitoreo vehicular',
  DRIVERS: 'Conductores',
  ROLES: 'Roles',
  TICKETS: 'Tiquetes',
  ROUTES: 'Rutas',
  UNITS: 'Vehiculos',
  PROFILE: 'Perfil usuario',
  OFFICE: 'Terminales y oficinas',
  COPNY: 'Pefil empresa',
  VALID_POS: 'Validadores',
  PAYMENT_SYSTEM: 'Recaudo y bancos',
  MECHANIC: 'Mecanica',
  STADISTIC: 'Estadisticas',
  GUIDE: 'Guias de soporte'
};

export const ACCESS_TYPE = [
  'admin', // 'Admnistrador suscripcion',
  'seller', // 'Vendedor',
  'enroller', // ROLLER: 'Enrolador',
  'analyst', // ANALYST: 'Analista',
  'mechanic' // MECHANICAL: 'Mecanico'
];
