import { Injectable } from "@angular/core";

declare var db: IDBDatabase;

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

	private storageName = '_ionickv';

	constructor(){}

	set(key: string,value: any): Promise<any> {
		return new Promise((resolve, reject) =>{
			if(!db){
				reject(false)
			}else{
				try{
					const transaction = db.transaction(this.storageName, 'readwrite');
					const objectStore = transaction.objectStore(this.storageName);
					const serialized = JSON.parse(JSON.stringify(value));
					const request = objectStore.put(serialized, key);
					request.onerror = () => reject(request.error);
					request.onsuccess = () => resolve(request.result);
				}catch(e){
					reject(false)
				}
			}
		});
	}

	get(key: string){
		return new Promise((resolve, reject) =>{
			if(!db){
				throw false;
			}else{
				const transaction = db.transaction(this.storageName, 'readwrite');
				const objectStore = transaction.objectStore(this.storageName);
				const request = objectStore.get(key);
				request.onerror = () => {
					reject(request.error)
				};
				request.onsuccess = () => resolve(request.result);
			}
		});
	}

	remove(key: string){
		return new Promise((resolve, reject) =>{
			if(!db){
				reject(false)
			}else{
				const transaction = db.transaction(this.storageName, 'readwrite');
        		const objectStore = transaction.objectStore(this.storageName);
				const request: IDBRequest = objectStore.delete(key);
				request.onerror = () => reject(request.error);
        		request.onsuccess = () => resolve(request.result);
			}
		});
	}

	clear(){
		return new Promise((resolve, reject) =>{
			if(!db){
				reject(false)
			}else{
				const transaction = db.transaction(this.storageName, 'readwrite');
				const objectStore = transaction.objectStore(this.storageName);
				const request: IDBRequest = objectStore.clear();
				request.onerror = () => reject(request.error);
				request.onsuccess = () => resolve(request.result);
			}
		});
	}

}